import { text_mod_28, text_mod_29, text_mod_30 } from "../text/Text";

export const module10 = {
  title: "Тема 10. Література козацької України. Україна в добу Відродження",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_28,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_29,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_30,
    },
    {
      id: 1,
      question: "1. Одна з основних передумов виникнення українського мистецького Відродження —",
      options: [
        { id: "А", text: "досягнення вчених" },
        { id: "Б", text: "колоніальний гніт" },
        { id: "В", text: "винайдення друкарства" },
        { id: "Г", text: "творчість письменників" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Духовна основа доби Відродження в Україні – це",
      options: [
        { id: "А", text: "братства" },
        { id: "Б", text: "церква" },
        { id: "В", text: "козацтво" },
        { id: "Г", text: "кобзарі" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Основною національно-політичною силою України доби Відродження були",
      options: [
        { id: "А", text: "церковнослужителі" },
        { id: "Б", text: "козаки" },
        { id: "В", text: "міщани" },
        { id: "Г", text: "селяни" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "«Божими людьми» в Україні називали",
      options: [
        { id: "А", text: "гетьманів" },
        { id: "Б", text: "священників" },
        { id: "В", text: "козаків" },
        { id: "Г", text: "кобзарів" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. В Україні пісні та думи НЕ виконують у супроводі",
      options: [
        { id: "А", text: "бандури" },
        { id: "Б", text: "кобзи" },
        { id: "В", text: "ліри" },
        { id: "Г", text: "трембіти" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Прообразом героя оповідання Р. М. Рільке «Пісня про правду» став кобзар",
      options: [
        { id: "А", text: "Михайло Кравченко" },
        { id: "Б", text: "Гнат Гончаренко" },
        { id: "В", text: "Остап Вересай" },
        { id: "Г", text: "Єгор Мовчан" },
      ],
      correctAnswer: "В",
      isTest: true,
    },

  ],
};
