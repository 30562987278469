export const text_mod_5 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLtUAAAAAAARXIMswwyIJTDM_ewUF8FNM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;



export const text_mod_6 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLuEAAAAAAAae36VzVkZh1lV61HDavIaE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_16 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLtkAAAAAAARsLYfqumm1iPvJgG13Q3PE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_18 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLukAAAAAAARoCvP7Jl2kNhbyWNsQ9Xfg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_23 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLLUEAAAAAAezleKfyCZTPkQP_y_dP8y8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_25 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLLkEAAAAAAVWqiLbZdncw0JYkJCf5gsc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_26 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLLEEAAAAAAZs92OvABfukUuu9jq41r5c" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_30 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLL0EAAAAAAd4Of4oryvpoGARtKJrv8js" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_32 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLMEEAAAAAAXzpvv8noSdUDs2L1DxvxLk" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_34 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLMUEAAAAAAZYa87WwkVGRiwapUogEgrU" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_36 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLM0EAAAAAAVj_bZ2BMcbkx3aNYL4_Thg" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_38 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLMkEAAAAAAUSCWsAinNJmywTwSu62oQE" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_43 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLNEEAAAAAAY0xdVpVimyV8kIdhOjL3Eg" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_46 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLNkEAAAAAAX3V7xdZKy_D1iV36oN2dzA" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_51 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLN0EAAAAAARpC_lnUstwZH4Ly95X-OYA" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_56 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLNUEAAAAAAYj1dF_dOlZv6lrNPHzmX5M" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_60 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLOEEAAAAAAZA-r2pLRq23VCwCTB8Pf3Q" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_68 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLOkEAAAAAAX8v7lWg1P10xN14Zmo0LYc" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_72 = `1. Уважно переглянь та виконай завдання, запиши їх у зошит.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSVnA-BYxcTIICLOUEAAAAAAalV2lNJiK3rhtCCV-ynyaE" 
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;
