import { text_mod_30 } from "../text/Text.js";

export const module30 = {
  title: "Урок 30. ​Табличне множення і ділення на 5.​ Знаходження частини від числа.​ Таблиця ділення і множення на 5. Задачі на визначення тривалості подій.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_30,
    },
  ],
};
