import { text_mod_51 } from "../text/Text.js";

export const module51 = {
  title: "Урок 51. Геометричні фігури: площинні, об'ємні.​ Периметр трикутника. Дроби. ​Задачі на знаходження частини від числа та числа за його частиною.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_51,
    },
  ],
};
