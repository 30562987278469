export const module77 = {
  title: "§ 77. Відокремлені додатки",
  questions: [
    {
      id: 1,
      question: "1. Відокремлений додаток ужито в реченні",
      options: [
        { id: "А", text: "До серця доторкнулася весна, ота жіноча, ніжно-лебедина." },
        { id: "Б", text: "Розминаючи сизий полин у долоні, роси юності спогадом п’ю." },
        { id: "В", text: "Усі, за винятком Андрія, прийшли того вечора на збори." },
        { id: "Г", text: "Дозволь мені, мій вечоровий світе, упасти зерням в рідній бороні." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Відокремлений додаток ужито в реченні",
      options: [
        { id: "А", text: "Хлопець стріляв швидко, хоча й неточно." },
        { id: "Б", text: "О земле втрачена, явися бодай у зболеному сні!" },
        { id: "В", text: "Край шляху, у долинці, догорає вогнище." },
        { id: "Г", text: "За винятком баби Оришки, Чіпка нікого не любив." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Відокремлений додаток ужито в реченні",
      options: [
        { id: "А", text: "То пилок, то сніги, то сльота партизанську вкривають дорогу." },
        { id: "Б", text: "Крім знання, тут потрібна інтуїція, дар передбачення, дар ясновидця." },
        { id: "В", text: "У темному гаю, у зеленій діброві, на припоні коні отаву скубуть." },
        { id: "Г", text: "Щовесни, як і інші дерева, яблуня своїм цвітом цілується з бджолами." }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Відокремленим додатком ускладнено всі речення, ОКРІМ (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Робити хлопцям було нічого окрім згадувати кожному своє." },
        { id: "Б", text: "Загасивши лампу я ліг але на відміну від Михася довго не спав." },
        { id: "В", text: "І все-таки до тебе думка лине мій занапащений нещасний краю." },
        { id: "Г", text: "Посередині стояв стіл за яким крім хана та візира зібралося якесь товариство." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Додаток НЕ ТРЕБА відокремлювати комами в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Навколо подвір’я замість тину слугували високі кущі." },
        { id: "Б", text: "Окрім тарпанів на водопій спускалися цілі череди турів і диких кіз." },
        { id: "В", text: "На відміну від інших козаків, побратими в бійки не встрявали." },
        { id: "Г", text: "На лівобережжі замість верб і чагарників видно погорблені смуги темені." }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Доберіть до кожного відокремленого члена речення приклад.",
      dropdownQuestionParts: [
        "<i><b>Відокремлений член речення</b></i> <br> 1) додаток ",
        { id: "dropdown1", text: " - " },
        "<br> 2) обставина ",
        { id: "dropdown2", text: " - " },
        "<br> 3) означення ",
        { id: "dropdown3", text: " - " },
        "<br> 4) прикладка ",
        { id: "dropdown4", text: " - " },
      ],
      options: [
        { id: "А", text: "Мені наснилася криниця, водою сповнена до краю." },
        { id: "Б", text: "Солов’ї, нічні товариші мої, у сад злетілись до вікна." },
        { id: "В", text: "А там, надворі, іде дощ, готується до виступу веселка." },
        { id: "Г", text: "У лісі не було нічого: ані грибів, ані ягід, ані черешень." },
        { id: "Д", text: "Максимові не треба ніяких благ, окрім чесних взаємин." },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "В",
        dropdown3: "А",
        dropdown4: "Б",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: </br><i><b>речення, ускладнені відокремленими додатками; </br>речення, не ускладнені відокремленими додатками.</b></i>',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Похибки друзів за винятком зради ми повинні терпіти або виправляти.", type: "leftBucket" },
        { id: 2, text: "Стомлений роями пасажирів вулик залізничного вокзалу надвечір стих.", type: "rightBucket" },
        { id: 3, text: "Синку побудь тут замість нас.", type: "rightBucket" },
        { id: 4, text: "Хлопець на відміну від інших нікуди не квапився бо до вечора було далеченько.", type: "leftBucket" },
        { id: 5, text: "Гуде вогонь веселий сатана червоним реготом вихоплюється з печі.", type: "rightBucket" },
        { id: 6, text: "Тут крім кінних на них чекало ще з півтора десятка піших кремезних парубків.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені відокремленими додатками",
      rightBucketTitle: "Речення, не ускладнені відокремленими додатками",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: </br><i><b>речення, ускладнені відокремленими додатками;</br> речення, не ускладнені відокремленими додатками.</b></i>',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Але хіба ми люди пройшовши сто доріг завершивши тисячу справ не прагнемо до отчого порогу до калини що росте на розі хати?", type: "rightBucket" },
        { id: 2, text: "І ніхто не видів мене в лісі опріч дерев.", type: "leftBucket" },
        { id: 3, text: "Ось вони рідні озера та луки в синім добрі солов’їних ночей.", type: "rightBucket" },
        { id: 4, text: "У поході мені не вистачало друзів, особливо Петька.", type: "leftBucket" },
        { id: 5, text: "Окрім мене на біостанції працювали ще двоє військових біолог і технік.", type: "leftBucket" },
        { id: 6, text: "Я ненавиджу тих хто не сіявши жнуть що садів не садивши плоди об’їдають.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені відокремленими додатками",
      rightBucketTitle: "Речення, не ускладнені відокремленими додатками",
    },
  ],
};
