import React from "react";
import { Dropdown } from "react-bootstrap";

import userIcon from "../images/userIcon.svg"

import "./UserDropdown.css"


function UserDropdown({ userName, userRoles }) {


    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("username");

        window.location.href = "/login"; // Перенаправление на страницу логина
    };

    return (
        <div className="d-flex align-items-center">
            <Dropdown>
                <Dropdown.Toggle
                    id="dropdown-user"
                    as="div" // Используем кастомный элемент вместо кнопки
                    className="p-0 d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                >
                    <img
                        src={userIcon}
                        alt="User icon"
                        title="Профіль користувача"
                        style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "5px",
                        }}
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu className="user_dropdown">
                    <Dropdown.ItemText>{userName}</Dropdown.ItemText>
                    <Dropdown.Divider />
                    {/* <Dropdown.Item href="/profile">Профіль</Dropdown.Item> */}

                    {Array.isArray(userRoles) && userRoles.includes('STUDENT') && (
                        <Dropdown.Item href="/progress">Мій прогрес</Dropdown.Item>
                    )}

                    {Array.isArray(userRoles) && userRoles.includes('TEACHER') && (
                        <Dropdown.Item href="/progress">Навчальний процес</Dropdown.Item>
                    )}
                    {/* <Dropdown.Item href="/settings">Налаштування</Dropdown.Item> */}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout}>Вийти</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default UserDropdown;
