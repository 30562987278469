import {text_mod_13, } from "../text/Text.js";
export const module13 = {
  title:
    "§13. Звукове значення букви Щ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_13, },
    ]
  }
