import {
    module01_02,
    module03_04,
    module05,
    module06,
    module07_08,
    module09,
    module15,
    module17_18
} from "./modules.js";

export const routes = [
    { path: "/class/8/zbd/pol/modules/module01_02", module: module01_02 },
    { path: "/class/8/zbd/pol/modules/module03_04", module: module03_04 },
    { path: "/class/8/zbd/pol/modules/module05", module: module05 },
    { path: "/class/8/zbd/pol/modules/module06", module: module06 },
    { path: "/class/8/zbd/pol/modules/module07_08", module: module07_08 },
    { path: "/class/8/zbd/pol/modules/module09", module: module09 },
    { path: "/class/8/zbd/pol/modules/module15", module: module15 },
    { path: "/class/8/zbd/pol/modules/module17_18", module: module17_18 },
];