import React from "react";
import { useParams } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import authorsData from "./authorsData";
import paths from "./paths";
import Header from "../header_h/Header";
import Footer from "../footer/Footer";

const SubjectPage = ({ userName, userRoles }) => {
  const { id, subject } = useParams();

  const authors = authorsData[id] ? authorsData[id][subject] : [];

  return (
    <>
      <Header userName={userName} userRoles={userRoles} />
      <div className="classContainer">
        <ListGroup>
          {authors && authors.length > 0 ? (
            authors.map((author, index) => (
              <ListGroup.Item
                key={index}
                action
                href={`${window.location.pathname}/${paths[author] || ""
                  }/modules`}
                variant="info"
                className="list-item"
              >
                {author}
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item className="list-item">Пусто</ListGroup.Item>
          )}
        </ListGroup>
      </div>
      <Footer />
    </>
  );
};

export default SubjectPage;
