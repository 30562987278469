export const module73 = {
  title: "§ 73. Відокремлені прикладки",
  questions: [
    {
      id: 1,
      question: "1. Відокремленою прикладкою ускладнено речення",
      options: [
        { id: "А", text: "Книги — морська глибина." },
        { id: "Б", text: "Виє вовк — ночей моїх соліст." },
        { id: "В", text: "Сухі гілки — це вже вінок терновий." },
        { id: "Г", text: "Всесвіт цей — акваріум планет." }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Відокремленою прикладкою НЕ УСКЛАДНЕНО речення",
      options: [
        { id: "А", text: "Настала осінь — золота пора." },
        { id: "Б", text: "Співають солов’ї — співці весни й любові." },
        { id: "В", text: "Життя — спокута не своїх гріхів." },
        { id: "Г", text: "Я маю спокій — істинного друга." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Невідокремлену прикладку вжито в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Серед лісу особливий подив викликає могутній дуб цар лісу." },
        { id: "Б", text: "Час великий диригент перегортає ноти на пюпітрі." },
        { id: "В", text: "Мартинюк високий сухорлявий чоловік тепло дивився на прибулих." },
        { id: "Г", text: "Чемпіон світу з боксу Олександр Усик укотре переміг." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Кому НЕ ТРЕБА ставити перед виділеним сполучником у реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Лисенко відомий у світі <b><i>як</b></i> композитор." },
        { id: "Б", text: "Тішилася Ганна скоромовкою <b><i>як</b></i> піснею." },
        { id: "В", text: "Доброта потрібна людям <b><i>як</b></i> хліб." },
        { id: "Г", text: "Ця дівчина… Обличчя <b><i>як</b></i> з ікон." }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Кому треба поставити перед виділеним сполучником у реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Підмет здебільшого виражений іменником або займенником." },
        { id: "Б", text: "Високого ліризму сповнені народні пісні або балади." },
        { id: "В", text: "Для зміцнення фундаменту використовують дуб або кедр." },
        { id: "Г", text: "Столітник або алое використовують для лікування шлунка." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Правильно поставлено розділові знаки в реченні",
      options: [
        { id: "А", text: "Тоді весна, ще тільки натякала про себе, висилаючи вперед своїх зухвалих розвідників, гомінливі ручаї." },
        { id: "Б", text: "Тоді весна ще тільки натякала, про себе висилаючи вперед, своїх зухвалих розвідників — гомінливі ручаї." },
        { id: "В", text: "Тоді весна ще тільки натякала про себе, висилаючи вперед своїх зухвалих розвідників — гомінливі ручаї." },
        { id: "Г", text: "Тоді весна ще тільки натякала про себе, висилаючи вперед, своїх зухвалих розвідників, гомінливі ручаї." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: <i><b>речення, у яких треба відокремлювати прикладку; речення, у яких не треба відокремлювати прикладку.</b></i>',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Яка ж вона, пектораль, що змусила весь світ заговорити про себе?", type: "leftBucket" },
        { id: 2, text: "Наш дідусь Олекса завжди усміхнений.", type: "rightBucket" },
        { id: 3, text: "Постійний учасник мандрівок, Антон не втомлюється під час довгих переходів.", type: "leftBucket" },
        { id: 4, text: "Шевченко, як справжній знавець історії, вдало зобразив події Коліївщині.", type: "leftBucket" },
        { id: 5, text: "Захоплююся Тарасом Шевченком як художником-портретистом.", type: "rightBucket" },
        { id: 6, text: "Часто в думках лину до свого рідного села Орільського.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у яких треба відокремлювати прикладку",
      rightBucketTitle: "Речення, у яких не треба відокремлювати прикладку",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: <i><b>речення, у яких треба відокремлювати прикладку; речення, у яких не треба відокремлювати прикладку.</b></i>',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Весна вже майже розправилася зі снігом на полях і хазяйнувала як повноцінна володарка.", type: "rightBucket" },
        { id: 2, text: "Артем Пивоваров, український співак, народився у Вовчанську.", type: "leftBucket" },
        { id: 3, text: "Українська тенісистка Еліна Світоліна стала третьою ракеткою світу.", type: "rightBucket" },
        { id: 4, text: "У кав’ярні діти замовили найсмачніший напій — какао з маршмелоу.", type: "leftBucket" },
        { id: 5, text: "Дмитро Коцюбайло, на позивний Да Вінчі, похований на Аскольдовій могилі.", type: "leftBucket" },
        { id: 6, text: "Брати Клички відомі у світі як українські боксери.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у яких треба відокремлювати прикладку",
      rightBucketTitle: "Речення, у яких не треба відокремлювати прикладку",
    },
  ],
};
