import {text_mod_10, } from "../text/Text.js";
export const module10 = {
  title:
    "Уроки 40-43. Додавання і віднімання чисел виду 32 + 4, 28 – 5.​ Віднімання двоцифрових чисел виду 65 – 20​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_10, },
    ]
  }
