import { Component } from "react";
import Header from "../header_h/Header";
import Slider from "../slider/Slider";
import LessonSliderPreview from "../lesson-slider-preview/LessonSliderPreview";
import Footer from "../footer/Footer";

class Main extends Component {
  render() {
    const { userName, userRoles } = this.props;
    return (
      <>
        <Header userName={userName} userRoles={userRoles} />
        {/* <Slider /> */}
        <LessonSliderPreview />
        <Footer />
      </>
    );
  }
}

export default Main;
