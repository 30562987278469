export const module02 = {
  title: "Односкладні речення з головним членом у формі присудка. Добрі й погані звички",
  questions: [
    {
      id: 1,
      question: "1. Означено-особовим є речення",
      options: [
        { id: "А", text: "Завжди мию руки перед їжею." },
        { id: "Б", text: "Дотримуйтеся правил гігієни." },
        { id: "В", text: "Здоровий спосіб життя — запорука успіху." },
        { id: "Г", text: "Чистити зуби двічі на день корисно." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Головний член виражений дієсловом у формі 1-ї особи множини в реченні",
      options: [
        { id: "А", text: "Щодня займаюся спортом." },
        { id: "Б", text: "Уживаємо більше овочів і фруктів." },
        { id: "В", text: "Не палять і ведуть здоровий спосіб життя." },
        { id: "Г", text: "Прокидайся рано, щоб устигнути зробити зарядку." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Означено-особовим є речення",
      options: [
        { id: "А", text: "Не можна зловживати солодощами." },
        { id: "Б", text: "Регулярно відвідуй стоматолога." },
        { id: "В", text: "Дотримуюся режиму дня." },
        { id: "Г", text: "Корисно спати не менше 8-ми годин." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Присудок виражений дієсловом у формі наказового способу в реченні",
      options: [
        { id: "А", text: "Щодня займаюся фізичними вправами." },
        { id: "Б", text: "Обмежте споживання шкідливої їжі." },
        { id: "В", text: "Намагаємося їсти менше солодкого." },
        { id: "Г", text: "Читаю перед сном для кращого засинання." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Означено-особовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Не забувай про правильну поставу." },
        { id: "Б", text: "Уживаємо достатньо води щодня." },
        { id: "В", text: "Здоровий сон." },
        { id: "Г", text: "Регулярно провітрюю кімнату." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Присудок виражений дієсловом у формі майбутнього часу в реченні",
      options: [
        { id: "А", text: "Завтра встану рано та зроблю зарядку." },
        { id: "Б", text: "Намагаюся менше користуватися гаджетами перед сном." },
        { id: "В", text: "Щодня гуляємо на свіжому повітрі." },
        { id: "Г", text: "Не забувайте про користь фізичної активності." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 7,
      question: "7. Односкладне речення Щодня роблю ранкову гімнастику є",
      options: [
        { id: "А", text: "означено-особовим" },
        { id: "Б", text: "неозначено-особовим" },
        { id: "В", text: "узагальнено-особовим" },
        { id: "Г", text: "безособовим" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 8,
      question: "8. Односкладне речення Завжди миють фрукти перед уживанням є",
      options: [
        { id: "А", text: "означено-особовим" },
        { id: "Б", text: "неозначено-особовим" },
        { id: "В", text: "узагальнено-особовим" },
        { id: "Г", text: "безособовим" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
  ],
};
