export const module07 = {
  title: "Однорідні члени речення. Відомі невідомі країни",
  questions: [
    {
      id: 1,
      question: "1. Речення з неоднорідними означеннями подано в рядку",
      options: [
        { id: "А", text: "Вануату — екзотична, тропічна, райська держава в Тихому океані." },
        { id: "Б", text: "Вануату — далека острівна країна в Меланезії." },
        { id: "В", text: "Вануату — маленька, бідна, але щаслива нація." },
        { id: "Г", text: "Вануату — дивовижна, неповторна, загадкова країна вулканів." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Однорідні додатки вжито в реченні",
      options: [
        { id: "А", text: "Небо синє, велике, високе, наче вічність, бездонне, глибоке (Б. Лепкий)." },
        { id: "Б", text: "Скіфське золото зберігається в музеях України та за кордоном." },
        { id: "В", text: "Археологи знайшли стародавні монети, прикраси та зброю в курганах України." },
        { id: "Г", text: "Тетяна Пата, Віра й Галина Павленко, Віра Клименко, Надія Білокінь, Поліна Глущенко, Надія Пікуш по-різному використали вікові традиції декоративного малювання свого села (Г. Степовик)." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Однорідні додатки з узагальнювальним словом містить речення",
      options: [
        { id: "А", text: "Скіфське золото, трипільська кераміка, старовинні ікони — усе це зберігається в музеях України." },
        { id: "Б", text: "Україна багата на вугілля, залізну руду, газ." },
        { id: "В", text: "Карпати славляться мінеральними джерелами й чистим повітрям." },
        { id: "Г", text: "Дністровський каньйон вражає туристів красою та величчю." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Однорідні обставини є в реченні",
      options: [
        { id: "А", text: "Ми подорожували через гори, долини та ліси." },
        { id: "Б", text: "Експедиція досліджувала Амазонку швидко й ефективно." },
        { id: "В", text: "Студенти й викладачі відвідали найвищу гору Європи — Монблан." },
        { id: "Г", text: "Археологи шукають і знаходять стародавні артефакти." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Визначте, скільки груп однорідних обставин є в реченні: Під час експедиції вчені старанно й методично збирали зразки ґрунту, уважно й відповідально аналізували склад повітря, а також ретельно й точно вимірювали температуру води в гейзерах.",
      options: [
        { id: "А", text: "одна група" },
        { id: "Б", text: "дві групи" },
        { id: "В", text: "три групи" },
        { id: "Г", text: "чотири групи" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Узагальнювальне слово при однорідних обставинах містить речення",
      options: [
        { id: "А", text: "Дослідники вивчали клімат регіону всебічно: улітку і взимку, удень і вночі." },
        { id: "Б", text: "Експедиція зібрала зразки рослин у тропічних лісах, саванах і пустелях." },
        { id: "В", text: "Науковці досліджували вулкан, використовуючи сейсмографи та термометри." },
        { id: "Г", text: "Мандрівники подолали складний маршрут, піднявшись на вершину гори." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
  ],
};
