export const text_mod_1 = `
    <p>Ласкаво просимо на платформу “Е-Грамота” — сучасний онлайн-сервіс інтерактивних електронних додатків для учнів. Наші інструменти створені для того, щоб зробити навчання цікавим, зручним і доступним з будь-якого пристрою та будь-якого місця.</p>
    <p style='font-size: 18px'><b>
    Що пропонує “Е-Грамота”?
    </b></p>
    <ol>
        <li><b>Інтерактивний навчальний контент</b><br/>
       Наші додатки побудовані на сучасних технологіях і пропонують широкий спектр можливостей для навчання:
        <ul style="list-style-type: disc;">
        <li>Мультимедійні елементи, включно з анімаціями та звуковими ефектами, щоб зробити процес навчання яскравим і захоплюючим.</li>
        <li>Завдання різного рівня складності: від простих інтерактивних вправ, таких як перетягування елементів, до більш складних завдань на заповнення пропусків, зіставлення тощо.</li>
        <li>Презентації, які допомагають учням краще зрозуміти та закріпити теоретичний матеріал.</li>
        <li>Додаткові ресурси: інтерактивна теорія, практичні вправи та тести для закріплення знань.</li>
        <li>Завдання, які сприяють розвитку критичного мислення та творчого підходу.</li>
        </ul>
        </li>
          
        <li>
        <b> Доступність будь-де і будь-коли</b><br/>
        Наші додатки працюють виключно у вебверсії, що дозволяє використовувати їх з будь-якого пристрою — комп’ютера, планшета чи смартфона. Підтримуються всі популярні операційні системи: Windows, Android, iOS, ChromeOS. Ви можете навчатись у школі, вдома або навіть у подорожі — лише доступ до інтернету, і все готово!
        </li></br>

        <li>
        <b>Простота у використанні</b><br/>
        Інтуїтивний інтерфейс допомагає учням швидко орієнтуватися, а вчителям і батькам — контролювати процес навчання. У додатках доступні покрокові інструкції, роз’яснення та підказки до кожного завдання.
        </li></br>
    </ol>
          <p style='font-size: 18px'><b>
    Чому варто обрати “Е-Грамоту”?
        </b></p>
    <ul style="list-style-type: disc;">
    <li>Універсальність: навчання доступне з будь-якого куточка світу.</li>
    <li>Інтерактивність: технології, які залучають до навчання.</li>
    <li>Зручність: простота використання на будь-якому пристрої.</li>
    </ul>

    <p><b>Зв’яжіться з нами:</b></p>
        📧 Email: info@gramota.kiev.ua <br/>
        📞 Телефон: +380686604958
    `