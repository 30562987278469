import {
    module01,
    module02,
    module03,
    module04,
    module05,
    module06,
    module07,
    module08,
    module09,
    module10,
    module11,
    module12,
    module13,
    module14,
    module15,
    module16,
    module17,
    module18,
    module19,
    module20,
    module21,
    module22,
    module23,
    module24,
    module25,
    module26,
    module27,
    module28,
    module29,
    module30
} from "./modules.js";

export const routes = [
    { path: "/class/8/ukrlit/yac/modules/module01", module: module01 },
    { path: "/class/8/ukrlit/yac/modules/module02", module: module02 },
    { path: "/class/8/ukrlit/yac/modules/module03", module: module03 },
    { path: "/class/8/ukrlit/yac/modules/module04", module: module04 },
    { path: "/class/8/ukrlit/yac/modules/module05", module: module05 },
    { path: "/class/8/ukrlit/yac/modules/module06", module: module06 },
    { path: "/class/8/ukrlit/yac/modules/module07", module: module07 },
    { path: "/class/8/ukrlit/yac/modules/module08", module: module08 },
    { path: "/class/8/ukrlit/yac/modules/module09", module: module09 },
    { path: "/class/8/ukrlit/yac/modules/module10", module: module10 },
    { path: "/class/8/ukrlit/yac/modules/module11", module: module11 },
    { path: "/class/8/ukrlit/yac/modules/module12", module: module12 },
    { path: "/class/8/ukrlit/yac/modules/module13", module: module13 },
    { path: "/class/8/ukrlit/yac/modules/module14", module: module14 },
    { path: "/class/8/ukrlit/yac/modules/module15", module: module15 },
    { path: "/class/8/ukrlit/yac/modules/module16", module: module16 },
    { path: "/class/8/ukrlit/yac/modules/module17", module: module17 },
    { path: "/class/8/ukrlit/yac/modules/module18", module: module18 },
    { path: "/class/8/ukrlit/yac/modules/module19", module: module19 },
    { path: "/class/8/ukrlit/yac/modules/module20", module: module20 },
    { path: "/class/8/ukrlit/yac/modules/module21", module: module21 },
    { path: "/class/8/ukrlit/yac/modules/module22", module: module22 },
    { path: "/class/8/ukrlit/yac/modules/module23", module: module23 },
    { path: "/class/8/ukrlit/yac/modules/module24", module: module24 },
    { path: "/class/8/ukrlit/yac/modules/module25", module: module25 },
    { path: "/class/8/ukrlit/yac/modules/module26", module: module26 },
    { path: "/class/8/ukrlit/yac/modules/module27", module: module27 },
    { path: "/class/8/ukrlit/yac/modules/module28", module: module28 },
    { path: "/class/8/ukrlit/yac/modules/module29", module: module29 },
    { path: "/class/8/ukrlit/yac/modules/module30", module: module30 },
];
