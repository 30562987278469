import {text_mod_44, } from "../text/Text.js";
export const module44 = {
  title:
    "§44. Сполучення іменників із прикметниками​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_44, },
    ]
  }
