const users = [
    { login: 'student', password: '123', roles: ['STUDENT'], username: 'Олександр 9-Б' },
    { login: 'teacher', password: '123', roles: ['TEACHER'], username: 'Аліна Миколаївна' },
    { login: 'parent', password: '123', roles: ['PARENT'], username: 'Сергій' },
    { login: 'admin', password: '123', roles: ['ADMIN'], username: 'Адміністратор' },
];

export async function mockLogin(login, password) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const user = users.find(
                (u) => u.login === login && u.password === password
            );
            if (user) {
                const fakeJwt = btoa(`${user.login}:${user.roles.join(',')}`);
                resolve({
                    token: fakeJwt,
                    roles: user.roles,
                    login: user.login,
                    username: user.username
                });
            } else {
                reject(new Error('Неправильний логін або пароль!'));
            }
        }, 500);
    });
}

// Функция «проверки» токена (расшифровка)
export function mockValidateToken(token) {
    if (!token) return null;
    try {
        const decoded = atob(token); // 'login:ROLE1,ROLE2'
        const [login, rolesString] = decoded.split(':');
        const roles = rolesString.split(',');

        // Найти пользователя по логину
        const user = users.find((u) => u.login === login);
        if (!user) return null;

        return { login: user.login, roles, username: user.username };
    } catch (e) {
        return null;
    }
}
