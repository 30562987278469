import {text_mod_23, } from "../text/Text.js";
export const module23 = {
  title:
    "Уроки 121-126. Складання за схемою добутків із першим множником 8 і 9. Обчислення периметра. Розв’язання задач на вивчені випадки множення і ділення. Знаходження невідомого дільника.​ Відновлення рівностей​​​​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_23, },
    ]
  }
