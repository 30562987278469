import {text_mod_31, } from "../text/Text.js";
export const module31 = {
  title:
    "§31. Однозначні й багатозначні слова",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_31, },
    ]
  }
