import {text_mod_58, } from "../text/Text.js";
export const module58 = {
  title:
    "§58. Поширення речень за питаннями та поданими словами",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_58, },
    ]
  }
