import imglit04 from "./images/ukrlit/imglit_04.png";
import imglit05 from "./images/ukrlit/imglit_05.png";
import imglit06 from "./images/ukrlit/imglit_06.png";
import imglit07 from "./images/ukrlit/imglit_07.png";
import imglit08 from "./images/ukrlit/imglit_08.png";

export const module32_34 = {
  title: "§ 32-34. Риси реалізму в повісті Івана Нечуя-Левицького «Микола Джеря»",
  questions: [
    {
      id: 1,
      question: "1. Портрет Івана Нечуя-Левицького позначено буквою",
      options: [
        { id: "А", text: "Портрет А" },
        { id: "Б", text: "Портрет Б " },
        { id: "В", text: "Портрет В " },
        { id: "Г", text: "Портрет Г " }
      ],
      imgPath: imglit04,
      addClass: "horizontal",
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Ілюстрацію до повісті «Микола Джеря» позначено буквою",
      options: [
        { id: "А", text: "А" },
        { id: "Б", text: "Б" },
        { id: "В", text: "В" },
        { id: "Г", text: "Г" }
      ],
      imgPath: imglit05,
      addClass: "horizontal",
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Більшість подій повісті «Микола Джеря» відбуваються в регіонах, позначених на карті цифрами",
      options: [
        { id: "А", text: " 1 і 4" },
        { id: "Б", text: "5 і 7" },
        { id: "В", text: " 2 і 3 " },
        { id: "Г", text: "3 і 6" }
      ],
      imgPath: imglit06,
      addClass: "horizontal",
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Ілюстрація до повісті «Микола Джеря» відповідає її",
      options: [
        { id: "А", text: "зав’язці" },
        { id: "Б", text: "розвиткові дії" },
        { id: "В", text: "кульмінації" },
        { id: "Г", text: "розв’язці" }
      ],
      imgPath: imglit07,
      addClass: "vertical",
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Художній образ, якого НЕМАЄ в повісті «Микола Джеря», позначено буквою",
      options: [
        { id: "А", text: " " },
        { id: "Б", text: " " },
        { id: "В", text: " " },
        { id: "Г", text: " " }
      ],
      imgPath: imglit08,
      addClass: "horizontal",
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: '6. Розподіліть літературних героїв / героїнь за творами: <i><b>Повість «Микола Джеря»; Повість «Сорочинський ярмарок».</b></i> <br>(Перетягніть імена у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Нимидора", type: "leftBucket" },
        { id: 2, text: "Солопій Черевик", type: "rightBucket" },
        { id: 3, text: "Параска", type: "rightBucket" },
        { id: 4, text: "Петро Кавун", type: "leftBucket" },
        { id: 5, text: "пан Бжозовський", type: "leftBucket" },
        { id: 6, text: "Грицько", type: "rightBucket" },
        { id: 7, text: "Хівря", type: "rightBucket" },
        { id: 8, text: "Мокрина", type: "leftBucket" }
      ],
      leftBucketTitle: "Повість «Микола Джеря»",
      rightBucketTitle: "Повість «Сорочинський ярмарок»"
    },
    {
      id: 7,
      question:
        "7. Перегляньте тизер екранізації повісті Івана Нечуя-Левицького «Микола Джеря» (2 хв 20 с). Поділіться враженнями від зорових й слухових ефектів цього відеоматеріалу. Поділіться думками щодо того, наскільки влучно вони передають атмосферу повісті чи інтригують майбутніх глядачів до перегляду кінострічки (письмово). </br></br><b><i> <h5> Тизер — </b>рекламне повідомлення, зроблене як загадка, що містить частину інформації про продукт, але ніяк не називає рекламований товар. Тизери зазвичай з'являються на ранньому етапі рекламування товару та служать для створення інтриги навколо нього.</i></h5>",
      videoPath:
        "https://www.youtube.com/embed/T_15iEq0lus?si=OJG17qIILGrr2toC",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
