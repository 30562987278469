import {text_mod_1, } from "../text/Text.js";
export const module01 = {
  title:
    "§1. Ми знову разом​ ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_1, },
    ]
  }
