import { text_mod_72 } from "../text/Text.js";

export const module72 = {
  title: "Урок 72. Співвідношення між одиницями маси. Дії з іменованими числами.  Коло. Радіус і діаметр кола.​ Задачі про спільну роботу та на визначення тривалості і часу подій. Обчислення виразів.​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_72,
    },
  ],
};