import { text_mod_6, } from "../text/Text.js";

export const module09 = {

  title: "Урок 9. Безпека в населеному пункті. Надання долікарської допомоги під час надзвичайних ситуацій.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_6,
    },

  ],
};
