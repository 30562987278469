import { Component } from "react";
import logo from "../../images/logo.png";

import "./Footer.css";
import "./responsive.css";

class Footer extends Component {
  render() {
    return (
      <footer className="gap footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="logo-footer">
                <a href="#">
                  <img alt="log" src={logo} />
                </a>
                <p>
                  Платформа електронних додатків <br /> "е-Грамота"
                </p>
              </div>
            </div>
          </div>

          <div className="footer-contact-info">
            <div className="footer-mailto">
              <p>Виникли питання? Телефонуй або пиши нам</p>
              <a href="mailto:info@gramota.kiev.ua">
                info@gramota.kiev.ua
              </a>
            </div>

            <div className="d-flex">
              <i>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M437.15,74.817C388.895,26.571,324.561,0,256,0C187.587,0,123.279,26.65,74.92,75.041
                    C26.559,123.435-0.048,187.766,0,256.184c0.048,68.507,27.005,132.938,75.905,181.425C124.335,485.629,188.219,512,255.997,512
                    c0.677,0,1.357-0.002,2.035-0.008c44.288-0.345,87.858-12.192,126.001-34.262l-15.024-25.967
                    c-33.653,19.472-72.109,29.925-111.21,30.23c-60.48,0.456-117.575-22.858-160.77-65.688C53.847,373.49,30.043,316.616,30,256.163
                    C29.958,195.762,53.447,138.97,96.141,96.247C138.832,53.527,195.605,30,256,30c124.595,0,225.979,101.365,226,225.959
                    c0.008,49.387-15.621,96.298-45.198,135.661c-2.573,3.424-6.37,5.478-10.692,5.784c-4.368,0.308-8.658-1.291-11.756-4.388
                    l-20.406-20.406l9.06-9.06l-70.711-70.711l-28.284,28.284c-58.885-7.935-105.202-54.252-113.137-113.137l28.284-28.284
                    l-70.711-70.711l-39.054,39.054c-3.826,66.249,19.552,133.776,70.167,184.391s118.142,73.993,184.391,70.167l8.782-8.781
                    l20.406,20.406c9.247,9.247,22.033,14.022,35.082,13.1c12.935-0.913,24.803-7.36,32.563-17.688
                    C494.3,365.039,512.01,311.895,512,255.954C511.988,187.393,485.406,123.064,437.15,74.817z"
                    ></path>
                  </g>
                </svg>
              </i>

              <div className="footer-phone-num">
                <p>Наш телефон</p>
                <a href="callto:+3806866049583">+380686604958</a>
              </div>
            </div>

            <div className="d-flex footer-bottom-text">
              <i>
                <svg
                  version="1.1"
                  xmlSpace="preserve"
                  width="682.66669"
                  height="682.66669"
                  viewBox="0 0 682.66669 682.66669"
                  xmlns="http://www.w3.org/2000/svg"
                  id="defs2260"
                >
                  <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2270">
                    <path d="M 0,512 H 512 V 0 H 0 Z" id="path2268"></path>
                  </clipPath>
                  <g
                    id="g2262"
                    transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)"
                  >
                    <g id="g2264">
                      <g id="g2266" clipPath="url(#clipPath2270)">
                        <g id="g2272" transform="translate(84.6397,306.3162)">
                          <path
                            d="M 0,0 V 48.717 C 0,127.055 63.506,190.56 141.844,190.56 h 59.033 c 78.338,0 141.844,-63.505 141.844,-141.843 V 0"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2274"
                          ></path>
                        </g>
                        <g id="g2276" transform="translate(84.6397,161.0151)">
                          <path
                            d="m 0,0 h -33.458 c -19.988,0 -36.191,16.204 -36.191,36.192 v 72.917 c 0,19.989 16.203,36.192 36.191,36.192 l 33.458,0 z"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2278"
                          ></path>
                        </g>
                        <g id="g2280" transform="translate(427.3603,161.0151)">
                          <path
                            d="m 0,0 33.435,0.144 c 19.988,0 36.192,16.204 36.192,36.192 v 72.918 c 0,19.988 -16.204,36.191 -36.192,36.191 H 0 Z"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2282"
                          ></path>
                        </g>
                        <g id="g2284" transform="translate(427.3603,161.0151)">
                          <path
                            d="M 0,0 V -114.164 H -132.792"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2286"
                          ></path>
                        </g>
                        <g id="g2288" transform="translate(294.5683,15.0369)">
                          <path
                            d="m 0,0 h -46.548 c -16.894,0 -30.589,13.695 -30.589,30.589 v 2.451 c 0,16.893 13.695,30.588 30.589,30.588 H 0 Z"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2290"
                          ></path>
                        </g>
                        <g id="g2292" transform="translate(366.3334,327.334)">
                          <path
                            d="m 0,0 h -220.663 v -128.665 h 78.925 l 31.405,-40.617 31.404,40.617 H 0 Z"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2294"
                          ></path>
                        </g>
                        <g id="g2296" transform="translate(240.9991,263.33)">
                          <path
                            d="M 0,0 H 30.002"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2298"
                          ></path>
                        </g>
                        <g id="g2300" transform="translate(186.8619,263.33)">
                          <path
                            d="M 0,0 H 30.002"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2302"
                          ></path>
                        </g>
                        <g id="g2304" transform="translate(296.0025,263.33)">
                          <path
                            d="M 0,0 H 30.002"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 30,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            id="path2306"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </i>
              <div className="footer-support">
                <p>24/7 Підтримка</p>
                <p>&ensp;</p>
              </div>
            </div>

            <div className="d-flex">
              <i>
                <svg
                  version="1.1"
                  xmlSpace="preserve"
                  width="682.66669"
                  height="682.66669"
                  viewBox="0 0 682.66669 682.66669"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2333">
                    <path d="M 0,512 H 512 V 0 H 0 Z"></path>
                  </clipPath>
                  <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                    <g>
                      <g clipPath="url(#clipPath2333)">
                        <g transform="translate(256,92)">
                          <path
                            d="m 0,0 c -126.964,143.662 -160,165.23 -160,240 0,88.366 71.634,160 160,160 88.365,0 160,-71.634 160,-160 C 160,165.854 130.212,147.337 0,0 Z"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 40,
                              strokeLinecap: "square",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                          ></path>
                        </g>
                        <g transform="translate(316,372)">
                          <path
                            d="m 0,0 -80,-80 -40,40"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 40,
                              strokeLinecap: "square",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                          ></path>
                        </g>
                        <g transform="translate(176,20)">
                          <path
                            d="M 0,0 H 160"
                            style={{
                              fill: "none",
                              stroke: "#f6ff00",
                              strokeWidth: 40,
                              strokeLinecap: "square",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </i>
              <div className="footer-address">
                <p>вул. Паньківська 25, (офіс 15), м. Київ, 01021</p>
                <span>Карта</span>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div>
              <p>
                <span>© 2025 Видавництво "Грамота"</span>
              </p>
            </div>

            <ul className="social-media">
              <li>
                <a href="/home">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
