import {text_mod_17, } from "../text/Text.js";
export const module17 = {
  title:
    "Уроки 78-83. Складання діаграми. Множення із числами 1 і 0. Буквене позначення чисел. Складання таблиці множення числа 3. Периметр прямокутника, п’ятикутника. Задачі на збільшення числа на кілька одиниць​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_17, },
    ]
  }
