import {text_mod_65, } from "../text/Text.js";
export const module65 = {
  title:
    "§65.Виражальні засоби мови. Сворюю текст за ілюстрацією",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_65, },
    ]
  }
