import {text_mod_51, } from "../text/Text.js";
export const module51 = {
  title:
    "§51. Мої навчальні досягнення",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_51, },
    ]
  }
