
import { text_mod_20_1, text_mod_20_2, text_mod_20_3 } from "../text/Text.js";

export const module20 = {
  title: "Тема 20. Романтизм у творчості Віктора Забіли",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_20_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_20_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_20_3,
    },
    {
      id: 4,
      question: "1. Визначте, чому романтизм в українській літературі утвердився пізніше, ніж в європейському письменстві.",
      options: [
        { id: "А", text: "через жорстокий імперський гніт" },
        { id: "Б", text: "не було літературної мови" },
        { id: "В", text: "у мистецтві переважали фольклорні мотиви" },
        { id: "Г", text: "не було високоосвічених митців" }
      ],
      correctAnswer: "А",
      isTest: true
    },
    {
      id: 5,
      question: "2. Романтизм як художній напрям найбільше відобразився в українській",
      options: [
        { id: "А", text: "поезії" },
        { id: "Б", text: "прозі" },
        { id: "В", text: "драматургії" },
        { id: "Г", text: "фольклорі" }
      ],
      correctAnswer: "А",
      isTest: true
    },
    {
      id: 6,
      question: "3. Назва гуртка «Руська трійця» виникла за",
      options: [
        { id: "А", text: "національною належністю учасників" },
        { id: "Б", text: "кількістю учасників" },
        { id: "В", text: "однойменним альманахом" },
        { id: "Г", text: "першою колективною збіркою фольклору" }
      ],
      correctAnswer: "Б",
      isTest: true
    },
    {
      id: 7,
      question: "4. Небо в поезії М. Петренка є символом",
      options: [
        { id: "А", text: "безтурботності" },
        { id: "Б", text: "самотності" },
        { id: "В", text: "духовної свободи" },
        { id: "Г", text: "самостійності" }
      ],
      correctAnswer: "В",
      isTest: true
    },
    {
      id: 8,
      question: "5. Мотив вірша «Дивлюсь я на небо…» —",
      options: [
        { id: "А", text: "прагнення духовно багатої натури до абсолютної краси та досконалості" },
        { id: "Б", text: "патріотичне захоплення красою рідної землі" },
        { id: "В", text: "трагічне кохання, розлука закоханих" },
        { id: "Г", text: "митець і мистецтво в суспільстві" }
      ],
      correctAnswer: "А",
      isTest: true
    },
    {
      id: 9,
      question: "6. Визначте, як мрія автора про небо частково реалізувалася.",
      options: [
        { id: "А", text: "онук М. Петренка став космонавтом" },
        { id: "Б", text: "іменем М. Петренка названо астероїд" },
        { id: "В", text: "іменем М. Петренка названо космічний корабель" },
        { id: "Г", text: "вірш «Дивлюсь я на небо…» став славнем космонавтів" }
      ],
      correctAnswer: "Б",
      isTest: true
    }
    
  ],
};
