export const module03 = {
  title:
    "Речення та його ознаки. Типи речень за метою висловлювання та за емоційним забарвленням. (повторення)",
  questions: [
    {
      id: 1,
      question: "1. Ознаками речення є всі, ОКРІМ",
      options: [
        { id: "А", text: "має головне й залежне слово" },
        { id: "Б", text: "виражає закінчену думку" },
        { id: "В", text: "інтонаційно завершене" },
        { id: "Г", text: "має граматичну основу" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question:
        "2. НЕПРАВИЛЬНИМ є твердження, що речення «Терпи, козаче, отаманом будеш»",
      options: [
        { id: "А", text: "ускладнене звертанням" },
        { id: "Б", text: "односкладне" },
        { id: "В", text: "розповідне" },
        { id: "Г", text: "неокличне" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Розповідним є речення",
      options: [
        { id: "А", text: "Узвозом скрадалося підвечір’я." },
        { id: "Б", text: "Не страши кота салом!" },
        { id: "В", text: "Хто вірші потай шепотів?" },
        { id: "Г", text: "Не роби комусь, що собі не мило." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Спонукальним є речення",
      options: [
        { id: "А", text: "Хто душу виплескав до дна?" },
        { id: "Б", text: "Людей питай, а свій розум май!" },
        { id: "В", text: "З великої хмари малий дощ." },
        { id: "Г", text: "Ласий на чужі ковбаси." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. НЕ є спонукальним речення",
      options: [
        { id: "А", text: "Не кажи — не вмію, а кажи — навчусь." },
        { id: "Б", text: "Моя ти доле молодая, не покидай мене!" },
        { id: "В", text: "У чужому домі не порядкуй." },
        { id: "Г", text: "Яка весна цьогоріч особлива!" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Прислівникове словосполучення записано у варіанті",
      options: [
        { id: "А", text: "надзвичайно впевнено" },
        { id: "Б", text: "надзвичайно сміливий" },
        { id: "В", text: "несподівано зʼявитися" },
        { id: "Г", text: "зʼявитися вчасно" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 7,
      question:
        "7. Розподіліть конструкції у дві чарунки – словосполучення і сполуки слів:",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "буду опановувати", type: "rightBucket" },
        { id: 2, text: "вареники з вишнями", type: "leftBucket" },
        { id: 3, text: "те завдання", type: "leftBucket" },
        { id: 4, text: "обібрати до нитки", type: "rightBucket" },
        { id: 5, text: "найвищий з-поміж соняшників", type: "leftBucket" },
        { id: 6, text: "оригінальне есе", type: "leftBucket" },
        { id: 7, text: "прийшли батьки", type: "rightBucket" },
        { id: 8, text: "навколо гімназії", type: "rightBucket" },
        { id: 9, text: "радісно від успіху", type: "leftBucket" },
        { id: 10, text: "менш відкритий", type: "rightBucket" },
        { id: 11, text: "хлопці і дівчата", type: "rightBucket" },
        { id: 12, text: "збігаючи з гори", type: "leftBucket" },
      ],
      leftBucketTitle: "Словосполучення",
      rightBucketTitle: "Сполуки слів",
    },

  ],
};
