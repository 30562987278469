import React from "react";
import { ListGroup } from "react-bootstrap";
import "./SelectionContainer.css"

const SelectionContainer = ({ onSelect }) => {
    const options = [
        { type: "test", label: "Тестові завдання" },
        { type: "interactive", label: "Інтерактивні вправи" },
        { type: "video", label: "Самостійна робота" },
    ];

    return (
        <div className="classContainer">
            <ListGroup>
                {options.map((option, index) => (
                    <ListGroup.Item
                        key={index}
                        action
                        variant="info"
                        className="list-item moduletype"
                        onClick={() => onSelect(option.type)}
                    >
                        {option.label}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default SelectionContainer;
