export const module16 = {
  title: "Спілкування",
  questions: [
    {
      id: 1,
      question: "1. Найбільше підходить для імпровізації в спілкуванні",
      options: [
        { id: "А", text: "підготовча тактика" },
        { id: "Б", text: "творча тактика" },
        { id: "В", text: "наслідувальна тактика" },
        { id: "Г", text: "неправильна тактика" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Визнач, яка з емоцій покращує настрій.",
      options: [
        { id: "А", text: "страх" },
        { id: "Б", text: "гнів" },
        { id: "В", text: "радість" },
        { id: "Г", text: "тривога" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Негативною є емоція",
      options: [
        { id: "А", text: "задоволення" },
        { id: "Б", text: "захоплення" },
        { id: "В", text: "відчай" },
        { id: "Г", text: "радість" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Найбільше допомагає в спілкуванні порада",
      options: [
        { id: "А", text: "Контролюйте фізичні прояви емоцій." },
        { id: "Б", text: "Ігноруйте почуття інших." },
        { id: "В", text: "Говоріть тільки про себе." },
        { id: "Г", text: "Не слухайте співрозмовника." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Визнач характеристику закритих запитань.",
      options: [
        { id: "А", text: "вимагають розгорнутої відповіді" },
        { id: "Б", text: "можна відповісти «так» або «ні»" },
        { id: "В", text: "вимагають уточнення" },
        { id: "Г", text: "всі наведені вище" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Відкритим запитанням є фраза",
      options: [
        { id: "А", text: "Тобі сподобалася книжка?" },
        { id: "Б", text: "Яка твоя улюблена книжка?" },
        { id: "В", text: "Чи була книжка цікавою?" },
        { id: "Г", text: "Чи читав / читала ти цю книжку?" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 7,
      question: "7. Прикладом ввічливого заперечення є фраза",
      options: [
        { id: "А", text: "Ні, я не згоден / не згодна." },
        { id: "Б", text: "Вибачте, але я бачу це зовсім інакше." },
        { id: "В", text: "Це неправильно." },
        { id: "Г", text: "Я не хочу обговорювати це." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 8,
      question: "8. Прикладом категоричного заперечення є фраза",
      options: [
        { id: "А", text: "Можливо, я не зрозумів / не зрозуміла." },
        { id: "Б", text: "Я не можу із цим погодитися." },
        { id: "В", text: "Я не хочу цього обговорювати." },
        { id: "Г", text: "Це не зовсім правильно." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 9,
      question: "9. Важливою під час заперечення є порада",
      options: [
        { id: "А", text: "не слухати співрозмовника" },
        { id: "Б", text: "зберігати позитивний настрій" },
        { id: "В", text: "використовувати грубі слова" },
        { id: "Г", text: "ігнорувати думку інших" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 10,
      question: "10. Прикладом м’якого заперечення є фраза",
      options: [
        { id: "А", text: "Це неправильно." },
        { id: "Б", text: "Я не можу із цим погодитися." },
        { id: "В", text: "На жаль, я не можу з вами погодитися." },
        { id: "Г", text: "Я не згоден / не згодна з тобою." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 11,
      question: "11. Прикладом переконання є фраза",
      options: [
        { id: "А", text: "Я думаю, що це хороша ідея." },
        { id: "Б", text: "Ти можеш зробити це, якщо захочеш." },
        { id: "В", text: "Я впевнений / упевнена, що це принесе користь." },
        { id: "Г", text: "Це не так важливо." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 12,
      question: "12. Прикладом ввічливого заперечення є фраза",
      options: [
        { id: "А", text: "Ти помиляєшся." },
        { id: "Б", text: "Я не згоден / не згодна з твоєю думкою." },
        { id: "В", text: "Я думаю, що ти не зовсім правий / права." },
        { id: "Г", text: "Це не так." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
  ],
};
