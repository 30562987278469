import { text_mod_26_1, text_mod_26_2, text_mod_26_3 } from "../text/Text.js";

export const module26 = {
  title: "Тема 26. Проза реалізму. Іван Нечуй-Левицький «Микола Джеря»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_26_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_26_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_26_3,
    },
    {
      id: 4,
      question: "1.	Повість «Микола Джеря» автор присвятив",
      options: [
        { id: "A", text: "своєму батьку" },
        { id: "B", text: "своїй родині" },
        { id: "C", text: "Миколі Лисенку" },
        { id: "D", text: "Тарасу Шевченку" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "2.	Повісті «Микола Джеря» стосуються всі слова в рядку",
      options: [
        { id: "A", text: "хроніка, романтичний стиль, Нимидора" },
        { id: "B", text: "повість, реалізм, Петро Джеря" },
        { id: "C", text: "соціально-побутовий роман, Вербівка, Любка" },
        { id: "D", text: "реалістичний стиль, 12 розділів, Ковбаненко" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Хибне продовження твердження розміщене в рядку «Микола був непересічною особистістю, бо…»",
      options: [
        { id: "A", text: "грав на скрипці" },
        { id: "B", text: "вмів читати і гарно малювати" },
        { id: "C", text: "був різьбярем" },
        { id: "D", text: "займався лимарством" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	Портретна деталь, якою автор підкреслив бунтарську вдачу Миколи, — це ",
      options: [
        { id: "A", text: "постава" },
        { id: "B", text: "очі" },
        { id: "C", text: "руки" },
        { id: "D", text: "брови" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Визначте подію, якої НЕ було у творі.",
      options: [
        { id: "A", text: "Пан не давав дозвіл на одруження Миколи й Нимидори" },
        { id: "B", text: "Перед втечею з села вербівці спалили панський тік" },
        { id: "C", text: "Донька отамана Ковбаненка видала Миколу десяцьким" },
        { id: "D", text: "Пан Бжозовський спеціально приїхав до Акермана, щоб засудити Миколу" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 9,
      question:
        "6.	Образ жар-птиці у фольклорі має кілька символічних значень. В образі головного героя НЕ розкрито символічне значення",
      options: [
        { id: "A", text: "Це символ унікальності, індивідуальності, незалежності людини" },
        { id: "B", text: "Це символ гармонії людини з навколишньою природою" },
        { id: "C", text: "Це символ омріяного щастя та кохання" },
        { id: "D", text: "Це символ надії на краще життя" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
