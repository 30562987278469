export const module48 = {
  title: "§ 48. Називні речення",
  questions: [
    {
      id: 1,
      question: "1. Односкладним є речення",
      options: [
        { id: "А", text: "Мати звернула в степ і пішла швидше." },
        { id: "Б", text: "Там, на березі річки, густі лозняки, вербові по́рості." },
        { id: "В", text: "Тут айстри, жовті гвоздики, висока рожа, чорнобривці." },
        { id: "Г", text: "Сукня ніжно-бузкового кольору в грецькому стилі." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Називним є речення-назва твору",
      options: [
        { id: "А", text: "«Сорочинський ярмарок»" },
        { id: "Б", text: "«Ой як крикнув же козак Сірко»" },
        { id: "В", text: "«Іван Сірко – великий характерник»" },
        { id: "Г", text: "«Дарувала Катерина козакам лимани»" }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Називними є всі речення-назви творів, ОКРІМ",
      options: [
        { id: "А", text: "«Дарунки скіфів»" },
        { id: "Б", text: "«Сторожова застава»" },
        { id: "В", text: "«Мене звати Варвара»" },
        { id: "Г", text: "«Євангеліє від Луки»" }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Називним є речення",
      options: [
        { id: "А", text: "У криницях, на дні, сиве забуття…" },
        { id: "Б", text: "Тепла зима окутана довгожданими снігами." },
        { id: "В", text: "У всякого своя доля і свій шлях широкий." },
        { id: "Г", text: "Стіл, застелений вишиваною скатертиною." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Називними є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Журний шепіт верби, полиневі вітри." },
        { id: "Б", text: "На рушнику святковий коровай." },
        { id: "В", text: "Безмежжя візерунків, ліній, квітів." },
        { id: "Г", text: "Морозяний, веселий і засніжений день." }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. З’ясуйте вид кожного односкладного речення в тексті/ ",
      dropdownQuestionParts: [
        "<i> <b>Речення (текст)</b> </br> 1) Довгождана весна...  ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) У полі хочеться дихати на повну, задивлятися в небесну глибінь. ",
        { id: "dropdown2", text: " - " },
        "<i><br />3) Але ж як почнеш орати, то в сопілку не будеш грати.",
        { id: "dropdown3", text: " - " },
        "<i><br />4) Тішишся сонячним теплом степового роздолля, сподіванням на майбутній урожай.",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>",
      ],
      options: [
        { id: "А", text: "безособове" },
        { id: "Б", text: "називне" },
        { id: "В", text: "узагальнено-особове" },
        { id: "Г", text: "неозначено-особове" },
        { id: "Д", text: "означено-особове" },
      ],
      correctAnswers: {
        dropdown1: "Б",
        dropdown2: "А",
        dropdown3: "В",
        dropdown4: "Д",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        '7. Розподіліть прості речення за типами: <i><b>односкладні називні речення; інші типи односкладних речень.</i></b> </br>Перетягни номер речення у відповідний кошик).',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Сьогодні вівторок. ", type: "rightBucket" },
        { id: 2, text: "День, залитий сонячним сяйвом. ", type: "leftBucket" },
        { id: 3, text: "Замок високий.  ", type: "leftBucket" },
        { id: 4, text: "Зимовий вечір по-сімейному затишний. ", type: "rightBucket" },
        { id: 5, text: "Високий замок. ", type: "leftBucket" },
        { id: 6, text: "У місті весна. ", type: "rightBucket" },

      ],
      leftBucketTitle: "Односкладні називні речення",
      rightBucketTitle: "Інші типи простих речень",
    },
    {
      id: 8,
      question:
        '8. Розподіліть прості речення за типами: <i><b>односкладні називні речення; інші типи односкладних речень.</i></b> </br>Перетягни номер речення у відповідний кошик).',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Твоя рідна земля. ", type: "leftBucket" },
        { id: 2, text: "Весняні канікули. ", type: "leftBucket" },
        { id: 3, text: "Канікули чудові! ", type: "rightBucket" },
        { id: 4, text: "Таємна пісня степового зілля. ", type: "leftBucket" },
        { id: 5, text: "Пора мініатюрних квітів і коників. ", type: "leftBucket" },
        { id: 6, text: "Наша мандрівка дуже вдала. ", type: "rightBucket" },
      ],
      leftBucketTitle: "Односкладні називні речення",
      rightBucketTitle: "Інші типи простих речень",
    },
  ],
};
