import {
    module01,
    module02,
    module03,
    module04,
    module05,
    module06,
    module07,
    module08,
    module09,
    module10,
    module11,
    module12,
    module13,
    module14,
    module15,
    module16,
    module17,
    module18,
    module19,
    module20,
    module21,
    module22,
    module23,
    module24,
    module25,
    module26,
    module27,
    module28,
    module29,
    module30,
    module31,
    module32,
    module33,
    module34,
    module35,
    module36,
    module37,
    module38,
    module39,
    module40,
    module41,
    module42,
    module43,
    module44,
    module45,
    module46,
    module47,
    module48,
    module49,
    module50,
    module51
} from "./modules.js";

export const routes = [
    { path: "/class/8/int/yac/modules/module01", module: module01 },
    { path: "/class/8/int/yac/modules/module02", module: module02 },
    { path: "/class/8/int/yac/modules/module03", module: module03 },
    { path: "/class/8/int/yac/modules/module04", module: module04 },
    { path: "/class/8/int/yac/modules/module05", module: module05 },
    { path: "/class/8/int/yac/modules/module06", module: module06 },
    { path: "/class/8/int/yac/modules/module07", module: module07 },
    { path: "/class/8/int/yac/modules/module08", module: module08 },
    { path: "/class/8/int/yac/modules/module09", module: module09 },
    { path: "/class/8/int/yac/modules/module10", module: module10 },
    { path: "/class/8/int/yac/modules/module11", module: module11 },
    { path: "/class/8/int/yac/modules/module12", module: module12 },
    { path: "/class/8/int/yac/modules/module13", module: module13 },
    { path: "/class/8/int/yac/modules/module14", module: module14 },
    { path: "/class/8/int/yac/modules/module15", module: module15 },
    { path: "/class/8/int/yac/modules/module16", module: module16 },
    { path: "/class/8/int/yac/modules/module17", module: module17 },
    { path: "/class/8/int/yac/modules/module18", module: module18 },
    { path: "/class/8/int/yac/modules/module19", module: module19 },
    { path: "/class/8/int/yac/modules/module20", module: module20 },
    { path: "/class/8/int/yac/modules/module21", module: module21 },
    { path: "/class/8/int/yac/modules/module22", module: module22 },
    { path: "/class/8/int/yac/modules/module23", module: module23 },
    { path: "/class/8/int/yac/modules/module24", module: module24 },
    { path: "/class/8/int/yac/modules/module25", module: module25 },
    { path: "/class/8/int/yac/modules/module26", module: module26 },
    { path: "/class/8/int/yac/modules/module27", module: module27 },
    { path: "/class/8/int/yac/modules/module28", module: module28 },
    { path: "/class/8/int/yac/modules/module29", module: module29 },
    { path: "/class/8/int/yac/modules/module30", module: module30 },
    { path: "/class/8/int/yac/modules/module31", module: module31 },
    { path: "/class/8/int/yac/modules/module32", module: module32 },
    { path: "/class/8/int/yac/modules/module33", module: module33 },
    { path: "/class/8/int/yac/modules/module34", module: module34 },
    { path: "/class/8/int/yac/modules/module35", module: module35 },
    { path: "/class/8/int/yac/modules/module36", module: module36 },
    { path: "/class/8/int/yac/modules/module37", module: module37 },
    { path: "/class/8/int/yac/modules/module38", module: module38 },
    { path: "/class/8/int/yac/modules/module39", module: module39 },
    { path: "/class/8/int/yac/modules/module40", module: module40 },
    { path: "/class/8/int/yac/modules/module41", module: module41 },
    { path: "/class/8/int/yac/modules/module42", module: module42 },
    { path: "/class/8/int/yac/modules/module43", module: module43 },
    { path: "/class/8/int/yac/modules/module44", module: module44 },
    { path: "/class/8/int/yac/modules/module45", module: module45 },
    { path: "/class/8/int/yac/modules/module46", module: module46 },
    { path: "/class/8/int/yac/modules/module47", module: module47 },
    { path: "/class/8/int/yac/modules/module48", module: module48 },
    { path: "/class/8/int/yac/modules/module49", module: module49 },
    { path: "/class/8/int/yac/modules/module50", module: module50 },
    { path: "/class/8/int/yac/modules/module51", module: module51 },
];