import { text_mod_56 } from "../text/Text.js";

export const module56 = {
  title: "Урок 56. Задачі з буквеними даними. Розв'язання рівнянь.​ Повторення вивчених випадків множення і ділення. Розв'язання задач.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_56,
    },
  ],
};
