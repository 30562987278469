import {text_mod_40, } from "../text/Text.js";
export const module40 = {
  title:
    "§40. Мої навчальні досягнення​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_40, },
    ]
  }
