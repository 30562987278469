export const module05 = {
  title: "Безособові речення. З любов’ю до свого",
  questions: [
    {
      id: 1,
      question: "1. Безособові речення виражають",
      options: [
        { id: "А", text: "загальні судження, висновки, поради" },
        { id: "Б", text: "дію конкретної особи (мовця, співрозмовника) чи спонукання до дії" },
        { id: "В", text: "дію, що виконує або виконувала невизначена особа" },
        { id: "Г", text: "дію без участі особи як природне чи стихійне явище, психічний або фізичний стан людини, незалежний від її волі" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Безособовим є односкладне речення",
      options: [
        { id: "А", text: "Збирають чебрець напередодні Трійці." },
        { id: "Б", text: "Вишивають традиційно червоними й чорними нитками." },
        { id: "В", text: "Дихається легко українським повітрям." },
        { id: "Г", text: "Пишаюся бути українцем." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Безособовим є односкладне речення",
      options: [
        { id: "А", text: "Моя Україна." },
        { id: "Б", text: "Шила в мішку не сховаєш." },
        { id: "В", text: "Люблю тебе, моя країно!" },
        { id: "Г", text: "В українській хаті завжди затишно." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Безособовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Нашої волі не зламати." },
        { id: "Б", text: "В Україні люблять приймати гостей." },
        { id: "В", text: "Світає." },
        { id: "Г", text: "Мене морозить." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Безособовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Поїхав би в Карпати." },
        { id: "Б", text: "Поїхати б у Карпати." },
        { id: "В", text: "Заморосило осінь у сльозу (Л. Костенко)." },
        { id: "Г", text: "За вікном хурделило (В. Яворівський)." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Позначте речення, що потребує редагування",
      options: [
        { id: "А", text: "В українській хаті завжди затишно." },
        { id: "Б", text: "Мною підготовлено запрошення." },
        { id: "В", text: "Радо зустрічаємо гостей." },
        { id: "Г", text: "Готуємося приймати гостей." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
