import {text_mod_6, } from "../text/Text.js";
export const module06 = {
  title:
    "Урок 9, 12. Переставний закон множення.​ Знаходження невідомих множників. Перевірка ділення за допомогою дії множення. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_6, },
    ]
  }
