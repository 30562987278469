import {text_mod_56, } from "../text/Text.js";
export const module56 = {
  title:
    "Урок 1. Абетка. Звуки та букви",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_56, },
    ]
  };
