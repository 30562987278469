import { text_mod_37, text_mod_38, text_mod_39 } from "../text/Text";

export const module13 = {
  title: "Тема 13. «Чи не той то хміль» — народна пісня про перемогу козацького війська у битві під Жовтими Водами",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_37,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_38,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_39,
    },
    {
      id: 4,
      question: "1. Пісня «Чи не той то хміль...» уславлює",
      options: [
        { id: "А", text: "перемогу запорожців над татарами" },
        { id: "Б", text: "остаточну перемогу козаків у Визвольній війні" },
        { id: "В", text: "першу перемогу у Визвольній війні та Богдана Хмельницького" },
        { id: "Г", text: "Богдана Хмельницького" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Із хмелем народ порівнює Богдана Хмельницького за його",
      options: [
        { id: "А", text: "відвагу" },
        { id: "Б", text: "енергійну й вольову вдачу" },
        { id: "В", text: "патріотизм" },
        { id: "Г", text: "сміливість" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "3. У пісні «Чи не той то хміль» НЕ відображені почуття",
      options: [
        { id: "А", text: "гордості за гідного гетьмана" },
        { id: "Б", text: "любові до рідного краю" },
        { id: "В", text: "захоплення козацькою звитягою" },
        { id: "Г", text: "дошкульної іронії щодо загарбників" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "4. У поданих рядках «Чи не той то хміль, що коло тичин в’ється? / Гей, той то Хмельницький, що з ляхами б’ється» використано такий художній засіб",
      options: [
        { id: "А", text: "постійні епітети" },
        { id: "Б", text: "пестливі слова" },
        { id: "В", text: "паралелізм" },
        { id: "Г", text: "метафору" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "5. НЕ відображено образ Богдана Хмельницького в",
      options: [
        { id: "А", text: "анімаційних фільмах" },
        { id: "Б", text: "образотворчому мистецтві" },
        { id: "В", text: "скульптурі" },
        { id: "Г", text: "літературі" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 9,
      question: "6. Першу скульптурну композицію на честь Богдана Хмельницького споруджено в місті",
      options: [
        { id: "А", text: "Жовті Води" },
        { id: "Б", text: "Дніпро" },
        { id: "В", text: "Київ" },
        { id: "Г", text: "Чигирин" },
      ],
      correctAnswer: "В",
      isTest: true,
    },

  ],
};
