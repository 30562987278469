import {text_mod_32, } from "../text/Text.js";
export const module32 = {
  title:
    "§32. Слова, що називають предмети, ознаки, дії, числа. ​Розподіл слів на групи за значенням і питаннями​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_32, },
    ]
  }
