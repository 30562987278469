import {
    module01,
    module02,
    module03,
    module04,
    module05,
    module06,
    module07,
    module08,
    module09,
    module10,
    module11,
    module22_23,
    module24_25,
    module26_27,
    module29_31,
    module32_34,
    module35_38,
    module39_41
} from "./modules.js";

export const routes = [
    { path: "/class/8/ukrlit/avr/modules/module01", module: module01 },
    { path: "/class/8/ukrlit/avr/modules/module02", module: module02 },
    { path: "/class/8/ukrlit/avr/modules/module03", module: module03 },
    { path: "/class/8/ukrlit/avr/modules/module04", module: module04 },
    { path: "/class/8/ukrlit/avr/modules/module05", module: module05 },
    { path: "/class/8/ukrlit/avr/modules/module06", module: module06 },
    { path: "/class/8/ukrlit/avr/modules/module07", module: module07 },
    { path: "/class/8/ukrlit/avr/modules/module08", module: module08 },
    { path: "/class/8/ukrlit/avr/modules/module09", module: module09 },
    { path: "/class/8/ukrlit/avr/modules/module10", module: module10 },
    { path: "/class/8/ukrlit/avr/modules/module11", module: module11 },
    { path: "/class/8/ukrlit/avr/modules/module22_23", module: module22_23 },
    { path: "/class/8/ukrlit/avr/modules/module24_25", module: module24_25 },
    { path: "/class/8/ukrlit/avr/modules/module26_27", module: module26_27 },
    { path: "/class/8/ukrlit/avr/modules/module29_31", module: module29_31 },
    { path: "/class/8/ukrlit/avr/modules/module32_34", module: module32_34 },
    { path: "/class/8/ukrlit/avr/modules/module35_38", module: module35_38 },
    { path: "/class/8/ukrlit/avr/modules/module39_41", module: module39_41 },
];
