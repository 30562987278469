import {text_mod_8, } from "../text/Text.js";
export const module08 = {
  title:
    "Урок 8. Мої друзі",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_8, },
    ]
  };