import image010 from "./images/ukrlit/image010.png";
import image0111 from "./images/ukrlit/image0111.jpg";
export const module10 = {
  title: "§ 18. Барокова поезія",
  questions: [
    {
      id: 1,
      question: "1. Слово бароко в перекладі з італійської означає",
      options: [
        { id: "А", text: "чистий, світлий" },
        { id: "Б", text: "строкатий, пістрявий" },
        { id: "В", text: "вибагливий, химерний" },
        { id: "Г", text: "виразний, яскравий" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Стилю бароко НЕ властива ознака",
      options: [
        { id: "А", text: "поєднання релігійних і світських мотивів" },
        { id: "Б", text: "тяжіння до різноманітних контрастів" },
        { id: "В", text: "метафоричність і алегоричність" },
        { id: "Г", text: "культ краси людського тіла" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. НЕПРАВИЛЬНИМ є твердження щодо вірша «Їхав козак за Дунай»",
      options: [
        {
          id: "А",
          text: "провідний мотив – зустріч закоханих після тривалої розлуки",
        },
        { id: "Б", text: "автор твору – Семен Климовський" },
        { id: "В", text: "твір написано у формі діалогу" },
        { id: "Г", text: "зразок барокової поезії" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. У вірші «Їхав козак за Дунай» НЕМАЄ образу",
      options: [
        { id: "А", text: "коня вороненького" },
        { id: "Б", text: "степу широкого" },
        { id: "В", text: "білих ручок" },
        { id: "Г", text: "ясних очок" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question:
        "5. Розгляньте зображення.</br>Цей твір Симеона Полоцького за жанром є:",
      options: [
        { id: "А", text: "віршем-відлунням" },
        { id: "Б", text: "раком літеральним" },
        { id: "В", text: "зоровою поезією" },
        { id: "Г", text: "лабіринтом" },
      ],
      imgPath: image010,
      addClass: "horizontal",
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Розподіліть зображення за належністю до архітектурного стилю. </br>",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "А) Андріївська церква", type: "leftBucket" },
        { id: 2, text: "Б) Миколаївський костел", type: "rightBucket" },
        { id: 3, text: "В) будинок на Хрещатику", type: "rightBucket" },
        { id: 4, text: "Г) Маріїнський палац ", type: "leftBucket" },
        { id: 5, text: "Д) брама Заборовського", type: "leftBucket" },
      ],
      imgPath: image0111,
      addClass: "horizontal",
      leftBucketTitle: "Споруда в стилі бароко",
      rightBucketTitle: "Споруда в іншому стилі",
    },
    {
      id: 7,
      question:
        "7. Опрацювавши відеоматеріал ресурсу «Unisonll» “Їхав козак за Дунай” (2 хв 43 с), напишіть на нього відгук (настрій, ритм, музичні інструменти, манера виконання, відповідність ілюстративного матеріалу змісту пісні, ваші враження та ін.).",
      videoPath:
        "https://www.youtube.com/embed/7iT54oRUUz0?si=AI1XnOGG44Ru--_m",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
