import {text_mod_11, } from "../text/Text.js";
export const module11 = {
  title:
    "Уроки 44-48. Додавання чисел виду 25 + 43. Віднімання чисел виду 34 – 21. Додавання чисел​ виду 26 + 4. Віднімання чисел виду 40 – 3​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_11, },
    ]
  }
