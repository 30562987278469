import {text_mod_49, } from "../text/Text.js";
export const module49 = {
  title:
    "§49. Добір дієслів для висловлення власних думок",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_49, },
    ]
  }
