import {text_mod_9, } from "../text/Text.js";
export const module09 = {
  title:
    "Уроки 35-39. Лічба десятками. Попереднє і наступне числа. Додавання і віднімання іменованих чисел.​ Вирази з дужками. Читання виразів із дужками​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_9, },
    ]
  }
