import {text_mod_7, } from "../text/Text.js";
export const module07 = {
  title:
    "§7. Звукові значення ​букви Ї",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_7, },
    ]
  }
