import { text_mod_25, text_mod_26, text_mod_27 } from "../text/Text";

export const module09 = {
  title: "Тема 9. Дзвінка Матіяш «Мене звати Варвара»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_25,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_26,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_27,
    },
    {
      id: 4,
      question: "1. У повісті «Мене звати Варвара» порушено усі проблеми, ОКРІМ",
      options: [
        { id: "А", text: "Збереження довкілля" },
        { id: "Б", text: "Батьки й діти" },
        { id: "В", text: "Стосунки між ровесниками" },
        { id: "Г", text: "Сенс людського життя" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Варвара Ханенко була",
      options: [
        { id: "А", text: "письменницею" },
        { id: "Б", text: "колекціонеркою" },
        { id: "В", text: "художницею" },
        { id: "Г", text: "співачкою" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Головну героїню повісті «Мене звати Варвара» батьки назвали Варварою",
      options: [
        { id: "А", text: "на честь Варвари Ханенко" },
        { id: "Б", text: "на честь біблійної великомучениці Варвари" },
        { id: "В", text: "коли дізналися про давню традицію австрійців Barbarazweig" },
        { id: "Г", text: "бо подобалося рідкісне ім'я" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "4. Улюблений літературний герой Варвари Сабадаш –",
      options: [
        { id: "А", text: "Гаррі Поттер" },
        { id: "Б", text: "Шерлок Голмс" },
        { id: "В", text: "Герміона Ґрейнджер" },
        { id: "Г", text: "Доктор Джон Вотсон" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "5. Класна керівничка Варвари Ольга Миколаївна любить, щоб учні",
      options: [
        { id: "А", text: "завжди тихо сиділи на уроках" },
        { id: "Б", text: "виконували всі завдання" },
        { id: "В", text: "розвивали творче мислення і пізнавали нове" },
        { id: "Г", text: "всі навчалися «на відмінно»" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	Варвару Ханенко і головну героїню поєднує",
      options: [
        { id: "А", text: "бажання змінити думку оточення про себе." },
        { id: "Б", text: "прагнення пізнавати нове." },
        { id: "В", text: "намагання відстояти своє право вибору." },
        { id: "Г", text: "бажання мати гарні стосунки з батьками." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },

  ],
};
