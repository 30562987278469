export const module54_55 = {
  title: "§ 54–55. Розділові знаки між однорідними членами речення",
  questions: [
    {
      id: 1,
      question: "1. Між однорідними членами треба поставити тире в реченні <i>(розділові знаки пропущено)</i>",
      options: [
        { id: "А", text: "На уроках Микола слухав писав." },
        { id: "Б", text: "Глибоке весняне небо синіє манить." },
        { id: "В", text: "На столі були м’ясо риба сухарі." },
        { id: "Г", text: "Літнє сонце не гріло пекло." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Між однорідними членами треба поставити ко́му в реченні <i>(розділові знаки пропущено) </i>",
      options: [
        { id: "А", text: "Не біду щастя чекала Оксана." },
        { id: "Б", text: "То не Галя ясне сонце." },
        { id: "В", text: "Сонце опівдні сяє припікає." },
        { id: "Г", text: "Зорянка не ходить літає птахою." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Пунктуаційну помилку допущено в реченні",
      options: [
        { id: "А", text: "Ворона й за море літає та дурна вертається." },
        { id: "Б", text: "Очерети понад берегом шелестіли ліниво та сонно." },
        { id: "В", text: "Звіддалік чулися короткі та чіткі Санькові слова." },
        { id: "Г", text: "Вечір минав під веселі розмови та щирий сміх." }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Пунктуаційну помилку допущено в реченні",
      options: [
        { id: "А", text: "Високо літає, та низько сідає." },
        { id: "Б", text: "Зорі тьмяніють, та сяйво ще сіють." },
        { id: "В", text: "Поспіли груші, та яблука на Спаса." },
        { id: "Г", text: "Зібралися вчасно, та забули сценарій." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Пунктуаційної помилки немає в реченні",
      options: [
        { id: "А", text: "Хоч і виїхали вчасно – але трохи спізнилися." },
        { id: "Б", text: "Кругом пахтить цвіт жасмину і каштанів, і липи." },
        { id: "В", text: "Чи то успіхи чи то друзі підняли мені настрій." },
        { id: "Г", text: "Любов і ненависть, добро і зло часто йдуть поруч." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. З’ясуйте вид зв’язку між однорідними членами речення.",
      dropdownQuestionParts: [
        "<i> <b>Вид зв’язку</b> </br> 1) безсполучниковий ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) безсполучниковий протиставний ",
        { id: "dropdown2", text: " - " },
        "<i><br />3) сполучниковий протиставний ",
        { id: "dropdown3", text: " - " },
        "<i><br />4) сполучниковий єднальний ",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>",
      ],
      options: [
        { id: "А", text: "Не планували поїздку, проте з радістю погодилися." },
        { id: "Б", text: "Лежу в холодку над чистим, прозорим струмком." },
        { id: "В", text: "Не тільки дорослі, а й діти нині стають волонтерами." },
        { id: "Г", text: "До конкурсу треба підготувати або пісню, або танець." },
        { id: "Д", text: "Не закордонням – Україною хочеться подорожувати." },
      ],
      correctAnswers: {
        dropdown1: "Б",
        dropdown2: "Д",
        dropdown3: "А",
        dropdown4: "В",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за розділовими знаками: <i><b>речення, у яких не допущено пунктуаційної помилки; речення, у яких допущено пунктуаційну помилку.</b></i> <br>(Перетягни номер речення у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Перемагай труднощі розумом, а небезпеку – досвідом.", type: "leftBucket" },
        { id: 2, text: "Було чути і жаб’яче кумкання і солов’їний спів.", type: "rightBucket" },
        { id: 3, text: "Уся сім’я вийшла саджати картоплю.", type: "leftBucket" },
        { id: 4, text: "Назар не йшов біг на довгождану зустріч.", type: "rightBucket" },
        { id: 5, text: "Поспішали, та спізнилися на тролейбус.", type: "leftBucket" },
        { id: 6, text: "Чи ліхтарі чи, може, зорі осявали мені шлях додому.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у яких не допущено пунктуаційної помилки",
      rightBucketTitle: "Речення, у яких допущено пунктуаційну помилку",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за розділовими знаками: <i><b>речення, у яких не допущено пунктуаційної помилки; речення, у яких допущено пунктуаційну помилку.</b></i> <br>(Перетягни номер речення у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Сміх і радість, і тепло сповнювали душу по вінця.", type: "rightBucket" },
        { id: 2, text: "Прийде щастя світле та прозоре.", type: "leftBucket" },
        { id: 3, text: "Для плоту згодилися і сухі палиці, і моту́зки дикого хмелю.", type: "leftBucket" },
        { id: 4, text: "Ні вдень, ні вночі не припинявся стукіт поїздів.", type: "rightBucket" },
        { id: 5, text: "Казала та не зав’язала.", type: "rightBucket" },
        { id: 6, text: "Людей питай, а свій розум май.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення, у яких не допущено пунктуаційної помилки",
      rightBucketTitle: "Речення, у яких допущено пунктуаційну помилку",
    },
  ],
};
