import {text_mod_26, } from "../text/Text.js";
export const module26 = {
  title:
    "Урок 5. Літера ч маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_26, },
    ]
  };