import { ListGroup } from "react-bootstrap";

import "./LessonDropdown.css";

function LessonDropdown() {
  return (
    <ListGroup>
      <ListGroup.Item
        key={module.path}
        action
        className="list-item-mob"
        variant="info"
        href={`/class/1`}
      >
        Клас 1
      </ListGroup.Item>

      <ListGroup.Item
        key={module.path}
        action
        className="list-item-mob"
        variant="info"
        href={`/class/2`}
      >
        Клас 2
      </ListGroup.Item>

      <ListGroup.Item
        key={module.path}
        action
        className="list-item-mob"
        variant="info"
        href={`/class/3`}
      >
        Клас 3
      </ListGroup.Item>

      <ListGroup.Item
        key={module.path}
        action
        className="list-item-mob"
        variant="info"
        href={`/class/8`}
      >
        Клас 8
      </ListGroup.Item>

      <ListGroup.Item
        key={module.path}
        action
        className="list-item-mob"
        variant="info"
        href={`/instruction`}
      >
        Інструкція
      </ListGroup.Item>

      <ListGroup.Item
        key={module.path}
        action
        className="list-item-mob"
        variant="info"
        href={`/about`}
      >
        Про додаток
      </ListGroup.Item>

      <ListGroup.Item
        key={module.path}
        action
        className="list-item-mob"
        variant="info"
        href={`/author_info`}
      >
        Про авторів
      </ListGroup.Item>
    </ListGroup>
  );
}

export default LessonDropdown;
