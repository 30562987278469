export const module22_23 = {
  title: "§ 21-23. Давня кримськотатарська література. Кримські татари й українці в сучасній літературі",
  questions: [
    {
      id: 1,
      question: "1. Кримськотатарська література зародилася в",
      options: [
        { id: "А", text: "V ст." },
        { id: "Б", text: "Х ст." },
        { id: "В", text: "ХІІІ ст." },
        { id: "Г", text: "ХVІ ст." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Автором твору «Фонтан сліз у Бахчисарайському палаці» є",
      options: [
        { id: "А", text: "Махмуд Киримли" },
        { id: "Б", text: "Гази Гірей" },
        { id: "В", text: "Ашик Умер" },
        { id: "Г", text: "народ" }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. За жанром «Фонтан сліз у Бахчисарайському палаці» –",
      options: [
        { id: "А", text: "повість" },
        { id: "Б", text: "переказ" },
        { id: "В", text: "казка" },
        { id: "Г", text: "дума" }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. У розділі «Рашит» другорядним персонажем є",
      options: [
        { id: "А", text: "Санько" },
        { id: "Б", text: "Грицик" },
        { id: "В", text: "Швайка" },
        { id: "Г", text: "Кудьма" }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Позасюжетним елементом у розділі «Рашит» є уривок",
      options: [
        { id: "А", text: "Спочатку йшли вночі, а вдень ховалися по байраках чи просто в траві. А тепер, схоже, навіть Швайці було байдуже. Їм аби дістатися до якоїсь річечки чи бодай калюжі." },
        { id: "Б", text: "Швайка ніс на руках Санька. Після того, як Санько відвадив татарина, його ніби хто наврочив. Пройде кілька кроків — і зупиняється, начебто прислухається до чогось." },
        { id: "В", text: "Санько марив. А може, й не марив. Просто йому ввижалося, ніби його й не було на світі і водночас він був. От тільки який він був із себе — Санько сказати не міг." },
        { id: "Г", text: "Трави стояли високі... Жовта балка була ще зеленою. У низовині, довкола маленької ямки, росло кілька кущиків очерету." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: '6. Розподіліть слова літературних героїв: <i><b>Швайка; Рашит.</b></i> <br>(Перетягніть цитати у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "«Треба тікати якнайдалі звідсіля. Бо ж про це місце не лише я, а й татарин знає»", type: "leftBucket" },
        { id: 2, text: "«Щоб мені сиділи тихо, мов куріпки. І що б не трапилось, не видавайте себе, зрозуміло?»", type: "leftBucket" },
        { id: 3, text: "«А дістанемося плавнів — тебе там зовсім вилікують. О, у нас є такі знахарі! І найкращий з-поміж них — дід Кудьма»", type: "leftBucket" },
        { id: 4, text: "«Я поїхало… е-е, поїхав. Інші ще не сплять, можуть побачити, що мене нема. А вранці табун пущу сліди топтати»", type: "rightBucket" },
        { id: 5, text: "«Потім приходив люди Іслам-бека, — продовжував він. — Сьогодні з сонцем. Я їм сказав про два урус. Подалися по сліду»", type: "rightBucket" },
        { id: 6, text: "«Кінний показав знак Іслам-бека. А Іслам-бек мій хазяїн. Тоді взяв у мене два коні — і ф'ють!»", type: "rightBucket" }
      ],
      leftBucketTitle: "Швайка",
      rightBucketTitle: "Рашит"
    },
    {
      id: 7,
      question:
        "7. Послухайте кримськотатарську народну пісню «Два джерела» у виконанні Сусанни Карпенко (2 хв 17 с) і в письмовій формі висловіть свої враження від почутого (зміст пісні (українські титри), темп, настрій, музичні інструменти, манера виконання; 5-7 речень).",
      videoPath:
        "https://www.youtube.com/embed/vSZ3EGnOKIw?si=OSoQRf-YU8RLUWtV",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
