export const module81 = {
  title: "§ 81. Односкладне речення. Неповне речення",
  questions: [
    {
      id: 1,
      question: "1. Односкладним є речення",
      options: [
        { id: "А", text: "Скільки щастя природа здатна принести людині!" },
        { id: "Б", text: "Чи не Ілля Муромець постав ото на горі на сторожі?" },
        { id: "В", text: "Нехай у серці не згасне любові священний вогонь." },
        { id: "Г", text: "За дурною головою й ногам немає спокою." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Називним є речення-назва твору",
      options: [
        { id: "А", text: "«Сватання на Гончарівці»" },
        { id: "Б", text: "«Чайка Джонатан Лівінґстон»" },
        { id: "В", text: "«Як Робін Гуд став розбійником»" },
        { id: "Г", text: "«Фонтан сліз у Бахчисарайському палаці»" }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Означено-особовим є речення",
      options: [
        { id: "А", text: "Під вільховими корчами ловили марен із білими вусами." },
        { id: "Б", text: "Довелося опустити вітрила та взятися за весла." },
        { id: "В", text: "Минаю лісову галяву, іду через молодий ялинник." },
        { id: "Г", text: "На людський роток не навісиш замок." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Неозначено-особовим є речення",
      options: [
        { id: "А", text: "Лови рибу не на сковороді, а у воді." },
        { id: "Б", text: "Ткала з ранку до вечора і пісню наспівувала." },
        { id: "В", text: "За першим курганом зістрибнули з коней і залягли." },
        { id: "Г", text: "Звісно, не всі вовки страшні, особливо влітку." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Неповними є речення, ОКРІМ",
      options: [
        { id: "А", text: "З ким поведешся, того й наберешся." },
        { id: "Б", text: "Цикада на гілці з вишневим цвітом." },
        { id: "В", text: "Сяк-так пересидів довгу ніч." },
        { id: "Г", text: "На столі прикритий рушником сніданок." }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Доберіть до кожного типу односкладного речення приклад.",
      dropdownQuestionParts: [
        "<i> <b>Тип односкладного речення</b> </br> 1) узагальнено-особове ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) неозначено-особове ",
        { id: "dropdown2", text: " - " },
        "<i><br />3) безособове ",
        { id: "dropdown3", text: " - " },
        "<i><br />4) називне ",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>",
      ],
      options: [
        { id: "А", text: "Боятися во́вка – у ліс не ходити." },
        { id: "Б", text: "Живи просто – проживеш років зо сто." },
        { id: "В", text: "Теплий зоряний вечір, сповнений чарів." },
        { id: "Г", text: "На узгір’ї наші хати́, садки, горо́ди." },
        { id: "Д", text: "Козаків часто порівнювали з орлами." }
      ],
      correctAnswers: {
        dropdown1: "Б",
        dropdown2: "Д",
        dropdown3: "А",
        dropdown4: "В",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за типами: <i><b>односкладні речення; двоскладні речення.</b></i> <br>(Перетягніть номер речення у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Сонечко, як завжди, усміхалося з неба.", type: "rightBucket" },
        { id: 2, text: "Як добре, як щасливо було Юркові!", type: "leftBucket" },
        { id: 3, text: "У козацькому таборі пожвавлення.", type: "rightBucket" },
        { id: 4, text: "Борітеся – поборете.", type: "leftBucket" },
        { id: 5, text: "Ніжний спів ластіво́к, журавлів хоровод.", type: "leftBucket" },
        { id: 6, text: "Після дощу капелюх не потрібний.", type: "rightBucket" },

      ],
      leftBucketTitle: "Односкладні речення",
      rightBucketTitle: "Двоскладні речення",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за типами: <i><b>односкладні речення; двоскладні речення.</b></i> <br>(Перетягніть номер речення у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Серпиком місяця пливу в золотистій хмарі.", type: "leftBucket" },
        { id: 2, text: "Надворі зима, завіяна снігами.", type: "rightBucket" },
        { id: 3, text: "Наука в ліс не веде, а з лісу виводить.", type: "rightBucket" },
        { id: 4, text: "Мене везуть у царство трав, річок і таємничих озер.", type: "leftBucket" },
        { id: 5, text: "У ярузі розкошували солов’ї.", type: "rightBucket" },
        { id: 6, text: "Було затишно, пахло грибами.", type: "leftBucket" },
      ],
      leftBucketTitle: "Односкладні речення",
      rightBucketTitle: "Двоскладні речення",
    },
  ],
};
