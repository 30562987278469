export const teacherDataMock = {
    subjects: [
        {
            name: "Українська мова",
            modules: [
                {
                    title: "Завдання до 01", link: "/class/8/ukrmova/avr/modules/module01",
                    isDone: true,
                    tooltip: "Словосполучення і речення як одиниці синтаксису. Ознаки словосполучення. Головне й залежне слово в словосполученні. Типи словосполучень за будовою.",
                    type: "test",
                },
                {
                    title: "Завдання до 02", link: "/class/8/ukrmova/avr/modules/module02",
                    isDone: true,
                    tooltip: "Граматична помилка в словосполученні.",
                    type: "test",
                },
                {
                    title: "Завдання до §3", link: "/class/8/ukrmova/avr/modules/module3",
                    isDone: true,
                    tooltip: "§ 3. Дієслово та його форми",
                    type: "test",
                }, {
                    title: "Завдання до §4", link: "/class/8/ukrmova/avr/modules/module4",
                    isDone: true,
                    tooltip: "§ 4. Розряди прислівників за значенням",
                    type: "test",
                }, {
                    title: "Завдання до §5", link: "/class/8/ukrmova/avr/modules/module5",
                    isDone: true,
                    tooltip: "§ 5. Сполучники сурядності та підрядності.",
                    type: "test",
                }, {
                    title: "Завдання до §6", link: "/class/8/ukrmova/avr/modules/module6",
                    isDone: true,
                    tooltip: "§ 6. Написання прислівників",
                    type: "test",
                }, {
                    title: "Завдання до §7", link: "/class/8/ukrmova/avr/modules/module7",
                    isDone: true,
                    tooltip: "§ 7. Написання НЕ з різними частинами мови",
                    type: "test",
                }, {
                    title: "Завдання до §8", link: "/class/8/ukrmova/avr/modules/module8",
                    isDone: true,
                    tooltip: "§ 8. Уживання прийменників у, в і початкових у-, в-; сполучників, часток і, й та початкових і-, й-; прийменника з і його варіантів із, зі (зо).",
                    type: "test",
                }, {
                    title: "Завдання до §9", link: "/class/8/ukrmova/avr/modules/module9",
                    isDone: true,
                    tooltip: "§ 9. Граматичні помилки.",
                    type: "test",
                }, {
                    title: "Завдання до §18-19", link: "/class/8/ukrmova/avr/modules/module18_19",
                    isDone: true,
                    tooltip: "§ 18–19. Граматична основа двоскладного речення. Підмет",
                    type: "test",
                }, {
                    title: "Завдання до §20-21", link: "/class/8/ukrmova/avr/modules/module20_21",
                    isDone: false,
                    tooltip: "§ 20–21. Граматична основа двоскладного речення. Присудок",
                    type: "test",
                }, {
                    title: "Завдання до §23", link: "/class/8/ukrmova/avr/modules/module23",
                    isDone: false,
                    tooltip: "§ 23. Узгодження підмета й присудка. Тире між підметом і присудком",
                    type: "test",
                },
            ],
        },
        {
            name: "Українська література",
            modules: [
                {
                    title: "Завдання до §1", link: "/class/8/ukrlit/avr/modules/module01",
                    isDone: true,
                    tooltip: "§1. Вступ. Художній твір в історико-культурному контексті доби.",
                    type: "test",
                },
                {
                    title: "Завдання до §2-3", link: "/class/8/ukrlit/avr/modules/module02",
                    isDone: true,
                    tooltip: "§2-3. Культура й віра давніх українців. Історичне оповідання Івана Білика «Дарунки скіфів»",
                    type: "test",
                },
                {
                    title: "Завдання до §4-5", link: "/class/8/ukrlit/avr/modules/module03",
                    isDone: true,
                    tooltip: "§4-5. Русь, її культура та письменство. Старина про Іллю Муромця та Солов’я.",
                    type: "test",
                },
                {
                    title: "Завдання до §6-7", link: "/class/8/ukrlit/avr/modules/module04",
                    isDone: true,
                    tooltip: "§ 6-7. Своєрідність давнього українського письменства. «Повчання дітям» Володимира Мономаха.",
                    type: "test",
                },
                {
                    title: "Завдання до §8-9", link: "/class/8/ukrlit/avr/modules/module05",
                    isDone: true,
                    tooltip: "§ 8-9. Образ Русі в сучасній українській літературі.",
                    type: "test",
                },
                {
                    title: "Завдання до §10-11", link: "/class/8/ukrlit/avr/modules/module06",
                    isDone: false,
                    tooltip: "§ 10-11. Біблія – одна зі священних книг.",
                    type: "test",
                },
                {
                    title: "Завдання до §12-14", link: "/class/8/ukrlit/avr/modules/module07",
                    isDone: false,
                    tooltip: "§ 12-14. Біблія та українська література.",
                    type: "test",
                },
                {
                    title: "Завдання до §15", link: "/class/8/ukrlit/avr/modules/module08",
                    isDone: false,
                    tooltip: "§ 15. Народні думи.",
                    type: "test",
                },
            ],
        },
        {
            name: "Українська та зарубіжна література",
            modules: [
                {
                    title: "Завдання до §1", link: "/class/8/int/yac/modules/module01",
                    isDone: true,
                    tooltip: "Тема 1. Художній твір в історико-культурному контексті доби",
                    type: "test",
                },
                {
                    title: "Завдання до §2", link: "/class/8/int/yac/modules/module02",
                    isDone: true,
                    tooltip: "Тема 2. Антична література. Давньогрецька міфологія",
                    type: "test",
                },
                {
                    title: "Завдання до §3", link: "/class/8/int/yac/modules/module03",
                    isDone: true,
                    tooltip: "Тема 3. Гомер «Іліада»",
                    type: "test",
                },
                {
                    title: "Завдання до §4", link: "/class/8/int/yac/modules/module04",
                    isDone: true,
                    tooltip: "Тема 4. Есхіл «Прометей закутий»",
                    type: "test",
                },
                {
                    title: "Завдання до §5", link: "/class/8/int/yac/modules/module05",
                    isDone: true,
                    tooltip: "Тема 5. Давньогрецька лірика. Поезія Сафо",
                    type: "test",
                },
                {
                    title: "Завдання до §6", link: "/class/8/int/yac/modules/module06",
                    isDone: true,
                    tooltip: "Тема 6. Культура й віра прадавніх українців",
                    type: "test",
                },
                {
                    title: "Завдання до §7", link: "/class/8/int/yac/modules/module07",
                    isDone: true,
                    tooltip: "Тема 7. Іван Білик «Дарунки скіфів»",
                    type: "test",
                },
                {
                    title: "Завдання до §8", link: "/class/8/int/yac/modules/module08",
                    isDone: true,
                    tooltip: "Тема 8. Мотиви античності в українській літературі. Валентин Чемерис «Ольвія»",
                    type: "test",
                },
                {
                    title: "Завдання до §9", link: "/class/8/int/yac/modules/module09",
                    isDone: true,
                    tooltip: "Тема 9. Біблія й українська література. Ліна Костенко “Давидові псалми. Псалом 1”",
                    type: "test",
                },
                {
                    title: "Завдання до §10", link: "/class/8/int/yac/modules/module10",
                    isDone: false,
                    tooltip: "Тема 10. Біблійні жанри та мотиви в літературі. Антуан де Сент-Екзюпері «Маленький принц»",
                    type: "test",
                },
                {
                    title: "Завдання до §11", link: "/class/8/int/yac/modules/module11",
                    isDone: true,
                    tooltip: "Тема 11. Біблія й українська література. Ліна Костенко “Давидові псалми. Псалом 1”",
                    type: "test",
                },
                {
                    title: "Завдання до §12", link: "/class/8/int/yac/modules/module12",
                    isDone: true,
                    tooltip: "Тема 12. Дзвінка Матіяш «Мене звати Варвара»",
                    type: "test",
                },
                {
                    title: "Завдання до §13", link: "/class/8/int/yac/modules/module13",
                    isDone: true,
                    tooltip: "Тема 13. ЛІТЕРАТУРА СЕРЕДНЬОВІЧЧЯ. Доба Середньовіччя у світовій літературі",
                    type: "test",
                },
                {
                    title: "Завдання до §14", link: "/class/8/int/yac/modules/module14",
                    isDone: true,
                    tooltip: "Тема 14. Русь, її культура та письменство. Усний народний епос. Старини (билини). Старина «Про Іллю Муромця та Соловія»",
                    type: "test",
                },
                {
                    title: "Завдання до §15", link: "/class/8/int/yac/modules/module15",
                    isDone: true,
                    tooltip: "Тема 15. Своєрідність давнього українського письменства. Володимир Мономах «Повчання дітям»",
                    type: "test",
                },
                {
                    title: "Завдання до §16", link: "/class/8/int/yac/modules/module16",
                    isDone: true,
                    tooltip: "Тема 16. Образ Русі в сучасній українській літературі. Раїса Іванченко «Ярославни»",
                    type: "test",
                },
                {
                    title: "Завдання до §17", link: "/class/8/int/yac/modules/module17",
                    isDone: true,
                    tooltip: "Тема 17. Західноєвропейська середньовічна поезія. Лірика вагантів",
                    type: "test",
                },
                {
                    title: "Завдання до §18", link: "/class/8/int/yac/modules/module18",
                    isDone: true,
                    tooltip: "Тема 18. Італійське середньовіччя. Аліґ’єрі Данте Сонет 11",
                    type: "test",
                },
                {
                    title: "Завдання до §19", link: "/class/8/int/yac/modules/module19",
                    isDone: true,
                    tooltip: "Тема 19. Особливості середньовічної доби на Сході. Омар Хаям. Рубаї",
                    type: "test",
                },
                {
                    title: "Завдання до §20", link: "/class/8/int/yac/modules/module20",
                    isDone: false,
                    tooltip: "Тема 20. Мотиви середньовіччя в сучасній зарубіжній літературі. Юстейн Ґордер «Середньовіччя» (із роману «Світ Софії»)",
                    type: "test",
                },
                {
                    title: "Завдання до §21", link: "/class/8/int/yac/modules/module21",
                    isDone: true,
                    tooltip: "Тема 21. ЛІТЕРАТУРА ВІДРОДЖЕННЯ. Доба Ренесансу (Відродження) в Європі",
                    type: "test",
                },
                {
                    title: "Завдання до §22", link: "/class/8/int/yac/modules/module22",
                    isDone: false,
                    tooltip: "Тема 22. Франческо Петрарка. Сонети",
                    type: "test",
                },
                {
                    title: "Завдання до §13", link: "/class/8/int/yac/modules/module13",
                    isDone: false,
                    tooltip: "Тема 13. ЛІТЕРАТУРА СЕРЕДНЬОВІЧЧЯ. Доба Середньовіччя у світовій літературі",
                    type: "test",
                },
            ],
        },
    ],
};
