import { text_mod_24_1, text_mod_24_2, text_mod_24_3 } from "../text/Text.js";

export const module24 = {
  title: "Тема 24. Реалізм в українській літературі",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_24_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_24_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_24_3,
    },
    {
      id: 4,
      question: "1.	НЕ є ознакою реалізму твердження",
      options: [
        { id: "A", text: "Письменники-реалісти зосередилися на злободенних проблемах сьогодення." },
        { id: "B", text: "Засобом відтворення і дослідження дійсності в реалістів стає тип – збірний образ-персонаж." },
        { id: "C", text: "Реалісти розглядали світ крізь призму символів." },
        { id: "D", text: "Природа для письменників-реалістів – насамперед тло, на якому відбуваються типові події." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "2.	У добу реалізму в літературі переважали прозові епічні жанри, тому що ",
      options: [
        { id: "A", text: "Жанри епосу допомагають авторові зосереджуватися на подіях та поясненні їх причин." },
        { id: "B", text: "Жанри лірики та драми було заборонено." },
        { id: "C", text: "Видавці не хотіли друкувати твори ліричних жанрів." },
        { id: "D", text: "Твори поетичних жанрів не користувалися популярністю в читачів." },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Реалісти рішуче засуджували",
      options: [
        { id: "A", text: "зображення емоцій і почуттів" },
        { id: "B", text: "перебільшення, фантазування, ідеалізацію, містику" },
        { id: "C", text: "зображення явищ природи" },
        { id: "D", text: "акценти на художніх деталях" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	У реалістичних творах головним є розкриття",
      options: [
        { id: "A", text: "відносин людини й суспільства" },
        { id: "B", text: "внутрішнього світу людини" },
        { id: "C", text: "впливу родини на формування характеру персонажа" },
        { id: "D", text: "фольклорних мотивів твору" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Зображення природи в реалістичних творах є",
      options: [
        { id: "A", text: "тлом, на якому відбуваються події" },
        { id: "B", text: "засобом розкриття характерів персонажів" },
        { id: "C", text: "важливою художньою деталлю" },
        { id: "D", text: "виразником думок і почуттів персонажів" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	Мистецький напрям, у якому у ХІХ ст. співіснував реалізм, — це",
      options: [
        { id: "A", text: "бароко" },
        { id: "B", text: "класицизм" },
        { id: "C", text: "сентименталізм" },
        { id: "D", text: "романтизм" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
  ],
};
