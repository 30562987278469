import { text_mod_2, } from "../text/Text.js";

export const module03_04 = {

  title: "Урок 3-4. Національна безпека України. Основні принципи порятунку та захисту людей у надзвичайних ситуаціях.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_2,
    },
    {
      id: 2,
      question:
        "Оберіть необхідні речі для евакуації",
      isGame: true,

      initialWords: [
        {
          id: 1,
          text: "Аптечка, у якій обов'язково мають бути ліки, якими користуються хворі члени родини (серцеві, інсулін, інгалятор), знеболювальні засоби, перев'язувальний матеріал, спирт, йод.",
          type: "leftBucket",
        },
        {
          id: 2,
          text: "Кухоль, ложка, ніж, сірники, ліхтарик, свисток.",
          type: "leftBucket",
        },
        {
          id: 3,
          text: "Скейтборд, наколінники, шолом.",
          type: "rightBucket",
        },
        {
          id: 4,
          text: "Документи (паспорти всіх членів родини, свідоцтва про народження), гроші, цінні речі.",
          type: "leftBucket",
        },

        {
          id: 5,
          text: "Шкільні підручники, пенал.",
          type: "rightBucket",
        },
        {
          id: 6,
          text: "Необхідні речі (білизна, теплий одяг, взуття, предмети особистої гігієни).",
          type: "leftBucket",
        },

        {
          id: 7,
          text: "Спінінг, рибальські сітки.",
          type: "rightBucket",
        },
        {
          id: 8,
          text: "Футбольний м'яч, бутси.",
          type: "rightBucket",
        },
        {
          id: 9,
          text: "Крем для засмаги, парасолька.",
          type: "rightBucket",
        },

        {
          id: 10,
          text: "Дітям дошкільного віку кладуть у кишеню або пришивають на одяг записку, де зазначається прізвище, ім'я та по батькові, домашня адреса, а також дані про батьків.",
          type: "leftBucket",
        },
        {
          id: 11,
          text: "Продукти харчування (тривалого зберігання) на два-три дні та питна вода.",
          type: "leftBucket",
        },
      ],
      leftBucketTitle: "Потрібні речі",
      rightBucketTitle: "Непотрібні речі",
    },


  ],
};
