import { text_mod_60 } from "../text/Text.js";

export const module60 = {
  title: "Урок 60. Нумерація трицифрових чисел. Порівняння чисел в межах 1000. Назви розрядів. Співвідношення між одиницями довжини. ​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_60,
    },
  ],
};
