import {text_mod_21, } from "../text/Text.js";
export const module21 = {
  title:
    "§21. Склад. Перенос слів із рядка в рядок по складах. Перенос слів, у яких склад позначений однією буквою",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_21, },
    ]
  }
