export const module5 = {
  title: "§ 5. Сполучники сурядності та підрядності.",
  questions: [
    {
      id: 1,
      question: "1. Сполучниками є всі слова у варіанті",
      options: [
        { id: "А", text: "немовбито, і, навіть" },
        { id: "Б", text: "дарма що, або, проте" },
        { id: "В", text: "однак, через те що, із-за" },
        { id: "Г", text: "тому що, наче, згідно з" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Сурядними є всі сполучники у варіанті",
      options: [
        { id: "А", text: "чи, аж, якби" },
        {
          id: "Б",
          text: "й, ніби, аби",
        },
        { id: "В", text: "зате, що, мов" },
        { id: "Г", text: "та, але, або" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question:
        '3. Виділені слова є сполучниками в усіх реченнях, <span class="text-wow">ОКРІМ</span>',
      options: [
        {
          id: "А",
          text: 'Раптом дозорці доповіли, <span class="text-wow">&nbsp;що&nbsp;</span>  зі степу їде військовий загін.',
        },
        {
          id: "Б",
          text: 'Павутиння підказувало, <span class="text-wow">&nbsp;що&nbsp;</span>  тут давно не ходили.',
        },
        {
          id: "В",
          text: 'Раптом усі відчули, <span class="text-wow">&nbsp;що&nbsp;</span>  ніч уже пахне груднем.',
        },
        {
          id: "Г",
          text: 'Доброта — це проміння, <span class="text-wow">&nbsp;що&nbsp;</span>  світло дарує.',
        },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question:
        '4. Прийменниками є всі виділені слова, <span class="text-wow">ОКРІМ',
      options: [
        {
          id: "А",
          text: 'Сім’я хутко стала <span class="text-wow">&nbsp;довкола &nbsp;</span> столу.',
        },
        {
          id: "Б",
          text: 'Ми стояли просто <span class="text-wow">&nbsp;посеред &nbsp;</span> дороги.',
        },
        {
          id: "В",
          text: '<span class="text-wow">Навколо &nbsp;</span>  стояла вранішня тиша.',
        },
        {
          id: "Г",
          text: '<span class="text-wow">Назустріч &nbsp;</span>  флотилії виповзли острівки.',
        },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Виділене слово є сполучником у реченні",
      options: [
        {
          id: "А",
          text: '<span class="text-wow">Як/би &nbsp;</span> змогла, то прийшла б на зустріч.',
        },
        {
          id: "Б",
          text: '<span class="text-wow">Як/би &nbsp;</span> не запізнитися на заняття?',
        },
        {
          id: "В",
          text: '<span class="text-wow">Як/би &nbsp;</span> там не було, а ми побачимося.',
        },
        {
          id: "Г",
          text: '<span class="text-wow">Як/би &nbsp;</span> виконати цю складну задачу?',
        },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. 3’ясуйте, якою частиною мови є виділені слова в реченні <br /> (цифра позначає попереднє слово).",
      dropdownQuestionParts: [
        "<i> 1) Найбільше люду зібралося </i> <br> ",
        { id: "dropdown1", text: " довкола " },
        "<i> хіп-хоперів. </i> <br /> 2) <i>Хрещатик</i> ",
        { id: "dropdown2", text: "тільки " },
        "<br /><i>починав прокидатися.</i> <br /> ",
        { id: "dropdown3", text: "Тільки-но " },
        "<i> дозрівають ягоди, <br />шпаки налітають на дерева.<br /> Земля </i>",
        { id: "dropdown4", text: "довкола " },
        "<i> перетворилася <br />на квітучий острівець.</i>",
      ],
      options: [
        { id: "А", text: "частка" },
        { id: "Б", text: "прислівник" },
        { id: "В", text: "сполучник" },
        { id: "Г", text: "займенник" },
        { id: "Д", text: "прийменник" },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "А",
        dropdown3: "В",
        dropdown4: "Б",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        "7. Розподіліть сполучники за групами: <br />1) сполучники сурядності; <br />2) сполучники підрядності.",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Адже", type: "rightBucket" },
        { id: 2, text: "що аж", type: "rightBucket" },
        { id: 3, text: "однак", type: "leftBucket" },
        { id: 4, text: "не тільки ... а й", type: "leftBucket" },
        { id: 5, text: "якби", type: "rightBucket" },
        { id: 6, text: "так що", type: "rightBucket" },
        { id: 7, text: "але", type: "leftBucket" },
        { id: 8, text: "дарма що", type: "rightBucket" },
        { id: 9, text: "й", type: "leftBucket" },
        { id: 10, text: "начебто", type: "rightBucket" },
        { id: 11, text: "проте", type: "leftBucket" },
        { id: 12, text: "аби", type: "rightBucket" },
        { id: 13, text: "або", type: "leftBucket" },
        { id: 14, text: "чи", type: "leftBucket" },
        { id: 15, text: "щоб", type: "rightBucket" },
        { id: 16, text: "коли", type: "rightBucket" },
        { id: 17, text: "а", type: "leftBucket" },
        { id: 18, text: "якщо", type: "rightBucket" },
        { id: 19, text: "зате", type: "leftBucket" },
        { id: 20, text: "ані ... ані", type: "leftBucket" },
        { id: 21, text: "через те що", type: "rightBucket" },
        { id: 22, text: "нехай", type: "rightBucket" },
        { id: 23, text: "хоч ... хоч", type: "leftBucket" },
        { id: 24, text: "і", type: "leftBucket" },
      ],
      leftBucketTitle: "Сполучники сурядності",
      rightBucketTitle: "Сполучники підрядності",
    },
    {
      id: 9,
      question:
        "9. <b>Експрес-урок - Питання чи ЗАпитання?</b>. Перегляньте експрес-урок за покликанням і виконайте завдання.</br> <b>Завдання:<b/> </br> <i> 1. Складіть зі згаданими у відео словами або словосполученнями речення (письмово).</br> 2. Запишіть зазначені у відео приклади й прочитайте їх вдома родичам.</i>",
      videoPath:
        "https://www.youtube.com/embed/UjVvbhNjsTs?si=qQ1mVMi_bme4fTig",
      addClass: "horizontal",
      type: "video",
      isTest: true,
      isCreativeTask: true,
    },
  ],
};
