import {text_mod_12, } from "../text/Text.js";
export const module12 = {
  title:
    "§12. Приголосні звуки [дж ], [дз ], [дз′]. Позначення їх буквами​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_12, },
    ]
  }
