import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mockLogin } from '../../api/mockAuth';

const MockLoginAs = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await mockLogin(username, password);

            localStorage.setItem('token', response.token);
            localStorage.setItem('roles', JSON.stringify(response.roles));
            localStorage.setItem('username', response.username);

            props.onLogin(response.username);

            navigate('/home');
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div style={{ margin: 20 }}>
            <h2>Login (заглушка)</h2>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Username: </label>
                    <input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="student/teacher/parent"
                    />
                </div>
                <div>
                    <label>Password: </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="123"
                    />
                </div>
                <button type="submit">Войти</button>
            </form>
        </div>
    );
}

export default MockLoginAs;
