const authorsData = {
  1: {
    math: ["Т. Прошкуратова, А. Пархоменко"],
    bukv: ["М. Захарійчук"],
  },
  8: {
    ukrmova: ["О. Авраменко", "Н. Голуб, О. Горошкіна"],
    ukrlit: ["О. Авраменко", "Т. Яценко, В. Пахаренко, О. Слижук"],
    int: ["Т. Яценко, В. Пахаренко, О. Слижук"],
    zbd: ["Н. Поліщук, П. Поліщук"],
  },
  2: {
    math: ["Т. Прошкуратова, А. Пархоменко"],
    ukrmova: ["М. Захарійчук"],
  },
  3: {
    math: ["Т. Прошкуратова, А. Пархоменко"],
    chutannya: ["М. Захарійчук"],
  },
};

export default authorsData;
