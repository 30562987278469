import {text_mod_46, } from "../text/Text.js";
export const module46 = {
  title:
    "§46. Дієслово.Слова – назви дій​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_46, },
    ]
  }
