import { text_mod_7, } from "../text/Text.js";

export const module15 = {

  title: "Урок 15. Здоров’я і вибір способу життя. Поняття про оздоровчі системи.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_7,
    },

  ],
};
