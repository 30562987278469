export const text_mod_1 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR2Zi8BIH-6R7TIv02HiFc7AZotxND72CC1LrW5Lr4GpEU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_2 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR6SGsWEOvrRb-7jJWXfKHxAeZKbz951lPNuh6_rme0-iY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_3 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQTwc58cNW1SLw8o1biqXGPAa580yWtF6PmxWf9bf3wGS0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_4 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT_sELApTn0SpfuMA0PkfSTAXxlPdmmgFLU5sda-dPcV_4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_5 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS2fkKvaXSjQak0HpZmladjAegZWeAcna16AazvjoaOFuM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_6 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTxy0YtaZjgQJcZIqjF9qX5Adqb9m_V2yizknvayCesAlA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_7 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSy0KW-tU0ORJ3LkYaj0RHsAZmYF1XVC3qzdOm3b-KUVv0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_8 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRm_-SW5WciRapAwjH330qKATDK390gMBPjvIh7O0_X9Uw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_9 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQPhZmVQ9QmRICeWSsn2GFkAWbMiirG8dH2kFcE9w14JkE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_10 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSpQOdxXvu2Sq-MEtyJ1naFATiHRX1zF6Kh7XkIjqcvCqc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_11 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR-8-oTX_qJQIvDcDTRsHB4AXzZOmJASNZr7WtiOckygpA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_12 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTtdMQBfGQnRZX3wz175zPwAZm2v5j20iufKxZ4gVrfDfs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_13 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRjyEAb2Vn5S6q2JSyj0cx0AVIWrru4HH4ba0m7MkiPKsI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_14 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQcOqyndAFbQ67kEzca_PXJAbQTqE0ew9MrakypKlY5nLk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_15 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSLY5o_rUU2RKA-AFivD75JAVC6xHotEhQNhAodIWkluB0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_16 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQCpf16D3S3To7b7LnebSUGAa5tZHDWL2fuzmLD55pintE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_17 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRbBR5XHwtKR7ftnbH3sxuDAZa6xuP7Uhio-yoUpANYZI8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_18 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQROyg1L3FniRq2oCsf4zhV7AcWLGeGkK-pfSPzmmA7Rp6Q"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_19 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ75YBqqexGSLRcaX-m8Hn-AWqQjEb4sRWuZOpZcLKQllM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_20 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRHKeBRTl-CSYLCX8EU_QzpAWvJwoHDjmCvfQitM5SElhI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_21 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT7mNzD6UBFRZ_HWOBHwPjJAUpK3x1Xoiof2aLsl6aws4k"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_22 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT5stZUrEALSoIuw_btq16LAccyogbvwH_IeWJLFUjt05Q"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;

export const text_mod_23 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRh0Mqi71j5RqRNnvNZqVzeAXamGmm5B3VGVIEmHVtnh34"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;


export const text_mod_24 = `Виконай завдання та запиши відповідь у зошит. Для перегляду всіх завдань клікни на екран презентації.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQkZtz6UdHST4HsV1JB3cTIAV5YSc08S2kN9Bfgs-yVaCE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>`;
