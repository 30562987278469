import {text_mod_21, } from "../text/Text.js";
export const module21 = {
  title:
    "Уроки 108-113. Буквені вирази. Складання добутків та обчислення їхніх значень. Складання за схемою добутків із першим множником 6. ​Розв’язування задач​​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_21, },
    ]
  }
