import {text_mod_22, } from "../text/Text.js";
export const module22 = {
  title:
    "Уроки 114-118. Задачі на знаходження суми двох добутків. Складання за схемою добутків із першим множником 7. Способи обчислення периметра прямокутника, квадрата. Дії з іменованими числами. Знаходження невідомого множника.​ Розв’язання задач​​​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_22, },
    ]
  }
