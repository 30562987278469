import {text_mod_48, } from "../text/Text.js";
export const module48 = {
  title:
    "§48. Дієслова, протилежні за значенням. Дієслова, близькі за значенням",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_48, },
    ]
  }
