import React, { Component } from 'react';
import Header from '../header_h/Header';
import Footer from '../footer/Footer';
import UserProfileContent from './UserProfileContent';

class UserProfile extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { userName, userRoles } = this.props;
        return (
            <>
                <Header userName={userName} userRoles={userRoles} />
                <UserProfileContent userName={userName} userRoles={userRoles} />
                <Footer />
            </>
        );
    }
}

export default UserProfile;
