import {text_mod_5, } from "../text/Text.js";
export const module05 = {
  title:
    "Уроки 17-21. Доба. Тиждень. Місяць. Рік. Календар.​ Додавання і віднімання одноцифрових чисел із переходом через 10. Календар осінніх місяців.​ Визначення часу за годинником​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_5, },
    ]
  }
