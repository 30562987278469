import {text_mod_25, } from "../text/Text.js";
export const module25 = {
  title:
    "Урок 4. Літера Ґ велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_25, },
    ]
  };