import { text_mod_1, } from "./text/Text";
export const about = {
    title:
        "Про додаток",
    questions: [
        {
            id: 1,
            onlyText: true,
            text: text_mod_1,
        },
    ]
}
