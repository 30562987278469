import { text_mod_32 } from "../text/Text.js";

export const module32 = {
  title: "Урок 32. ​Вирази рівності й нерівності. Рівняння.​ Таблиця ділення і множення на 6. Розв'язання рівнянь. ​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_32,
    },
  ],
};