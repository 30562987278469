export const text_mod_1 = `
    <h4>8 клас</h4>
    <ol>
        <li><b>Українська література</b><br/>
        Підручник для 8 класу закладів загальної середньої освіти<br/>
        <i>Автори: Таміла Яценко, Василь Пахаренко, Олеся Слижук</i>
        </li></br>

        <li><b>Література (українська та зарубіжна)</b><br/>
        Підручник інтегрованого курсу для 8 класу закладів загальної середньої освіти (у 2-х частинах)<br/>
        <i>Частина 1. Автори: Таміла Яценко, Василь Пахаренко, Ірина Тригуб</i>
        </li></br>

        <li><b>Література (українська та зарубіжна)</b><br/>
        Підручник інтегрованого курсу для 8 класу закладів загальної середньої освіти (у 2-х частинах)<br/>
        <i>Частина 2. Автори: Таміла Яценко, Василь Пахаренко, Ірина Тригуб</i>
        </li></br>

        <li><b>Здоров’я, безпека та добробут</b><br/>
        Підручник інтегрованого курсу для 8 класу закладів загальної середньої освіти<br/>
        <i>Автори: Наталія Поліщук, Павло Поліщук</i>
        </li></br>

        <li><b>Українська мова</b><br/>
        Підручник для 8 класу закладів загальної середньої освіти<br/>
        <i>Автори: Н.Б. Голуб, О.М. Горошкіна</i>
        </li></br>

        <li><b>Українська література</b><br/>
        Підручник для 8 класу закладів загальної середньої освіти<br/>
        <i>Автор: О.М. Авраменко</i>
        </li></br>

        <li><b>Українська мова</b><br/>
        Підручник для 8 класу закладів загальної середньої освіти<br/>
        <i>Автор: О.М. Авраменко</i>
        </li></br>
    </ol>

    <div style="text-align: center; margin-top: 20px; font-size: 0.9em; color: #555;">
        © 2025 Усі права захищено. Автори: Таміла Яценко, Василь Пахаренко, Олеся Слижук, Ірина Тригуб, Наталія Поліщук, Павло Поліщук, Н.Б. Голуб, О.М. Горошкіна, О.М. Авраменко.
    </div>
`
