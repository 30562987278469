import {text_mod_13, } from "../text/Text.js";
export const module13 = {
  title:
    "Уроки 55-60. Міліметр. Вимірювання довжин відрізків.​ Віднімання чисел виду 34 – 6. ​Додавання чисел виду 38 + 25, 43 + 27.​ Додавання двоцифрових чисел​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_13, },
    ]
  }
