
import { text_mod_22_1, text_mod_22_2, text_mod_22_3 } from "../text/Text.js";

export const module22 = {
  title: "Тема 22. Сучасні українські балади",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_22_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_22_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_22_3,
    },
    {
      id: 4,
      question: "1.	Визначте, який факт із біографії В. Івасюка є помилковим.",
      options: [
        { id: "A", text: "В. Івасюк – автор відомої на весь світ пісні «Червона рута»." },
        { id: "B", text: "Автором слів і музики до твору «Балада про мальви» є В. Івасюк." },
        { id: "C", text: "Композитор В. Івасюк — один з основоположників української естрадної музики." },
        { id: "D", text: "В. Івасюк прожив усього 30 років." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "2.	Провідний мотив «Балади про мальви» передано в рядках",
      options: [
        { id: "A", text: "«Життя – як пісня, що не віддзвенить. / Я в мальві знов для тебе буду жить»." },
        { id: "B", text: "«О, мамо рідна, ти мене не жди, /Мені в наш дім ніколи не прийти»." },
        { id: "C", text: "«Не плач, не плач, бо ти вже не одна/ Багато мальв насіяла війна»." },
        { id: "D", text: "«У матерів є любі діти, / А у моєї — тільки квіти»." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Трагедію війни в рядках «Багато мальв насіяла війна» автор передає за допомогою",
      options: [
        { id: "A", text: "гіперболи" },
        { id: "B", text: "фразеологізма" },
        { id: "C", text: "персоніфікації" },
        { id: "D", text: "епітета" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	Автор «Кленової балади»",
      options: [
        { id: "A", text: "Володимир Івасюк" },
        { id: "B", text: "Анатолій Матвійчук" },
        { id: "C", text: "Богдан Гура" },
        { id: "D", text: "Іван Драч" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	У рядках «Загриміли в полі, мов громи, гармати» автор використовує ",
      options: [
        { id: "A", text: "порівняння та фразеологізм" },
        { id: "B", text: "порівняння та метафору" },
        { id: "C", text: "порівняння та епітет" },
        { id: "D", text: "порівняння та алітерацію" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	Визначте, яка з ознак НЕ характерна для «Кленової балади».",
      options: [
        { id: "A", text: "Висока емоційність" },
        { id: "B", text: "Невелика кількість подій і персонажів" },
        { id: "C", text: "Фантастичність, загадковість, недомовленість" },
        { id: "D", text: "Народнопісенна основа, символічність образів" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
  ],
};
