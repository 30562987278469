import { text_mod_25_1, text_mod_25_2, text_mod_25_3 } from "../text/Text.js";

export const module25 = {
  title: "Тема 25. Поезія реалізму. Павло Грабовський «Я не співець чудовної природи…»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_25_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_25_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_25_3,
    },
    {
      id: 4,
      question: "1.	Більшість українських поетів-реалістів були ",
      options: [
        { id: "A", text: "кирило-мефодіївцями" },
        { id: "B", text: "декабристами" },
        { id: "C", text: "революціонерами" },
        { id: "D", text: "перекладачами" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "2.	Українські поети-реалісти розглядали художнє слово як",
      options: [
        { id: "A", text: "зброю" },
        { id: "B", text: "символ" },
        { id: "C", text: "візуальний образ" },
        { id: "D", text: "емоційний заклик" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Павло Грабовський був також відомим ",
      options: [
        { id: "A", text: "композитором" },
        { id: "B", text: "художником" },
        { id: "C", text: "перекладачем" },
        { id: "D", text: "скульптором" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	Тема поезії «Я не співець чудовної природи…» — це",
      options: [
        { id: "A", text: "захоплення красою природи" },
        { id: "B", text: "роль слова і митця в суспільстві" },
        { id: "C", text: "переживання за долю рідного народу" },
        { id: "D", text: "оспівування краси й сили рідного слова" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 8,
      question:
        "5.	У рядках «Серед ясних, золо́чених просторів / Я бачу люд без житнього шматка…/ Блакить… пташки… з-під соловйових хорів, / Мов ніж, вража скрізь стогін мужика» НЕ використано",
      options: [
        { id: "A", text: "метафору" },
        { id: "B", text: "епітети" },
        { id: "C", text: "порівняння" },
        { id: "D", text: "літоту" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	Поезія «Я не співець чудовної природи…» належить до лірики",
      options: [
        { id: "A", text: "пейзажної" },
        { id: "B", text: "громадянської" },
        { id: "C", text: "інтимної" },
        { id: "D", text: "філософської" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
