import React, { Component } from "react";
import FeedbackBanner from "../general/correct-feedback/FeedbackBanner";
import AnswerOption from "../general/answer-option/AnswerOption";
import DropdownTask from "../general/dropdown-task/DropdownTask";
import WordGameDnD from "../general/word-game-dnd/WordGameDnD";

import { Modal, Button } from "react-bootstrap";

import "./Module.css";
import "./responsive.css";

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.module.title,
      questions: props.module.questions,
      selectedQuestionIndex: 0,
      selectedOption: null,
      attempts: 2,
      score: 0,
      feedback: "",
      feedbackVisible: false,
      showCheckButton: true,
      showNextButton: false,
      optionStatuses: {},
      allDropdownsSelected: false,
      dropdownValues: {
        dropdown1: "",
        dropdown2: "",
        dropdown3: "",
        dropdown4: "",
        dropdown5: "",
        dropdown6: "",
      },
      isGameOver: false,
      showModal: false,
    };

    this.dropdownTaskRef = React.createRef();
  }

  handleOptionClick = (answer) => {
    this.setState({
      selectedOption: answer,
    });
  };

  closeFeedback = () => {
    this.setState({ feedbackVisible: false });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  checkAnswer = () => {
    const {
      selectedOption,
      questions,
      selectedQuestionIndex,
      dropdownValues,
      allDropdownsSelected,
    } = this.state;
    const { correctAnswer, correctAnswers } = questions[selectedQuestionIndex];

    if (selectedOption) {
      const isCorrect = selectedOption === correctAnswer;
      this.setState(
        (prevState) => ({
          attempts: !isCorrect ? prevState.attempts - 1 : prevState.attempts,
          feedback: isCorrect ? "correct" : "incorrect",
          score: isCorrect ? prevState.score + 1 : prevState.score - 0,
          feedbackVisible: true,
          showCheckButton: !isCorrect && prevState.attempts > 1,
          showNextButton: isCorrect || prevState.attempts <= 1,
          optionStatuses: {
            ...prevState.optionStatuses,
            [selectedOption]: isCorrect ? "correct" : "incorrect",
          },
        }),
        () => {
          // localStorage.setItem("score", this.state.score);
        }
      );
      //Для тестів з дропдаунами
    } else if (allDropdownsSelected) {
      const isAllCorrect = Object.keys(correctAnswers).every((key) => {
        return dropdownValues[key] === correctAnswers[key];
      });

      this.setState(
        (prevState) => ({
          attempts: !isAllCorrect ? prevState.attempts - 1 : prevState.attempts,
          feedback: isAllCorrect ? "correct" : "incorrect",
          score: isAllCorrect ? prevState.score + 1 : prevState.score - 0,
          feedbackVisible: true,
          showCheckButton: !isAllCorrect && prevState.attempts > 1,
          showNextButton: isAllCorrect || prevState.attempts <= 1,
          optionStatuses: {
            ...prevState.optionStatuses,
            [selectedOption]: isAllCorrect ? "correct" : "incorrect",
          },
        }),
        () => {
          // localStorage.setItem("score", this.state.score);
        }
      );

      console.dir(dropdownValues);
    }
  };

  nextQuestion = () => {
    const { selectedQuestionIndex, questions } = this.state;
    if (selectedQuestionIndex < questions.length - 1) {
      this.setState(
        (prevState) => ({
          attempts: 2,
          selectedQuestionIndex: prevState.selectedQuestionIndex + 1,
          selectedOption: null,
          feedback: "",
          feedbackVisible: false,
          showCheckButton: true,
          showNextButton: false,
          optionStatuses: {},
          allDropdownsSelected: false,
          isGameOver: false,
          dropdownValues: {
            dropdown1: "",
            dropdown2: "",
            dropdown3: "",
            dropdown4: "",
            dropdown5: "",
          },
        }),
        () => {
          if (this.dropdownTaskRef.current) {
            this.dropdownTaskRef.current.resetDropdownValues();
          }
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      );
    } else {
    }
  };

  finishQuiz = () => {
    // Логіка завершення тесту, наприклад, редірект на іншу сторінку
    // alert("Ви закінчили модуль. Ваш рахунок: " + this.state.score);
    this.setState({ showModal: true });
  };

  handleAllDropdownsSelected = (dropdownValues) => {
    this.setState({ allDropdownsSelected: true, dropdownValues });
  };

  handleScoreUpdate = (newScore) => {
    this.setState({ score: newScore });
  };

  handleGameOver = (isGameOver) => {
    this.setState({ isGameOver });
  };

  render() {
    const {
      title,
      questions,
      selectedQuestionIndex,
      selectedOption,
      feedbackVisible,
      feedback,
      score,
      showCheckButton,
      showNextButton,
      optionStatuses,
      allDropdownsSelected,
      isGameOver,
      showModal,
    } = this.state;
    const {
      id,
      question,
      options,
      type,
      isTest,
      isGame,
      subtitle,
      imgPath,
      videoPath,
      shouldGoNext,
      isCreativeTask,
      addClass,
      onlyText,
      text,
      initialWords,
      leftBucketTitle,
      centerBucketTitle,
      rightBucketTitle,
      bucketsAmount,
      showAnswerOptions,
      dropdownAmount,
    } = questions[selectedQuestionIndex];

    const isLastQuestion = selectedQuestionIndex === questions.length - 1;
    const outOfAttempts = this.state.attempts <= 0;

    const containerClassName = `test-container${isGame ? " game" : ""}`;

    return (
      <>
        <div className={containerClassName}>
          {/* <div className="score-counter" id="scoreCounter">
          {score}
          <br />
          балів
        </div> */}

          {showModal && (
            <Modal show={showModal} onHide={this.handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Завершення тесту</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Ви закінчили модуль. Ваш рахунок: {score} ⭐
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseModal}>
                  Закрити
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          <div
            className="test-title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>

          {subtitle && <div className="testsubtitle"> {subtitle}</div>}

          {onlyText && (
            <>
              <div
                className="module-text-area"
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>

              {(showCheckButton && !isLastQuestion) && (
                <button
                  className="btn nextAnswer"
                  onClick={this.nextQuestion}
                  style={{ display: "inline-block" }}
                >
                  Наступне завдання
                </button>
              )}

            </>
          )}

          {isTest && (
            <>
              <div
                className="test-question"
                dangerouslySetInnerHTML={{ __html: question }}
              ></div>

              {!imgPath && !videoPath && !onlyText ? (
                <ul className="answer-options">
                  {options.map((option) => (
                    <AnswerOption
                      key={option.id}
                      id={option.id}
                      text={option.text}
                      selectedOption={selectedOption}
                      status={optionStatuses[option.id]}
                      onClick={this.handleOptionClick}
                    />
                  ))}
                </ul>
              ) : (
                <>
                  <div className="test-content">
                    <div className={`c1 ${addClass}`}>
                      {imgPath && <img src={imgPath} alt="moduleImg" />}

                      {videoPath && (
                        <iframe
                          width="100%"
                          height="100%"
                          src={videoPath}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      )}
                    </div>
                    <div className="c2">
                      {options && (
                        <ul className="answer-options">
                          {options.map((option) => (
                            <AnswerOption
                              key={option.id}
                              id={option.id}
                              text={option.text}
                              selectedOption={selectedOption}
                              status={optionStatuses[option.id]}
                              onClick={this.handleOptionClick}
                            />
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </>
              )}
              {showCheckButton && (
                <button
                  className="btn checkAnswer"
                  onClick={this.checkAnswer}
                  style={{ display: selectedOption ? "inline-block" : "none" }}
                >
                  Перевірити
                </button>
              )}

              {feedbackVisible && (
                <FeedbackBanner
                  feedback={feedback}
                  closeFeedback={this.closeFeedback}
                />
              )}

              {/* {showNextButton && (
              <button
                className="btn nextAnswer"
                onClick={
                  isLastQuestion || outOfAttempts
                    ? this.finishQuiz
                    : this.nextQuestion
                }
                style={{ display: "inline-block" }}
              >
                {isLastQuestion || outOfAttempts
                  ? "Завершити"
                  : "Наступне завдання"}
              </button>
            )} */}

              {(outOfAttempts || showNextButton) && !isLastQuestion && (
                <button
                  className="btn nextAnswer"
                  onClick={this.nextQuestion}
                  style={{ display: "inline-block" }}
                >
                  Наступне завдання
                </button>
              )}

              {isLastQuestion && showNextButton && (
                <button
                  className="btn nextAnswer"
                  onClick={this.finishQuiz}
                  style={{ display: "inline-block" }}
                >
                  Завершити
                </button>
              )}

              {isLastQuestion && isCreativeTask && (
                <button
                  className="btn nextAnswer"
                  onClick={this.finishQuiz}
                  style={{ display: "inline-block" }}
                >
                  Завершити
                </button>
              )}


              {videoPath && shouldGoNext && (
                <button
                  className="btn nextAnswer"
                  onClick={this.nextQuestion}
                  style={{ display: "inline-block" }}
                >
                  Далі
                </button>
              )}
            </>
          )}
          {!isTest && !isGame && !onlyText && (
            <>
              <DropdownTask
                ref={this.dropdownTaskRef}
                dropdownQuestionParts={
                  questions[selectedQuestionIndex].dropdownQuestionParts
                }
                onAllDropdownsSelected={this.handleAllDropdownsSelected}
                question={question}
                options={options}
                showAnswerOptions={showAnswerOptions}
                dropdownAmount={dropdownAmount}
              />

              {showCheckButton && (
                <button
                  className="btn checkAnswer"
                  onClick={this.checkAnswer}
                  style={{
                    display: allDropdownsSelected ? "inline-block" : "none",
                  }}
                >
                  Перевірити
                </button>
              )}

              {feedbackVisible && (
                <FeedbackBanner
                  feedback={feedback}
                  closeFeedback={this.closeFeedback}
                />
              )}

              {/* {showNextButton && (
              <button
                className="btn nextAnswer"
                onClick={
                  isLastQuestion || outOfAttempts
                    ? this.finishQuiz
                    : this.nextQuestion
                }
                style={{ display: "inline-block" }}
              >
                {isLastQuestion || outOfAttempts
                  ? "Завершити"
                  : "Наступне завдання"}
              </button>
            )} */}

              {(outOfAttempts || showNextButton) && (
                <button
                  className="btn nextAnswer"
                  onClick={this.nextQuestion}
                  style={{ display: "inline-block" }}
                >
                  Наступне завдання
                </button>
              )}

              {isLastQuestion && (
                <button
                  className="btn nextAnswer"
                  onClick={this.finishQuiz}
                  style={{ display: "inline-block" }}
                >
                  Завершити
                </button>
              )}
            </>
          )}
          {isGame && (
            <>
              <WordGameDnD
                key={id}
                onScoreUpdate={this.handleScoreUpdate}
                prevScore={score}
                onGameOver={this.handleGameOver}
                question={question}
                imgPath={imgPath}
                videoPath={videoPath}
                initialWords={initialWords}
                leftBucketTitle={leftBucketTitle}
                centerBucketTitle={centerBucketTitle}
                rightBucketTitle={rightBucketTitle}
                bucketsAmount={bucketsAmount}
              />
              {isGameOver && !isLastQuestion && (
                <button
                  className="btn nextAnswer"
                  onClick={this.nextQuestion}
                  style={{ display: "inline-block" }}
                >
                  Наступне завдання
                </button>
              )}

              {isGameOver && isLastQuestion && (
                <button
                  className="btn nextAnswer"
                  onClick={this.finishQuiz}
                  style={{ display: "inline-block" }}
                >
                  Завершити
                </button>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default Module;
