import {text_mod_70, } from "../text/Text.js";
export const module70 = {
  title:
    "Урок 15. Апостроф",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_70, },
    ]
  };