import { text_mod_26 } from "../text/Text.js";
export const module26 = {
  title: "Урок 26. Заміна додавання множенням. Табличне множення і ділення числа 4.​ Застосування таблиці множення. Четвертина, або чверть. Час. Квартал.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_26,
    },
  ],
};
