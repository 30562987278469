export const module04 = {
  title: "Узагальнено-особові речення. Пізнавай себе",
  questions: [
    {
      id: 1,
      question: "1. Узагальнено-особовим є односкладне речення",
      options: [
        { id: "А", text: "В Україні багато цікавих традицій." },
        { id: "Б", text: "Пізнаєш себе — пізнаєш світ." },
        { id: "В", text: "Ходять уздовж берега." },
        { id: "Г", text: "Часто не розумію своїх справжніх бажань." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Узагальнено-особовим є односкладне речення",
      options: [
        { id: "А", text: "Намагаюся жити чесно." },
        { id: "Б", text: "Завжди знаходять виправдання своїм помилкам." },
        { id: "В", text: "Аналізую свої вчинки щодня." },
        { id: "Г", text: "Учителя і дерево впізнають із плодів." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Узагальнено-особовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "На вокзалі оголосили про прибуття потяга." },
        { id: "Б", text: "Знайшов — не скач, загубив — не плач." },
        { id: "В", text: "З пісні слова не викинеш." },
        { id: "Г", text: "Чужого не гудь, свого не хвали." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Узагальнено-особовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Відстанеш годиною — не здоженеш родиною." },
        { id: "Б", text: "Роби надворі, буде й у коморі." },
        { id: "В", text: "Здобудеш освіту — побачиш більше світу." },
        { id: "Г", text: "Вінки в’ють із конвалії, волошок, чебрецю." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Найкраще описує шлях до самовдосконалення зміст узагальнено-особового речення",
      options: [
        { id: "А", text: "Не зупиняйся на досягнутому, рухайся вперед." },
        { id: "Б", text: "Вони завжди прагнуть до нових знань." },
        { id: "В", text: "Ми постійно вчимося чогось нового." },
        { id: "Г", text: "Кожен день приносить нові відкриття." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Узагальнено-особові речення виражають",
      options: [
        { id: "А", text: "загальні судження, висновки, поради" },
        { id: "Б", text: "дію конкретної особи (мовця, співрозмовника) чи спонукання до дії" },
        { id: "В", text: "дію, що виконує або виконувала невизначена особа" },
        { id: "Г", text: "дію без участі особи як природне чи стихійне явище, психічний або фізичний стан людини, незалежний від її волі" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
  ],
};
  