import {text_mod_23, } from "../text/Text.js";
export const module23 = {
  title:
    "Урок 23, 24. Табличне множення числа 2. Парні й непарні числа.​ Ціле. Половина, або одна друга. Задачі на знаходження частини від числа.​.​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_23, },
    ]
  }
