import {text_mod_41, } from "../text/Text.js";
export const module41 = {
  title:
    "§41. Слова, які називають ознаки предметів​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_41, },
    ]
  }
