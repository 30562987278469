import {
    module01,
    module02,
    module03,
    module04,
    module05,
    module06,
    module07,
    module08,
    module09,
    module10,
    module11,
    module12,
    module13,
    module14,
    module15,
    module16
} from "./modules.js";

export const routes = [
    { path: "/class/1/math/prosh/modules/module01", module: module01 },
    { path: "/class/1/math/prosh/modules/module02", module: module02 },
    { path: "/class/1/math/prosh/modules/module03", module: module03 },
    { path: "/class/1/math/prosh/modules/module04", module: module04 },
    { path: "/class/1/math/prosh/modules/module05", module: module05 },
    { path: "/class/1/math/prosh/modules/module06", module: module06 },
    { path: "/class/1/math/prosh/modules/module07", module: module07 },
    { path: "/class/1/math/prosh/modules/module08", module: module08 },
    { path: "/class/1/math/prosh/modules/module09", module: module09 },
    { path: "/class/1/math/prosh/modules/module10", module: module10 },
    { path: "/class/1/math/prosh/modules/module11", module: module11 },
    { path: "/class/1/math/prosh/modules/module12", module: module12 },
    { path: "/class/1/math/prosh/modules/module13", module: module13 },
    { path: "/class/1/math/prosh/modules/module14", module: module14 },
    { path: "/class/1/math/prosh/modules/module15", module: module15 },
    { path: "/class/1/math/prosh/modules/module16", module: module16 },
];
