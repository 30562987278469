import { text_mod_46 } from "../text/Text.js";

export const module46 = {
  title: "Урок 46. Таблиця множення і ділення числа 9, 10. Задачі з буквеними даними. Буквені вирази. Знаходження частини від числа.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_46,
    },
  ],
};
