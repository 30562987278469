import {text_mod_16, } from "../text/Text.js";
export const module16 = {
  title:
    "§16. Тверді та м’які приголосні звуки. Позначення м’якості приголосних м’яким знаком (ь)",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_16, },
    ]
  }
