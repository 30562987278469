import {text_mod_63, } from "../text/Text.js";
export const module63 = {
  title:
    "Урок 8. Створення речень за малюнками й опорними словами на задану тему",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_63, },
    ]
  };