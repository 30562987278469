import {text_mod_12, } from "../text/Text.js";
export const module12 = {
  title:
    "Уроки 49-52. Додавання і віднімання чисел виду 36 + 4, 30 – 3.​ Розв’язання задач на дві дії. Додавання чисел виду 38 + 4, 46 + 5. Додавання суми до числа",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_12, },
    ]
  }
