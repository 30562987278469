export const module09 = {
  title: "§ 16-17. Народні історичні пісні.",
  questions: [
    {
      id: 1,
      question:
        "1. Характерник і кошовий отаман, що здобув у боях 65 перемог – герой пісні",
      options: [
        { id: "А", text: "«Виряджала мати сина під Крути до бою»" },
        { id: "Б", text: "«Та, ой, як крикнув же козак Сірко»" },
        { id: "В", text: "«Зажурилась Україна»" },
        { id: "Г", text: "«Чи не той то хміль»" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. У пісні «Чи не той то хміль» НЕМАЄ образу",
      options: [
        { id: "А", text: "дубових хат" },
        { id: "Б", text: "сірих вовків" },
        { id: "В", text: "татарської орди" },
        { id: "Г", text: "червоної калини" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question:
        "3. НЕПРАВИЛЬНИМ є твердження щодо пісні «Виряджала мати сина під Крути до бою»",
      options: [
        { id: "А", text: "пісня трагічна за змістом" },
        { id: "Б", text: "твір написано у формі діалогу" },
        { id: "В", text: "у пісні зображено події 1818 року" },
        { id: "Г", text: "за родовою ознакою твір ліро-епічний" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question:
        "4. Ключові слова з пісні «Чи не той то хміль» записано в рядку",
      options: [
        { id: "А", text: "Золотий Брід, ляхи, Хмельницький" },
        { id: "Б", text: "хан, Січ, Запорозьке військо" },
        { id: "В", text: "Чорне море, Богуслав, невольники" },
        { id: "Г", text: "Крути, стрільці, воріженьки" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question:
        "5. Прочитайте рядки.</br></br> <b><i>Та ми думали, ой, та ми ж думали,</br>Що то місяць в степу, ой, зіходжає, –</br>Аж то козак Сірко, та козак же Сірко</br>На битому шляху на татар насуває.</i></b></br></br>В уривку НЕМАЄ:",
      options: [
        { id: "А", text: "художнього паралелізму" },
        { id: "Б", text: "постійного епітета" },
        { id: "В", text: "анафори" },
        { id: "Г", text: "повтору" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Розподіліть епітети на дві колонки.</br><b><i style='text-align: center; display: block;'>Епітети: </i></b>",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "пишний сад", type: "leftBucket" },
        { id: 2, text: "славне військо", type: "rightBucket" },
        { id: 3, text: "сизий орел", type: "rightBucket" },
        { id: 4, text: "золота душа", type: "leftBucket" },
        { id: 5, text: "гостре слово", type: "leftBucket" },
        { id: 6, text: "вороний кінь", type: "rightBucket" },
        { id: 7, text: "битий шлях", type: "rightBucket" },
        { id: 8, text: "вишукана пані", type: "leftBucket" },
        { id: 9, text: "старий ліс", type: "leftBucket" },
        { id: 10, text: "сірий вовк", type: "rightBucket" },
      ],
      leftBucketTitle: "Епітети",
      rightBucketTitle: "Постійні епітети",
    },
    {
      id: 7,
      question:
        "7. Перегляньте трейлер до фільму «Крути 1918» (1 хв 57 с) й виконайте завдання. </br><i>1. На основі зображених у трейлері подій коротко напишіть сценарій уявного фільму. </br>2. Перегляньте фільм «Крути. 1918» (1 год 50 хв) на каналі YouTube і з’ясуйте, наскільки Ваш сценарій відповідає побаченому. </br>3. Поділіться своїми враженнями від фільму в класі.</i>",
      videoPath:
        "https://www.youtube.com/embed/TIlGOgCca3M?si=7OkSjGd--_5At79O",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
