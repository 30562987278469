import {text_mod_1, } from "../text/Text.js";
export const module01 = {
  title:
    "Уроки 1-4. Лічба в межах 10. Складання та обчислення виразів. Додавання і віднімання круглих чисел",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_1, },
    ]
  }
