import {text_mod_60, } from "../text/Text.js";
export const module60 = {
  title:
    "Урок 5. Буква ґ. Вимова звука [ґ]",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_60, },
    ]
  };