export const module03 = {
  title: "Неозначено-особові речення. Мандрувати — корисно й захопливо!",
  questions: [
    {
      id: 1,
      question: "1. Неозначено-особовим є односкладне речення",
      options: [
        { id: "А", text: "Подорожували Європою минулого літа." },
        { id: "Б", text: "Комфортний номер." },
        { id: "В", text: "Відвідав знамениту пам’ятку." },
        { id: "Г", text: "Люблю подорожувати." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Неозначено-особовим є односкладне речення",
      options: [
        { id: "А", text: "В аеропорту перевіряють паспорти." },
        { id: "Б", text: "Перевіряємо паспорти в аеропорту." },
        { id: "В", text: "Паспортний контроль." },
        { id: "Г", text: "Паспорти потрібно перевірити в аеропорту." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Неозначено-особовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "На вокзалі оголосили про прибуття потяга." },
        { id: "Б", text: "У ресторані подають національні страви." },
        { id: "В", text: "Нам порадили відвідати цей чудовий парк." },
        { id: "Г", text: "Захоплено розглядаю архітектуру міста." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Неозначено-особове речення можна утворити, якщо до частини У цьому готелі ... додати",
      options: [
        { id: "А", text: "відпочивав минулого року." },
        { id: "Б", text: "пропонують різноманітні екскурсії." },
        { id: "В", text: "заброньовано всі номери." },
        { id: "Г", text: "затишно й недорого." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Правильно підкреслено граматичну основу в реченні",
      options: [
        { id: "А", text: "Люблю подорожувати." },
        { id: "Б", text: "Подорож була цікавою." },
        { id: "В", text: "Пригадую свою першу подорож." },
        { id: "Г", text: "У Львові люблять каву." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Потребує редагування речення",
      options: [
        { id: "А", text: "Під час екскурсії розповідають про місцеві традиції." },
        { id: "Б", text: "У цьому ресторані готують смачні страви." },
        { id: "В", text: "Тут пропонується широкий вибір сувенірів." },
        { id: "Г", text: "На пляжі багато відпочивальників." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
  ],
};
