import { text_mod_68 } from "../text/Text.js";

export const module68 = {
  title: "Урок 68. Одиниці вимірювання часу. Задачі на визначення тривалості подій, часу. ​Доба. Час за годинником. Округлення до круглих чисел будь – якого розряду. Округлення до сотень.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_68,
    },
  ],
};
