import imglit09 from "./images/ukrlit/imglit_09.png";
import imglit10 from "./images/ukrlit/imglit_10.png";
import imglit11 from "./images/ukrlit/imglit_11.png";
import imglit12 from "./images/ukrlit/imglit_12.png";
import imglit13 from "./images/ukrlit/imglit_13.png";

export const module35_38 = {
  title: "§ 35-38. Комедія Григорія Квітки-Основ’яненка «Сватання на Гончарівці»",
  questions: [
    {
      id: 1,
      question: "1. Драматичні твори мають безпосередній зв’язок із закладом, позначеним буквою",
      options: [
        { id: "А", text: " А" },
        { id: "Б", text: " Б " },
        { id: "В", text: " В " },
        { id: "Г", text: " Г " }
      ],
      imgPath: imglit09,
      addClass: "horizontal",
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. У кульмінації комедії «Сватання на Гончарівці» наявний художній образ, позначений буквою",
      options: [
        { id: "А", text: "А" },
        { id: "Б", text: "Б" },
        { id: "В", text: "В" },
        { id: "Г", text: "Г" }
      ],
      imgPath: imglit10,
      addClass: "horizontal",
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Події комедії «Сватання на Гончарівці» відбуваються в регіоні, позначеному на карті цифрою",
      options: [
        { id: "А", text: "1" },
        { id: "Б", text: "2" },
        { id: "В", text: " 3 " },
        { id: "Г", text: "4" }
      ],
      imgPath: imglit11,
      addClass: "horizontal",
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Жанровим особливостям твору «Сватання на Гончарівці» НЕ відповідає зображення, позначене буквою",
      options: [
        { id: "А", text: "А" },
        { id: "Б", text: "Б" },
        { id: "В", text: "В" },
        { id: "Г", text: "Г" }
      ],
      imgPath: imglit12,
      addClass: "vertical",
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Художній образ, якого НЕМАЄ в повісті «Микола Джеря», позначено буквою",
      options: [
        { id: "А", text: "Прокопа Шкурата" },
        { id: "Б", text: "Осипа Скорика" },
        { id: "В", text: "Олексія" },
        { id: "Г", text: "Стецька" }
      ],
      imgPath: imglit13,
      addClass: "horizontal",
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: '6. Розподіліть ознаки творів різних літературних родів: <i><b>Драма; Лірика.</b></i> <br>(Перетягніть ознаки у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "висока образність та емоційність", type: "rightBucket" },
        { id: 2, text: "єдність місця, часу і дії", type: "leftBucket" },
        { id: 3, text: "наявність ремарок", type: "leftBucket" },
        { id: 4, text: "віршова форма", type: "rightBucket" },
        { id: 5, text: "поділ на дії та акти", type: "leftBucket" },
        { id: 6, text: "відсутність чітко окресленого сюжету", type: "rightBucket" }
      ],
      leftBucketTitle: "Драма",
      rightBucketTitle: "Лірика"
    },
    {
      id: 7,
      question:
        "7. Переглянувши буктрейлер Надії Гладкої до комедії «Сватання на Гончарівці» (2 хв 45 с), напишіть короткий відгук на нього й розкажіть, які два-три сцени з твору, на вашу думку, варто було б додати до цього рекламного відео, аби зацікавити майбутніх читачів / глядачів.",
      videoPath:
        "https://www.youtube.com/embed/ZYc9D4Kh3eI?si=CNP8dKizqzzWXKkE",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
