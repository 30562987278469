export const module80 = {
  title: "§ 80. Словосполучення і речення. Головні та другорядні члени речення",
  questions: [
    {
      id: 1,
      question: "1. Лише словосполучення наведено в рядку",
      options: [
        { id: "А", text: "наперекір волі, швидко прочитаний, турбуватися про брата" },
        { id: "Б", text: "знайдена книжка, не тільки сумління, промінь сонця" },
        { id: "В", text: "будуть читати, мрія про літо, найбільш успішний" },
        { id: "Г", text: "у присмерках ночі, дуже швидко, до п’ятої ранку" }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Лише словосполучення наведено в рядку",
      options: [
        { id: "А", text: "лист від брата, роздуми про майбутнє, будемо співати" },
        { id: "Б", text: "кава з молоком, поворот ліворуч, озиратися навколо" },
        { id: "В", text: "надто прискіпливий, через океан, побувати в горах" },
        { id: "Г", text: "початок іспитів, найбільш вдало, захоплення гравюрою" }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Потребує редагування словосполучення",
      options: [
        { id: "А", text: "піти по воду, у зеленому пальті" },
        { id: "Б", text: "декілька сосон, наказ по школі" },
        { id: "В", text: "два фахівця, ходити по горам" },
        { id: "Г", text: "кліпати очима, три олівці" }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Не потребують редагування словосполучення",
      options: [
        { id: "А", text: "навчатися мови, ліки проти ковіду" },
        { id: "Б", text: "пробачити тебе, згідно новому закону" },
        { id: "В", text: "хворіти грипом, нового будинка" },
        { id: "Г", text: "опанувати грою, дорожчий золота" }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Редагування потребує речення",
      options: [
        { id: "А", text: "Більшість учнів уже спланувала свої літні канікули." },
        { id: "Б", text: "Ви, Максиме Петровичу, такий спокійний!" },
        { id: "В", text: "Інжир і персик росте в південній частині України." },
        { id: "Г", text: "Два місяці минуло від сестриного дня народження." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Установіть відповідність.",
      dropdownQuestionParts: [
        "<i><b>Член речення</b></i> <br> 1) присудок ",
        { id: "dropdown1", text: " - " },
        "<br> 2) означення ",
        { id: "dropdown2", text: " - " },
        "<br> 3) додаток ",
        { id: "dropdown3", text: " - " },
        "<br> 4) обставина ",
        { id: "dropdown4", text: " - " },
      ],
      options: [
        { id: "А", text: "Гетьманувати – це надзвичайно складна справа." },
        { id: "Б", text: "Нарешті батьки дозволили нам прикрашати ялинку." },
        { id: "В", text: "Сонячний промінець помчав до лісу проганяти морок." },
        { id: "Г", text: "Уміння усміхатися під час скрути притаманне не кожному." },
        { id: "Д", text: "Сонячні промені почали пестити поля, ліси та води." },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "Г",
        dropdown3: "Б",
        dropdown4: "В",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: <i><b>речення, у якому виділено головний член; речення, у якому виділено другорядний член.</b></i>',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Закохані підійшли до самого берега послухати музику моря.", type: "rightBucket" },
        { id: 2, text: "Які красиві наші матусі!", type: "leftBucket" },
        { id: 3, text: "Люблю переглядати фільми про успішних особистостей.", type: "rightBucket" },
        { id: 4, text: "Подорожі викликають бажання пізнати світ і життя.", type: "rightBucket" },
        { id: 5, text: "У саду по-весняному пишно, мальовничо.", type: "leftBucket" },
        { id: 6, text: "Багато книжок купляють в інтернет-магазинах.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у якому виділено головний член",
      rightBucketTitle: "Речення, у якому виділено другорядний член",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: <i><b>речення, у якому виділено головний член; речення, у якому виділено другорядний член.</b></i>',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "По сліду гнало трійко вершників.", type: "leftBucket" },
        { id: 2, text: "Дім наповнений теплими солодкими пахощами.", type: "leftBucket" },
        { id: 3, text: "Бабуся навчила мене вишивати гладдю.", type: "rightBucket" },
        { id: 4, text: "Пироги вже спечено.", type: "rightBucket" },
        { id: 5, text: "Сміливі завжди мають щастя.", type: "leftBucket" },
        { id: 6, text: "Жовті, із червоними боками яблука ніби іграшкові.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення, у якому виділено головний член",
      rightBucketTitle: "Речення, у якому виділено другорядний член",
    },
  ],
};
