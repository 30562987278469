import {text_mod_18, } from "../text/Text.js";
export const module18 = {
  title:
    "Уроки 85-90. Порівняння задач. Обчислення виразів.​ Дія ділення. Знак ділення. Назви компонентів і результатів дії ділення. Зв’язок дій множення і ділення. Складання таблиці ділення на 2. Розв’язання задач на ділення на рівні частини​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_18, },
    ]
  }
