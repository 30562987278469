import { text_mod_16_1, text_mod_16_2, text_mod_16_3 } from "../text/Text";

export const module16 = {
  title: "Тема 16. Українці та кримські татари. Переказ «Фонтан сліз у Бахчисарайському палаці»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_16_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_16_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_16_3,
    },
    {
      id: 4,
      question: "Визначте, яке з тверджень НЕ відповідає дійсності.",
      options: [
        { id: "A", text: "Киримли – це історична самоназва кримських татар." },
        { id: "B", text: "Киримли – це корінний народ нашої держави." },
        { id: "C", text: "Більшість кримських татар за віросповіданням християни." },
        { id: "D", text: "Киримли є нащадком багатьох етносів, що в різний час проживали в Криму." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Кирим-Гірей, як вважали в народі, мав замість серця ",
      options: [
        { id: "A", text: "камінь" },
        { id: "B", text: "клубок шерсті" },
        { id: "C", text: "уламок заліза" },
        { id: "D", text: "уламок скла" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Крим-Гірей НЕ порівнює красу Діляре із",
      options: [
        { id: "A", text: "джерелом" },
        { id: "B", text: "сонцем" },
        { id: "C", text: "ланню" },
        { id: "D", text: "голубкою" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 7,
      question: "У центрі «Фонтану сліз» Омер вирізьбив",
      options: [
        { id: "A", text: "троянду" },
        { id: "B", text: "голубку" },
        { id: "C", text: "сльозу" },
        { id: "D", text: "око" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 8,
      question: "Скульптор Омер вирізьбив слимака на «Фонтані сліз», щоб",
      options: [
        { id: "A", text: "передати сумнів, який гризе душу хана." },
        { id: "B", text: "якнайкраще оздобити свій витвір." },
        { id: "C", text: "проявити свій авторський стиль." },
        { id: "D", text: "не порушувати мистецькі традиції." },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 9,
      question: "За задумом майстра Омера, фонтан мав розповісти про",
      options: [
        { id: "A", text: "сльози нерозділеного кохання" },
        { id: "B", text: "сльози від утрати коханої" },
        { id: "C", text: "пекучі чоловічі сльози" },
        { id: "D", text: "сльози невільниці Деляре" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
  ],
};
