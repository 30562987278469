export const module62_63 = {
  title: "§ 62–63. Звертання",
  questions:
    [
      {
        id: 1,
        question: "1. Звертанням ускладнено речення (розділові знаки пропущено)",
        options: [
          { id: "А", text: "Не оскверни цю мить і не поруш цинічним словом чи гріховним жестом." },
          { id: "Б", text: "Благослови мене у світ без тебе заборони і думать і страждать." },
          { id: "В", text: "Ви знаєте як липа шелестить у місячні весняні ночі?" },
          { id: "Г", text: "Золотоногі сосни голубі підіпріть головою дощ." }
        ],
        correctAnswer: "Г",
        isTest: true,
        type: 'test',
      },
      {
        id: 2,
        question: "2. Звертанням НЕ УСКЛАДНЕНО речення (розділові знаки пропущено)",
        options: [
          { id: "А", text: "Панове судді важко розібрати що і до чого як воно було." },
          { id: "Б", text: "А я щасливий що вручила ти мені одвічну тугу за тобою." },
          { id: "В", text: "Тебе я земле всю сходив до краю." },
          { id: "Г", text: "Дивись же на мене сонце і засмали мою душу." }
        ],
        correctAnswer: "Б",
        isTest: true,
        type: 'test',
      },
      {
        id: 3,
        question: "3. Поширеним звертанням ускладнено речення (розділові знаки пропущено)",
        options: [
          { id: "А", text: "Лунайте ж оркестри грими барабан бо крок наш і дух наш міцніє!" },
          { id: "Б", text: "На хаті трон лелеки… Добридень імператоре крилатий!" },
          { id: "В", text: "Добре учителю що ти небокраї підняв нам людського стремління." },
          { id: "Г", text: "Я не спатиму люба до світу буду думати й буду писати." }
        ],
        correctAnswer: "Б",
        isTest: true,
        type: 'test',
      },
      {
        id: 4,
        question: "4. Непоширеним звертанням ускладнено речення",
        options: [
          { id: "А", text: "Бідна волошко, чому ти у житі, а не на клумбі волієш рости?" },
          { id: "Б", text: "Так, друже мій, ми любимо одно – старої творчості додержане вино." },
          { id: "В", text: "Дозволь мені, мій вечоровий світе, упасти зерням в рідній борозні." },
          { id: "Г", text: "Ой, не крийся, природо, не крийся, що ти в тузі за літом, у тузі." }
        ],
        correctAnswer: "Г",
        isTest: true,
        type: 'test',
      },
      {
        id: 5,
        question: "5. Прочитайте речення. Припливайте до колиски(1) лебеді(2) як мрії, опустіться(3) тихі(4) зорі, синові під вії. Кому треба поставити замість усіх цифр, ОКРІМ",
        options: [
          { id: "А", text: "1" },
          { id: "Б", text: "2" },
          { id: "В", text: "3" },
          { id: "Г", text: "4" }
        ],
        correctAnswer: "Г",
        isTest: true,
        type: 'test',
      },
      {
        id: 6,
        question: "6. НЕ ПОТРЕБУЄ редагування звертання в реченні",
        options: [
          { id: "А", text: "Синьоока, дозволь пройти." },
          { id: "Б", text: "З кошиком, не порвіть мені панчохи." },
          { id: "В", text: "Дітки, ви виходите на наступній зупинці?" },
          { id: "Г", text: "З морозивом, вийдіть з автобуса." }
        ],
        correctAnswer: "В",
        isTest: true,
        type: 'test',
      },
      {
        id: 7,
        question:
          '7. Розподіліть речення за особливостями: <i><b>речення, у яких не допущено пунктуаційної помилки; речення, у яких допущено пунктуаційну помилку.</b> </br>(Перетягни номер речення у відповідний кошик)</i>.',
        isGame: true,
        type: 'interactive',
        initialWords: [
          { id: 1, text: "Ні, світе мій, вовік не розлюбить святі поля твої, і небеса, і води.", type: "leftBucket" },
          { id: 2, text: "Ой, братику ріднесенький, снився мені сон дивнесенький.", type: "rightBucket" },
          { id: 3, text: "О панно Інно, панно Інно, любові усміх квітне раз, ще й тлінно.", type: "leftBucket" },
          { id: 4, text: "Стелися наша доле, рушниками, квітчай домівки, бережи від бід!", type: "rightBucket" },
          { id: 5, text: "Не стелись, тумане, не шуміть, тополі, не печальте очі, ви берізки голі!", type: "rightBucket" },
          { id: 6, text: "Гориш ти, зоренько моя, моя порадонько святая!", type: "leftBucket" },
        ],
        leftBucketTitle: "Речення, у яких не допущено пунктуаційної помилки",
        rightBucketTitle: "Речення, у яких допущено пунктуаційну помилку",
      },
      {
        id: 8,
        question:
          '8. Розподіліть речення за особливостями: <i><b>речення, у яких не допущено пунктуаційної помилки; речення, у яких допущено пунктуаційну помилку.</b> </br>(Перетягни номер речення у відповідний кошик)</i>.',
        isGame: true,
        type: 'interactive',
        initialWords: [
          { id: 1, text: "Дозволь мені, великий господине, розважити книжками смуток твій.", type: "leftBucket" },
          { id: 2, text: "Гей, ви, зорі ясні! Де ви бачили більше кохання?", type: "leftBucket" },
          { id: 3, text: "Співаючи про хмари кучеряві, не забувай, поете про народ!", type: "rightBucket" },
          { id: 4, text: "Ой Дніпре, мій Дніпре, широкий та дужий, багато ти, батьку, у море носив козацької крові.", type: "leftBucket" },
          { id: 5, text: "Гетьте, думи ви, хмари осінні! Тож тепера весна золота!", type: "rightBucket" },
          { id: 6, text: "Я п’ю тебе сонце, твій теплий цілющий напій.", type: "rightBucket" },
        ],
        leftBucketTitle: "Речення, у яких не допущено пунктуаційної помилки",
        rightBucketTitle: "Речення, у яких допущено пунктуаційну помилку",
      },
    ],
};
