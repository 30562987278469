import { text_mod_28_1, text_mod_28_2, text_mod_28_3 } from "../text/Text.js";

export const module28 = {
  title: "Тема 28. З української драматургії ХІХ століття",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_28_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_28_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_28_3,
    },
    {
      id: 4,
      question: "1.	Розлогі описи, авторські відступи в драматичних творах замінюють на",
      options: [
        { id: "A", text: "авторські пояснення" },
        { id: "B", text: "ремарки" },
        { id: "C", text: "репліки" },
        { id: "D", text: "монологи" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "2.	Вид мистецтва, що не задіяний у створенні сценічного образу, — це",
      options: [
        { id: "A", text: "живопис" },
        { id: "B", text: "музика" },
        { id: "C", text: "кіно" },
        { id: "D", text: "хореографія" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Основними зображально-виражальними засобами розкриття характеру героїв у п'єсах є",
      options: [
        { id: "A", text: "мова персонажів, інтонація, жести, міміка" },
        { id: "B", text: "авторські ремарки" },
        { id: "C", text: "портрети персонажів" },
        { id: "D", text: "метафори, епітети, порівняння" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	Роль режисера в підготовці вистави — ",
      options: [
        { id: "A", text: "дослівно передати текст драматичного твору" },
        { id: "B", text: "встановити емоційний контакт із глядачами" },
        { id: "C", text: "видозмінити, осучаснити текст п’єси" },
        { id: "D", text: "знайти ефективні та оригінальні прийоми, щоб передати задум письменника" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Правило трьох єдностей  у класичній виставі — це єдність",
      options: [
        { id: "A", text: "персонажа, місця, дії" },
        { id: "B", text: "місця, часу, дії" },
        { id: "C", text: "теми, дії, сюжету" },
        { id: "D", text: "ідеї, сюжету, дії" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
