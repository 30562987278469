import {text_mod_56, } from "../text/Text.js";
export const module56 = {
  title:
    "§56. Питальні речення. Інтонація питальних речень",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_56, },
    ]
  }
