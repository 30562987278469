import {text_mod_52, } from "../text/Text.js";
export const module52 = {
  title:
    "§52. Слова, які називають числа. Сполучення числівників з іменниками",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_52, },
    ]
  }
