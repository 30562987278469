import { text_mod_43, text_mod_44, text_mod_45 } from "../text/Text";

export const module15 = {
  title: "Тема 15. Доба козацької України в сучасній літературі. Марія Морозенко «Іван Сірко – великий характерник»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_43,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_44,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_45,
    },
    {
      id: 4,
      question: "1.	Жанр твору «Іван Сірко — великий характерник» —",
      options: [
        { id: "А", text: "детектив" },
        { id: "Б", text: "історична повість" },
        { id: "В", text: "історичне фентезі" },
        { id: "Г", text: "героїчне фентезі" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Основа твору «Іван Сірко — великий характерник» –",
      options: [
        { id: "А", text: "авторська вигадка" },
        { id: "Б", text: "історичні праці Д. Яворницького" },
        { id: "В", text: "легенди й перекази" },
        { id: "Г", text: "пісня “Ой як крикнув же козак Сірко”" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "3. Іван Дуб малому Івасю поставив умову",
      options: [
        { id: "А", text: "вивчитись на характерника" },
        { id: "Б", text: "отримати дозвіл батьків" },
        { id: "В", text: "самостійно подолати пороги й дістатися на Хортицю" },
        { id: "Г", text: "не брати з собою вовка" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	Іван подолав пороги на Дніпрі",
      options: [
        { id: "А", text: "на човні" },
        { id: "Б", text: "на плоті" },
        { id: "В", text: "на чайці" },
        { id: "Г", text: "переплив" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "5. Під час першої зустрічі на Хортиці Івась уразив козаків",
      options: [
        { id: "А", text: "розкутістю в поводженні зі старшими" },
        { id: "Б", text: "веселою вдачею" },
        { id: "В", text: "надприродними здібностями" },
        { id: "Г", text: "сміливістю у відстоюванні своєї думки" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 9,
      question: "6. Навчатися характерництву Івася відправив",
      options: [
        { id: "А", text: "батько" },
        { id: "Б", text: "Богдан Хмельницький" },
        { id: "В", text: "Петро Дорошенко" },
        { id: "Г", text: "Іван Дуб" },
      ],
      correctAnswer: "В",
      isTest: true,
    },

  ],
};
