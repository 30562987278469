import { type } from "@testing-library/user-event/dist/type";

export const module2 = {
  title: "§ 2. Самостійні і службові частини мови, вигук",
  questions: [
    {
      id: 1,
      question:
        "1. Тільки із самостійних частин мови утворено сполуки у варіанті",
      options: [
        { id: "А", text: "лише діти, назустріч сонцю" },
        { id: "Б", text: "прийти вчасно, та випускниця" },
        { id: "В", text: "нехай читає, згідно з наказом" },
        { id: "Г", text: "восьмий клас, близько ліцею" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: "test",
    },
    {
      id: 2,
      question:
        '2. Усього в реченні займенників "Інші почали оглядати новака зі всіх боків, намагаючись до чого-небудь прискіпатись".',
      options: [
        { id: "А", text: "1" },
        { id: "Б", text: "2" },
        { id: "В", text: "3" },
        { id: "Г", text: "4" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: "test",
    },
    {
      id: 3,
      question: "3. Помилково узгоджено числівник з іменником у варіанті",
      options: [
        { id: "А", text: "дванадцять ґав" },
        { id: "Б", text: "чотири банани" },
        { id: "В", text: "півтора року" },
        { id: "Г", text: "троє айфонів" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: "test",
    },
    {
      id: 4,
      question: "4. Вигуку немає в реченні.",
      options: [
        {
          id: "А",
          text: "Ось-ось уже прибуде експрес, а Катрусі ще не видно.",
        },
        { id: "Б", text: "Гуп-гуп-гуп! — почулося за спиною…" },
        { id: "В", text: "— О-о, серенада! — захоплено вигукнув Федько." },
        { id: "Г", text: "Дехто гукнув «Слава!», підкидаючи шапки вгору." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: "test",
    },
    {
      id: 5,
      question:
        "5. З’ясуйте частину мови виділених слів (квадратик позначає наступне слово).",
      dropdownQuestionParts: [
        "Світ праці… То є ",
        { id: "dropdown1", text: "добрий " },
        " світ,<br />",
        { id: "dropdown2", text: "Коли " },
        " плуги біжать ",
        { id: "dropdown3", text: "по " },
        " полю,<br />Коли цілує очі цвіт<br />І вітер обійма ",
        { id: "dropdown4", text: "тополю " },
        ".",
      ],
      options: [
        { id: "А", text: "сполучник" },
        { id: "Б", text: "прикметник" },
        { id: "В", text: "дієприкметник" },
        { id: "Г", text: "прийменник" },
        { id: "Д", text: "іменник" },
      ],
      correctAnswers: {
        dropdown1: "Б",
        dropdown2: "А",
        dropdown3: "Г",
        dropdown4: "Д",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: "interactive",
    },
    {
      id: 6,
      question:
        "6. Розподіліть слова тексту за частинами мови – самостійні / службові.",
      isGame: true,
      type: "interactive",
      initialWords: [
        { id: 1, text: "Срібну", type: "leftBucket" },
        { id: 2, text: "волосину", type: "leftBucket" },
        { id: 3, text: "Довбуш", type: "leftBucket" },
        { id: 4, text: "віддав", type: "leftBucket" },
        { id: 5, text: "сину", type: "leftBucket" },
        { id: 6, text: "Щоб", type: "rightBucket" },
        { id: 7, text: "ні", type: "rightBucket" },
        { id: 8, text: "меч", type: "leftBucket" },
        { id: 9, text: "ні", type: "rightBucket" },
        { id: 10, text: "куля", type: "leftBucket" },
        { id: 11, text: "не", type: "rightBucket" },
        { id: 12, text: "взяли", type: "leftBucket" },
        { id: 13, text: "гуцула", type: "leftBucket" },
        { id: 14, text: ".", type: "rightBucket" },
        { id: 15, text: "А", type: "rightBucket" },
        { id: 16, text: "де", type: "rightBucket" },
        { id: 17, text: "ж", type: "rightBucket" },
        { id: 18, text: "зараз", type: "rightBucket" },
        { id: 19, text: "срібна", type: "leftBucket" },
        { id: 20, text: "волосина", type: "leftBucket" },
        { id: 21, text: "?", type: "rightBucket" },
        { id: 22, text: "В", type: "rightBucket" },
        { id: 23, text: "соняшниковім", type: "leftBucket" },
        { id: 24, text: "чубі", type: "leftBucket" },
        { id: 25, text: "мого", type: "leftBucket" },
        { id: 26, text: "сина", type: "leftBucket" },
        { id: 27, text: "...", type: "rightBucket" },
      ],
      leftBucketTitle: "Самостійні частини мови",
      rightBucketTitle: "Службові частини мови",
    },
    {
      id: 7,
      question:
        "7. <b>Дніпро – він чи воно? </b>. Перегляньте відео і виконайте завдання. </br> <b>Завдання:<b/> </br> <i> 1. Доберіть до іменника  <b>Дніпро</b> 2–3 прикметники, утворені словосполучення запишіть. </br> 2. Пригадайте вірш чи пісню зі словом  <b>Дніпро </b>, запишіть назву цього твору.</i>",
      videoPath:
        "https://www.youtube.com/embed/rXYuhr1W7hs?si=kLFFzy4GTHeq6OzG",
      addClass: "horizontal",
      type: "video",
      isTest: true,
      isCreativeTask: true,
    },
  ],
};
