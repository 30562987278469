import {text_mod_31, } from "../text/Text.js";
export const module31 = {
  title:
    "Урок 10. Літера Х велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_31, },
    ]
  };