import image006 from "./images/ukrlit/image006.jpg";

export const module04 = {
  title:
    "§ 6-7. Своєрідність давнього українського письменства. «Повчання дітям» Володимира Мономаха.",
  questions: [
    {
      id: 1,
      question:
        "1. Часові межі давньої української літератури записано в рядку",
      options: [
        { id: "А", text: "ІX – XVIII ст." },
        { id: "Б", text: "X – XVII ст." },
        { id: "В", text: "XІ – XVIII ст." },
        { id: "Г", text: "ІX – XVII ст." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. НЕПРАВИЛЬНИМ є твердження, що Володимир Мономах –",
      options: [
        { id: "А", text: "батько княгині Ольги" },
        { id: "Б", text: "великий князь київський" },
        { id: "В", text: "представник династії Рюриковичів" },
        { id: "Г", text: "автор дидактичної праці «Повчання дітям»" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Зображення Володимира Мономаха позначено буквою",
      options: [
        { id: "А", text: "А" },
        { id: "Б", text: "Б" },
        { id: "В", text: "В" },
        { id: "Г", text: "Г" },
      ],
      imgPath: image006,
      addClass: "horizontal",
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. НЕПРАВИЛЬНИМ є твердження",
      options: [
        {
          id: "А",
          text: "Володимир Мономах написав “Повчання дітям” наприкінці свого життя",
        },
        {
          id: "Б",
          text: "Володимир Мономах наполягав на охороні інтересів держави",
        },
        {
          id: "В",
          text: "у цьому творі він закликав своїх синів до праці й науки",
        },
        { id: "Г", text: "твір написано у віршованій формі" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question:
        "5. У реченні «Брехні остерігайтеся, і пияцтва, й облуди, від того душа гине та тіло» використано",
      options: [
        { id: "А", text: "епітет" },
        { id: "Б", text: "анафору" },
        { id: "В", text: "метафору" },
        { id: "Г", text: "порівняння" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Розподіліть ознаки за стилями. </br><b><i style='text-align: center; display: block;'>Ознаки стилів:</i></b>",
      isGame: true,
      type: 'interactive',
      initialWords: [
        {
          id: 1,
          text: "1. складна (мозаїчна) будова, багата тематика, екскурси в минуле, ліричні відступи",
          type: "rightBucket",
        },
        { id: 2, text: "2. мало художніх засобів", type: "leftBucket" },
        {
          id: 3,
          text: "3. уся увага зосереджується на змісті",
          type: "leftBucket",
        },
        {
          id: 4,
          text: "4. на зміну християнському оптимізму  приходить песимізм",
          type: "rightBucket",
        },
        {
          id: 5,
          text: "5. основні теми – династична та державна єдність Русі; християнський оптимізм щодо майбутнього об’єднаної Русі",
          type: "rightBucket",
        },
        {
          id: 6,
          text: "6. багато художніх засобів (окрім епітета й порівняння, часто вживана гіпербола)",
          type: "rightBucket",
        },
        { id: 7, text: "7. проста будова", type: "leftBucket" },
        {
          id: 8,
          text: "8. утвердження культу аскетизму – стриманості, відлюдництва, відмови від життєвих благ, радощів життя",
          type: "rightBucket",
        },
      ],
      leftBucketTitle: "Монументальний стиль",
      rightBucketTitle: "Орнаментальний стиль",
    },
  ],
};
