import { text_mod_4, } from "../text/Text.js";

export const module06 = {

  title: "Урок 6. Безпека в населеному пункті. Вплив забрудненого середовища на життя і здоров’я людини. Атмосферні забруднення, забруднення ґрунтів",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_4,
    },

  ],
};
