import {text_mod_61, } from "../text/Text.js";
export const module61 = {
  title:
    "§61. Будова тексту",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_61, },
    ]
  }
