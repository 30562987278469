import {text_mod_6, } from "../text/Text.js";
export const module06 = {
  title:
    "Урок 6. Моя школа",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_6, },
    ]
  };