import {text_mod_14, } from "../text/Text.js";
export const module14 = {
  title:
    "§14. Дзвінкі й глухі приголосні звуки. Дзвінкі приголосні звук в кінці слова та складу перед глухими",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_14, },
    ]
  }
