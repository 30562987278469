import { Component } from "react";
import Slider from "react-slick";
import Baloon from "./baloon/Baloon";

import "./LessonSliderPreview.css";
import "./responsive.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import heading from "../../images/heading.png";
import slide1 from "../../images/book_circle_1.png";
import slide2 from "../../images/book_circle_2.png";
import slide3 from "../../images/book_circle_3.png";
import slide4 from "../../images/book_circle_4.png";
import slide5 from "../../images/book_circle_5.png";
import slide6 from "../../images/book_circle_6.png";

class LessonSliderPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: true,
      // width: 1400,
    };
  }

  // increaseWidth = () => {
  //   this.setState((prevState) => ({ width: prevState.width + 100 }));
  // };

  // decreaseWidth = () => {
  //   this.setState((prevState) => ({ width: prevState.width - 100 }));
  // };

  // toggleDisplay = () => {
  //   this.setState((prevState) => ({ display: !prevState.display }));
  // };

  render() {
    const { display, width } = this.state;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 2000,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 1,
            dots: false,
          },
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 2,
            dots: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            dots: false,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <section className="gap lesson-slider-baloon">
        <div className="container">
          <div className="heading">
            <img alt="heading" src={heading} />
            <h2>ОБИРАЙ ВПРАВИ</h2>
            <p>Обирай вправи для перевірки знань!</p>
          </div>

          <div className="baloon-slider">
            <div className="slider-container">
              <div
                className="slider-content"
                style={{
                  display: display ? "block" : "none",
                }}
              >
                <Slider {...settings}>
                  <div>
                    <a href="/class/8/int/yac/modules">
                    <Baloon imgSrc={slide1} link="#" />
                    </a>
                  </div>
                  <div>
                    <a href="class/8/ukrmova/gol/modules">
                    <Baloon imgSrc={slide2} link="#" />
                    </a>
                  </div>
                  <div>
                    <a href="class/8/ukrlit/yac/modules">
                    <Baloon imgSrc={slide3} link="#" />
                    </a>
                  </div>
                  <div>
                    <a href="class/8/ukrlit/avr/modules">
                    <Baloon imgSrc={slide4} link="#" />
                    </a>
                  </div>
                  <div>
                    <a href="class/8/zbd/pol/modules">
                    <Baloon imgSrc={slide5} link="#" />
                    </a>
                  </div>
                  <div>
                    <a href="/class/8/ukrmova/avr/modules">
                    <Baloon imgSrc={slide6} link="#" />
                    </a>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LessonSliderPreview;
