import {text_mod_35, } from "../text/Text.js";
export const module35 = {
  title:
    "§35. Іменники, ​протилежні за значенням. ​Іменники, ​близькі за значенням​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_35, },
    ]
  }
