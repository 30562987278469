export const module01 = {
  title:
    "Словосполучення і речення як одиниці синтаксису. Ознаки словосполучення. Головне й залежне слово в словосполученні. Типи словосполучень за будовою.",
  questions: [
    {
      id: 1,
      question: "1. Усі ознаки характеризують словосполучення, ОКРІМ",
      options: [
        { id: "А", text: "складається зі слів" },
        { id: "Б", text: "виражає закінчену думку" },
        { id: "В", text: "має головне й залежне слово" },
        { id: "Г", text: "не має інтонаційної завершеності" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: "test",
    },
    {
      id: 2,
      question: "2. Словосполучення записано у варіанті",
      options: [
        { id: "А", text: "з-понад будинків" },
        { id: "Б", text: "визирало сонце" },
        { id: "В", text: "ніби всміхаючись" },
        { id: "Г", text: "пестило промінням" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: "test",
    },
    {
      id: 3,
      question: "3. Лише словосполучення вжито у варіанті",
      options: [
        { id: "А", text: "читати книжку, піднести гарбуза" },
        { id: "Б", text: "згідно із законом, купити айфон" },
        { id: "В", text: "зоряний час, найбільш приємний" },
        { id: "Г", text: "особистісне зростання, п’ята година" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: "test",
    },
    {
      id: 4,
      question: "4. Просте словосполучення записано у варіанті",
      options: [
        { id: "А", text: "прямувати назустріч долі" },
        { id: "Б", text: "відповідно до постанови" },
        { id: "В", text: "навчатися англійської мови" },
        { id: "Г", text: "дозволено тільки своїм" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: "test",
    },
    {
      id: 5,
      question: "5. Складне словосполучення записано у варіанті",
      options: [
        { id: "А", text: "немовбито чарівна паличка" },
        { id: "Б", text: "пролягли білосніжні тумани" },
        { id: "В", text: "не потрапляти в такт музики" },
        { id: "Г", text: "за сприяння департаменту" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: "test",
    },
    {
      id: 6,
      question: "6. Прислівникове словосполучення записано у варіанті",
      options: [
        { id: "А", text: "надзвичайно впевнено" },
        { id: "Б", text: "надзвичайно сміливий" },
        { id: "В", text: "несподівано зʼявитися" },
        { id: "Г", text: "зʼявитися вчасно" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: "test",
    },
    {
      id: 7,
      question:
        "7. Розподіліть конструкції у дві чарунки – словосполучення і сполуки слів:",
      isGame: true,
      type: "interactive",
      initialWords: [
        { id: 1, text: "буду опановувати", type: "rightBucket" },
        { id: 2, text: "вареники з вишнями", type: "leftBucket" },
        { id: 3, text: "те завдання", type: "leftBucket" },
        { id: 4, text: "обібрати до нитки", type: "rightBucket" },
        { id: 5, text: "найвищий з-поміж соняшників", type: "leftBucket" },
        { id: 6, text: "оригінальне есе", type: "leftBucket" },
      ],
      leftBucketTitle: "Словосполучення",
      rightBucketTitle: "Сполуки слів",
    },
    {
      id: 8,
      question:
        "8. Розподіліть конструкції у дві чарунки – словосполучення і сполуки слів:",
      isGame: true,
      type: "interactive",
      initialWords: [
        { id: 1, text: "прийшли батьки", type: "rightBucket" },
        { id: 2, text: "навколо гімназії", type: "rightBucket" },
        { id: 3, text: "радісно від успіху", type: "leftBucket" },
        { id: 4, text: "менш відкритий", type: "rightBucket" },
        { id: 5, text: "хлопці і дівчата", type: "rightBucket" },
        { id: 6, text: "збігаючи з гори", type: "leftBucket" },
      ],
      leftBucketTitle: "Словосполучення",
      rightBucketTitle: "Сполуки слів",
    },
    {
      id: 9,
      question:
        "9. <span class='tekst-wow'>Який</span> чи <span class='tekst-wow'>котрий</span>. Перегляньте відео і виконайте завдання. </br> <b>Завдання:<b/> </br> <i></i> 1.З’ясуйте, чому в передостанньому реченні вправи 10 автор використав саме слово який. </br>2. У якому реченні цього тексту можна замінити що на який?</i>",
      videoPath:
        "https://www.youtube.com/embed/7pLfC1evOUY?si=sd7RQDt7X5nVbewx",
      addClass: "horizontal",
      type: "video",
      isTest: true,
      isCreativeTask: true,
    },
  ],
};
