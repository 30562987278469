import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import authorPaths from '../subject-page/paths';
import subjectPaths from '../class_page/paths';
import { modulesData } from '../author_page/modulesData';

import "./BreadcrumbsModulePage.css"

function getFullName(abbreviation, from) {
    for (const [key, value] of Object.entries(from)) {
        if (value === abbreviation) {
            return key;
        }
    }
    return abbreviation;
}

function findTitle(modulesData, id, subject, author, path) {
    if (
        modulesData[id] &&
        modulesData[id][subject] &&
        modulesData[id][subject][author]
    ) {
        const module = modulesData[id][subject][author].find(
            (item) => item.path === path
        );
        return module ? module.title : null;
    }
    return null;
};

function BreadcrumbsModulePage() {
    const location = useLocation();


    const pathSegments = location.pathname.split('/').filter(Boolean);

    const subject = pathSegments[2];
    const subjectFullName = subject ? getFullName(subject, subjectPaths) : null;

    const id = pathSegments[1];

    const author = pathSegments[3];
    const autorFullName = author ? getFullName(author, authorPaths) : null;

    const module = pathSegments[5];
    const moduleFullname = module ? findTitle(modulesData, id, subject, author, module) : null;

    const crumbs = [
        { name: subjectFullName, path: `/class/${id}/${subject}` },
        author && { name: autorFullName, path: `/class/${id}/${subject}/${author}/modules` },
        module && { name: moduleFullname, path: `/class/${id}/${subject}/${author}/modules/${module}` }
        ,
    ].filter(Boolean);

    if (location.pathname.includes('/instruction') || location.pathname.includes('/about')) {
        return null;
    }

    return (

        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
                Головна
            </Breadcrumb.Item>
            {crumbs.map((crumb, index) => (
                <Breadcrumb.Item
                    key={index}
                    linkAs={Link}
                    linkProps={{ to: crumb.path }}
                    active={index === crumbs.length - 1}
                >
                    {crumb.name}
                </Breadcrumb.Item>
            ))}
            {author === "avr" && module && (
                <Breadcrumb.Item onClick={() => (window.location.href = location.pathname)}>
                    Вибір типу завдання</Breadcrumb.Item>
            )}
        </Breadcrumb>
    );
}

export default BreadcrumbsModulePage;
