import {text_mod_7, } from "../text/Text.js";
export const module07 = {
  title:
    "Уроки 28-30. Способи віднімання від 14 одноцифрових чисел.​ Периметр багатокутника.​ Способи віднімання від 15 одноцифрових чисел​​​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_7, },
    ]
  }
