const classData = {
  1: ["Математика",
    "Буквар",
  ],
  2: ["Українська мова", "Математика"],
  3: ["Українська мова та читання", "Математика"],
  8: [
    "Українська мова",
    "Українська література",
    "Українська та зарібужна література",
    "Здоров'я, безпека та добробут",
  ],
};

export default classData;
