import image0061 from "./images/ukrlit/image0061.jpg";

export const module05 = {
  title: "§ 8-9. Образ Русі в сучасній українській літературі.",
  questions: [
    {
      id: 1,
      question:
        "1. Події оповідання Раїси Іванченко «Ярославни» відбуваються у",
      options: [
        { id: "А", text: "ІХ ст." },
        { id: "Б", text: "Х ст." },
        { id: "В", text: "ХІ ст." },
        { id: "Г", text: "ХІІ ст." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Королевичу Гаральду «припала до серця»",
      options: [
        { id: "А", text: "Єлизавета" },
        { id: "Б", text: "Анастасія" },
        { id: "В", text: "Інгігерда" },
        { id: "Г", text: "Анна" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Згадка тлумача Івора про «черти й ризі» стосується",
      options: [
        { id: "А", text: "інтер’єру Десятинної церкви" },
        { id: "Б", text: "воєнних походів Гаральда" },
        { id: "В", text: "одруження княгині Анни" },
        { id: "Г", text: "давньої писемності" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question:
        "4. У реченні «Чи згодиться нарешті князівна Єлизавета покинути свій дім, і цей град пишний, і ці сади райські?» провідним художнім засобом є",
      options: [
        { id: "А", text: "епітет" },
        { id: "Б", text: "гіпербола" },
        { id: "В", text: "порівняння" },
        { id: "Г", text: "персоніфікація" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question:
        "5. Увідповідніть фрагменти оповідання з композиційними елементами.",
      dropdownQuestionParts: [
        "<i> 1) …Біля підніжжя горба розтяглася низка білостінних хат-ліплянок. Вони утворюють вулицю, що ніби витікає з-поміж двох високих горбів і вливається в широкий гомінкий потік набережної...",
        { id: "dropdown1" },
        "<br /><i> 2) На грудях князівен – важкі золоті гривни; на високих шапках молодих княжичів – соболі й куниці; довгі сорочки із широкими рукавами внизу також розшиті узорами. ",
        { id: "dropdown2" },
        "<br /> <i>3) У храмі… барвами веселки переливається мозаїка Богородиці… Ноги ковзають по дзеркальній поверхні підлоги із сірого й білого мармуру. Мармурові колони гінко піднесли на своїх плечах склепіння центральної бані. ",
        { id: "dropdown3" },
      ],
      options: [
        { id: "А", text: "портрет" },
        { id: "Б", text: "розв’язка" },
        { id: "В", text: "інтер’єр" },
        { id: "Г", text: "пейзаж" },
      ],
      correctAnswers: {
        dropdown1: "Г",
        dropdown2: "А",
        dropdown3: "В",
      },
      showAnswerOptions: true,
      dropdownAmount: 3,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 6,
      question:
        "6. Розподіліть твердження на дві колонки. </br><b><i style='text-align: center; display: block;'>Твердження: </i></b>",
      isGame: true,
      type: 'interactive',
      initialWords: [
        {
          id: 1,
          text: "1. Під час проводів Анни гості молилися язичницьким богам.",
          type: "leftBucket",
        },
        {
          id: 2,
          text: "2. Івор знайшов славу на чужих полях, але не здобув серця київської князівни. ",
          type: "rightBucket",
        },
        {
          id: 3,
          text: "3. Анастасія принесла на береги Дунаю любов до науки й знань.",
          type: "leftBucket",
        },
        {
          id: 4,
          text: "4. Софію Київську збудував князь Володимир.",
          type: "rightBucket",
        },
        {
          id: 5,
          text: "5. Єлизавета один раз відмовила Геральду.",
          type: "rightBucket",
        },
        {
          id: 6,
          text: "6. Гаральд склав пісню про Єлизавету.",
          type: "leftBucket",
        },
        {
          id: 7,
          text: "7. Анна одружилася з молодим французьким королем.",
          type: "rightBucket",
        },
        {
          id: 8,
          text: "8. У киян була писемність ще до запровадження християнства.",
          type: "leftBucket",
        },
      ],
      leftBucketTitle: "Правильне твердження",
      rightBucketTitle: "Неправильне твердження",
    },
    {
      id: 7,
      question:
        "7. Переглянувши відеоматеріал телеканалу «СТБ» «Київська Русь – фундамент історії України» (20 хв 54 с) про Анну-Ярославну, заповніть у робочому зошиті таблицю.",
      imgPath: image0061,
      videoPath: "https://www.youtube.com/embed/45-tMkd5PSw",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
