import React, { useState } from "react";
import { Container, Row, Col, Card, Tooltip, OverlayTrigger, Form } from "react-bootstrap";

import { studentDataMock } from "./mock/StudentDataMock";
import { teacherDataMock } from "./mock/TeacherDataMock";

import doneIcon from "./images/done-icon.svg";
import notDoneIcon from "./images/not-done-icon.svg";

function UserProfileContent({ userName, userRoles }) {
    const [teacherModulesState, setTeacherModulesState] = useState(
        teacherDataMock.subjects.map((subject) => ({
            name: subject.name,
            modules: subject.modules.map((module) => ({
                ...module,
                isEnabled: module.isDone, // Изначальное состояние чекбокса
            })),
        }))
    );

    const handleCheckboxChange = (subjectIndex, moduleIndex) => {
        setTeacherModulesState((prevState) => {
            const newState = [...prevState];
            newState[subjectIndex].modules[moduleIndex].isEnabled =
                !newState[subjectIndex].modules[moduleIndex].isEnabled;
            return newState;
        });
    };

    return (
        <Container className="mt-4">
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={8}>
                    <Card className="shadow-sm rounded">
                        <Card.Body>
                            <div className="user-profile text-center">
                                <h5>{userName}</h5>
                                {Array.isArray(userRoles) && userRoles.includes("STUDENT") && (
                                    <div>Київський ліцей "Спокій" №439 </div>
                                )}
                            </div>

                            {Array.isArray(userRoles) && userRoles.includes("STUDENT") && (
                                <div>
                                    {studentDataMock.subjects.map((subject, index) => (
                                        <Card
                                            key={index}
                                            className="mb-3 shadow-sm"
                                            style={{
                                                backgroundColor: "#f8f9fa",
                                                border: "none",
                                            }}
                                        >
                                            <Card.Body>
                                                <h5>{subject.name}</h5>
                                                <div className="d-flex flex-wrap align-items-center">
                                                    {subject.modules.map((module, moduleIndex) => (
                                                        <OverlayTrigger
                                                            key={moduleIndex}
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip
                                                                    id={`tooltip-student-${moduleIndex}`}
                                                                >
                                                                    {module.tooltip}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <a
                                                                href={module.link}
                                                                className="m-1"
                                                                style={{
                                                                    textDecoration: "none",
                                                                    display: "inline-block",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        module.isDone
                                                                            ? doneIcon
                                                                            : notDoneIcon
                                                                    }
                                                                    alt={module.title}
                                                                    style={{
                                                                        width: "36px",
                                                                        height: "36px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </a>
                                                        </OverlayTrigger>
                                                    ))}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>
                            )}

                            {Array.isArray(userRoles) && userRoles.includes("TEACHER") && (
                                <div>
                                    <p>Ви маєте можливість <i>активувати \ деактивувати</i>  конкретні завдання для навчального процесу</p> <br />
                                    <p>Функція активації і деактивації працює на стороні фронтенду, на час апробації для того, щоб експертні дії у додатку не заважали один одному</p> <br />

                                    {teacherModulesState.map((subject, subjectIndex) => (
                                        <Card
                                            key={subjectIndex}
                                            className="mb-3 shadow-sm"
                                            style={{
                                                backgroundColor: "#f8f9fa",
                                                border: "none",
                                            }}
                                        >
                                            <Card.Body>
                                                <h5>{subject.name}</h5>
                                                <ul style={{ listStyle: "none", padding: 0 }}>
                                                    {subject.modules.map((module, moduleIndex) => (
                                                        <li
                                                            key={moduleIndex}
                                                            className="d-flex align-items-center mb-2"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={module.isEnabled}
                                                                onChange={() =>
                                                                    handleCheckboxChange(
                                                                        subjectIndex,
                                                                        moduleIndex
                                                                    )
                                                                }
                                                                className="me-2"
                                                            />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip
                                                                        id={`tooltip-teacher-${moduleIndex}`}
                                                                    >
                                                                        {module.tooltip}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>{module.title}</span>
                                                            </OverlayTrigger>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default UserProfileContent;
