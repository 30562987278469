import {text_mod_36, } from "../text/Text.js";
export const module36 = {
  title:
    "§36. Велика буква ​в іменах, по батькові​ та прізвищах​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_36, },
    ]
  }
