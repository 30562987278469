import { text_mod_34 } from "../text/Text.js";

export const module34 = {
  title: "Урок 34. ​Рівняння. Закріплення таблиці множення числа 6. Задачі. Блок – схеми. ​Дії з іменованими числами.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_34,
    },
  ],
};
