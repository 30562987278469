import {text_mod_47, } from "../text/Text.js";
export const module47 = {
  title:
    "Урок 26. Літера Ц велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_47, },
    ]
  };