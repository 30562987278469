export const module12 = {
  title: "Види критичного читання",
  questions: [
    {
      id: 1,
      question: "1. Основна мета критичного читання —",
      options: [
        { id: "А", text: "швидко прочитати текст" },
        { id: "Б", text: "зрозуміти й оцінити інформацію в тексті" },
        { id: "В", text: "запам’ятати всі деталі тексту" },
        { id: "Г", text: "написати власну думку про текст" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Визначте, який з наведених видів читання передбачає швидке ознайомлення з текстом.",
      options: [
        { id: "А", text: "читання для розуміння" },
        { id: "Б", text: "читання для анотування" },
        { id: "В", text: "читання для ознайомлення" },
        { id: "Г", text: "глибоке читання" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Визнач, який з наступних етапів критичного читання вимагає уважного аналізу аргументів автора.",
      options: [
        { id: "А", text: "читання для ознайомлення" },
        { id: "Б", text: "читання для розуміння" },
        { id: "В", text: "читання для анотування" },
        { id: "Г", text: "читання для розваги" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Важливим складником читання для анотування є",
      options: [
        { id: "А", text: "швидке читання тексту" },
        { id: "Б", text: "формулювання уточнювальних запитань" },
        { id: "В", text: "ігнорування деталей" },
        { id: "Г", text: "читання тексту лише один раз" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Критичне читання потребує часу та майстерності, тому що",
      options: [
        { id: "А", text: "потрібно запам’ятати всі деталі" },
        { id: "Б", text: "воно вимагає глибокого аналізу й оцінювання" },
        { id: "В", text: "треба читати лише один раз" },
        { id: "Г", text: "це процес, який не потребує зусиль" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Найбільш ефективним для повного розуміння тексту є",
      options: [
        { id: "А", text: "читання лише для ознайомлення" },
        { id: "Б", text: "читання тричі з використанням усіх видів читання" },
        { id: "В", text: "читання тільки для анотування" },
        { id: "Г", text: "читання без нотаток" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
  ],
};
