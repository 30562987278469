import React from "react";
import { useParams } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import classData from "./classData";
import paths from "./paths";
import Header from "../header_h/Header";
import Footer from "../footer/Footer";

import "./class_page.css";

const ClassPage = ({ userName, userRoles }) => {
  const { id } = useParams();
  const items = classData[id] || [];

  return (
    <>
      <Header userName={userName} userRoles={userRoles} />
      <div className="classContainer">
        <ListGroup>
          {items.map((item, index) => (
            <ListGroup.Item
              key={index}
              action
              href={`${window.location.pathname}/${paths[item] || ""}`}
              variant="info"
              className="list-item"
            >
              {item}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>

      {/* <div className="classContainer">
        <ListGroup>
          <ListGroup.Item action variant="info">
            Info
          </ListGroup.Item>
        </ListGroup>
      </div> */}

      <Footer />
    </>
  );
};

export default ClassPage;
