import {text_mod_5, } from "../text/Text.js";
export const module05 = {
  title:
    "Урок 8. Буквені та числові вирази.​ Периметр прямокутника. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_5, },
    ]
  }
