
import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { mockLogin } from "../../api/mockAuth";

import "./MockLoginAsNew.css";

import Logo from "../../images/logoG.jpg";

const MockLoginAsNew = (props) => {
    const [inputUsername, setInputUsername] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await mockLogin(inputUsername, inputPassword);

            localStorage.setItem("token", response.token);
            localStorage.setItem("roles", JSON.stringify(response.roles));
            localStorage.setItem("username", response.username);

            props.onLogin(response.username);
            navigate("/home");
        } catch (error) {
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handlePassword = () => {
        alert("");
    };

    return (
        <div
            className="sign-in__wrapper"
            // style={{ backgroundImage: `url(${BackgroundImage})` }}
            style={{ backgroundColor: `#f797f5` }}

        >
            <div className="sign-in__backdrop"></div>

            <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
                <img
                    className="img-thumbnail mx-auto d-block mb-2"
                    src={Logo}
                    alt="logo"
                />
                <div className="h4 mb-2 text-center">Вхід</div>
                {showError && (
                    <Alert
                        className="mb-2"
                        variant="danger"
                        onClose={() => setShowError(false)}
                        dismissible
                    >
                        Невірний логін або пароль
                    </Alert>
                )}
                <Form.Group className="mb-2" controlId="username">
                    <Form.Label>Логін</Form.Label>
                    <Form.Control
                        type="text"
                        value={inputUsername}
                        placeholder="student | teacher | parent"
                        onChange={(e) => setInputUsername(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-2" controlId="password">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                        type="password"
                        value={inputPassword}
                        placeholder="123"
                        onChange={(e) => setInputPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                {!loading ? (
                    <Button className="w-100" variant="primary" type="submit">
                        Увійти
                    </Button>
                ) : (
                    <Button className="w-100" variant="primary" type="submit" disabled>
                        Входимо...
                    </Button>
                )}

                {/* Лінк "Forgot password?" */}
                {/* <div className="d-grid justify-content-end">
                    <Button className="text-muted px-0" variant="link" onClick={handlePassword}>
                        Забули пароль?
                    </Button>
                </div> */}
            </Form>

            {/* "Футер" */}
            <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
                E-Грамота | &copy; Видавництво "Грамота", 2025
            </div>
        </div>
    );
};

export default MockLoginAsNew;
