import {text_mod_55, } from "../text/Text.js";
export const module55 = {
  title:
    "§55. Розповідні речення. Інтонація розповідних речень",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_55, },
    ]
  }
