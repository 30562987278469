import {text_mod_20, } from "../text/Text.js";
export const module20 = {
  title:
    "Уроки 103-107. Складання за схемою добутків із першим множником 5 і частки із дільником 5. Визначення часу за годинником. Хвилина.​ Складання добутків із множником 5​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_20, },
    ]
  }
