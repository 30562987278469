import React from "react";
import "./Baloon.css";
import "./responsive.css";


class Baloon extends React.Component {
  render() {
    const { link, imgSrc } = this.props;

    return (
      <div className="baloon-circle" style={{ backgroundImage: `url(${imgSrc})` }}>
        <div className="baloon-square">
          <a href={link} className="baloon-item">
          </a>
        </div>
      </div>
    );
  }
}

export default Baloon;
