export const module23 = {
  title: "§ 23. Узгодження підмета й присудка. Тире між підметом і присудком",
  questions: [
    {
      id: 1,
      question: "1. На кількості виконавців наголошено в реченні",
      options: [
        { id: "А", text: "Дев’ятеро військових сиділи довкола багаття." },
        { id: "Б", text: "Поміж дерев виднілися два туристичні намети." },
        { id: "В", text: "На правому березі показалося кілька ведмежат." },
        { id: "Г", text: "У спортзалі шестеро восьмикласників змагаються." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Редагування потребує речення",
      options: [
        { id: "А", text: "Багато птахів уже відлетіло у вирій." },
        { id: "Б", text: "Полтава й Миргород ваблять колоритом." },
        { id: "В", text: "Більшість дітей додому поїхала трамваєм." },
        { id: "Г", text: "Пані Маргарито, ви такі елегантні!" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Редагування потребує речення",
      options: [
        { id: "А", text: "Не спізнився на урок Євген і Вадим." },
        { id: "Б", text: "До кінця уроку залишилося три хвилини." },
        { id: "В", text: "Чимало туристів потрапило під літній дощ." },
        { id: "Г", text: "Малина й ожина особливо смакують дітям." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question:
        "4. Тире між підметом і присудком треба поставити в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Тарас Шевченко геніальний і неповторний." },
        { id: "Б", text: "Чисте озерце як справжнє болото від бруду." },
        { id: "В", text: "Поезія безмежний степ і лебединий легіт." },
        { id: "Г", text: "Кров людська не водиця, проливати не годиться." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question:
        "5. Тире між підметом і присудком НЕ ТРЕБА ставити в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Чай рослина делікатна." },
        { id: "Б", text: "Серце не камінь." },
        { id: "В", text: "Лось суворий звір" },
        { id: "Г", text: "Чотири та чотири вісім." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Тире між підметом і присудком НЕ ТРЕБА ставити в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Сивий коник то мій брат." },
        { id: "Б", text: "Жити значить боротися." },
        { id: "В", text: "Київ столиця України." },
        { id: "Г", text: "День сьогодні сонячний." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 7,
      question:
        "7. Розподіліть речення за правилами узгодження підмета з присудком: 1 – правильно узгоджено; 2 – неправильно узгоджено.",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Вівчарка й киця нарешті подружилася. ", type: "rightBucket" },
        { id: 2, text: "Чимало цікавих фільмів потребує українського дубляжу. ", type: "leftBucket" },
        { id: 3, text: "Із часу вступу до коледжу сплило три роки. ", type: "leftBucket" },
        { id: 4, text: "Більшість примірників часопису вже надійшли в продаж. ", type: "rightBucket" },
        { id: 5, text: "Ірина з Олексою зустрілися біля станції метро «Проспект Свободи».", type: "leftBucket" },
        { id: 6, text: "Минули дві години, як хлопець пішов. ", type: "rightBucket" },
        { id: 7, text: "Розкішна гілочка сакури й магнолії дивувала своєю красою. ", type: "rightBucket" },
        { id: 8, text: "Ми з тобою кораблі, відірвались від землі. ", type: "leftBucket" },
        { id: 9, text: "Ви, Петре, добре станцювали. ", type: "leftBucket" },
        { id: 10, text: "Ви, Маріє, дуже витривалі. ", type: "rightBucket" },
        { id: 11, text: "Пес Патрон і його подруга Ягідка знову долає перешкоди. ", type: "rightBucket" },
        { id: 12, text: "Сад Шевченка та Центральний міський парк чарують своєю вишуканістю.", type: "leftBucket" },

      ],
      leftBucketTitle: "Правильно узгоджено",
      rightBucketTitle: "Неправильно узгоджено",
    },

  ],
};
