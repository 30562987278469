import { text_mod_43 } from "../text/Text.js";

export const module43 = {
  title: "Урок 43. ​Задачі на зведення до одиниці. Обчислення виразів.​ Таблиця множення і ділення на 8. Розв'язання задач і рівнянь.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_43,
    },
  ],
};
