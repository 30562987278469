import {text_mod_43, } from "../text/Text.js";
export const module43 = {
  title:
    "Урок 22. Літера Ю велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_43, },
    ]
  };