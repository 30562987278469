export const module8 = {
  title: `§ 8. Уживання прийменників <span class="text-wow new-wow"> у, в </span> і початкових <br><span class="text-wow new-wow"> у-, в-;</span>
сполучників, часток <span class="text-wow new-wow"> і, й </span> та початкових <br><span class="text-wow new-wow"> і-, й-; </span>
прийменника <span class="text-wow new-wow"> з </span> і його варіантів <span class="text-wow new-wow">із, зі (зо)</span>.
  </span>`,
  questions: [
    {
      id: 1,
      question:
        '1. Прийменник  <span class="text-wow">&nbsp; у &nbsp;</span> має бути на місці пропуску в реченні',
      options: [
        { id: "А", text: "Не все тому правда, що ... пісні співають" },
        { id: "Б", text: "Хвалилася вівця, що ... неї хвіст, як у жеребця." },
        { id: "В", text: "Був ... бувальцях, знає, що кий, що палиця." },
        { id: "Г", text: "Два коти ... одному мішку не помиряться." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question:
        '2. Правило вживання <span class="text-wow">&nbsp; і – й  &nbsp;</span> порушено у варіанті',
      options: [
        { id: "А", text: "Від роботи й коні дохнуть." },
        { id: "Б", text: "Де коротко, там й рветься." },
        { id: "В", text: "Був у Римі, а папи й не бачив." },
        { id: "Г", text: "Бджола мала, а й та працює." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question:
        '3. Правило вживання <span class="text-wow">&nbsp; і – й  &nbsp;</span> дотримано у варіанті',
      options: [
        { id: "А", text: "Сергій і Петро" },
        { id: "Б", text: "каштани і липи" },
        { id: "В", text: "Олена й Ярослава" },
        { id: "Г", text: "добро й зло" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question:
        '4. Прийменник  <span class="text-wow">&nbsp; із  &nbsp;</span> має бути на місці пропуску у варіанті',
      options: [
        {
          id: "А",
          text: 'Чи сяк, чи так, а не буде <span class="text-wow">&nbsp;...&nbsp;</span> риби рак.',
        },
        {
          id: "Б",
          text: 'Калина хвалилась, що <span class="tekst-wow">&nbsp;...&nbsp;</span> медом солодка.',
        },
        {
          id: "В",
          text: 'Баба <span class="text-wow">&nbsp;...&nbsp;</span> воза – кобилі легше.',
        },
        {
          id: "Г",
          text: 'Він <span class="text-wow">&nbsp;...&nbsp;</span> води сухим вийде.',
        },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. З’ясуйте правильне вживання прийменників у реченнях.",
      dropdownQuestionParts: [
        "1) ",
        { id: "dropdown1", text: "" },
        " смутної дівки не буде весела жінка. <br /> 2) Живи просто, проживеш років ",
        { id: "dropdown2", text: "" },
        " сто.<br /> 3) ",
        { id: "dropdown3", text: "" },
        " базіками тримай язик за зубами. <br /> 4) Апетит ",
        { id: "dropdown4", text: "" },
        "їдою прибуває.",
      ],
      options: [
        { id: "з", text: "з" },
        { id: "із", text: "із" },
        { id: "зі", text: "зі" },
        { id: "зо", text: "зо" },
      ],
      correctAnswers: {
        dropdown1: "зі",
        dropdown2: "зо",
        dropdown3: "з",
        dropdown4: "із",
      },
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 6,
      question: `Розподіліть слова у дві чарунки: <br>За чергування <span class="text-wow">у/в</span> лексичне значення слова
<span class"text-wow">змінюється – не змінюється</span>`,
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "В/уклад", type: "leftBucket" },
        { id: 2, text: "в/удома", type: "rightBucket" },
        { id: 3, text: "в/уранці", type: "rightBucket" },
        { id: 4, text: "в/уступ", type: "leftBucket" },
        { id: 5, text: "в/управа", type: "leftBucket" },
        { id: 6, text: "в/удача", type: "leftBucket" },
        { id: 7, text: "в/учора", type: "rightBucket" },
        { id: 8, text: "в/утомитися", type: "rightBucket" },
        { id: 9, text: "в/никати", type: "leftBucket" },
        { id: 10, text: "в/учитель", type: "rightBucket" },
      ],
      leftBucketTitle: "Змінюється",
      rightBucketTitle: "Не змінюється",
    },
    {
      id: 8,
      question:
        "8. <b>Змерзнути й замерзнути - одне й те саме?</b>. Перегляньте відео і виконайте завдання.</br> <b>Завдання:<b/> </br> <i> 1. Запишіть два дієслова, про значення яких ішлося у відео, і розберіть їх за будовою.</br> 2. Складіть із кожним дієсловом речення і запишіть.</i>",
      videoPath:
        "https://www.youtube.com/embed/C8_ebkKoz9g?si=sJZxFHwUUIkPBYWU",
      addClass: "horizontal",
      type: "video",
      isTest: true,
      isCreativeTask: true,
    },
  ],
};
