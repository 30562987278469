import {text_mod_74, } from "../text/Text.js";
export const module74 = {
  title:
    "Урок 19. Слова, які відповідають на запитання СКІЛЬКИ?",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_74, },
    ]
  };