import {text_mod_22, } from "../text/Text.js";
export const module22 = {
  title:
    "§22. Перенос слів із буквосполученнями ьо, йо та дж, дз",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_22, },
    ]
  }
