import {text_mod_34, } from "../text/Text.js";
export const module34 = {
  title:
    "§34. Зміна іменників ​за числами ​(один – багато)​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_34, },
    ]
  }
