import {text_mod_42, } from "../text/Text.js";
export const module42 = {
  title:
    "§42. Прикметники, протилежні за значенням",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_42, },
    ]
  }
