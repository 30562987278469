import {text_mod_16, } from "../text/Text.js";
export const module16 = {
  title:
    "Уроки 71-76. Дія множення. Знак множення.​ Перевірка множення додаванням. Складання таблиці множення числа 2. Переставна властивість множення. Розв’язування задач​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_16, },
    ]
  }
