import { text_mod_22, text_mod_23, text_mod_24 } from "../text/Text";

export const module08 = {
  title: "Тема 8. Біблія й українська література. Ліна Костенко «Давидові псалми. Псалом 1»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_22,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_23,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_24,
    },
    {
      id: 4,
      question: "1. Зразком біблійної лірики є",
      options: [
        { id: "А", text: "Новий Заповіт" },
        { id: "Б", text: "Старий Заповіт" },
        { id: "В", text: "Книга псалмів" },
        { id: "Г", text: "Євангелія" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "2. «Давидові псалми» Л. Костенко — це",
      options: [
        { id: "А", text: "перекладом фрагменту Біблії" },
        { id: "Б", text: "переспівом біблейських псалмів" },
        { id: "В", text: "продовженням Біблії" },
        { id: "Г", text: "самостійним авторським твором" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "3. «Давидові псалми» Л. Костенко мають таку назву, тому що",
      options: [
        { id: "А", text: "Давид – головний герой цього ліричного твору" },
        { id: "Б", text: "псалми присвячені ізраїльському цареві Давиду" },
        { id: "В", text: "авторство більшості біблейських псалмів приписують цареві Давидові, а поезія написана за їх мотивами" },
        { id: "Г", text: "поетеса хотіла приховати своє ім’я" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "4. Слово <i>псалом</i> у перекладі з грецької означає",
      options: [
        { id: "А", text: "святий" },
        { id: "Б", text: "радісний" },
        { id: "В", text: "християнство" },
        { id: "Г", text: "піснеспів" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Основним художнім засобом, який використала Л. Костенко у «Давидових псалмах», є",
      options: [
        { id: "А", text: "розширена метафора" },
        { id: "Б", text: "порівняння" },
        { id: "В", text: "антитеза" },
        { id: "Г", text: "епітети" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 9,
      question: "6. Основний мотив «Давидових псалмів. Псалом 1” –",
      options: [
        { id: "А", text: "служіння митця народові." },
        { id: "Б", text: "вибір людиною шляху добра." },
        { id: "В", text: "єднання людини й природи." },
        { id: "Г", text: "пошук цінностей та ідеалів." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },

  ],
};
