
import { text_mod_23_1, text_mod_23_2, text_mod_23_3 } from "../text/Text.js";

export const module23 = {
  title: "Тема 23. Традиції романтизму в українській прозі ХІХ століття. Микола Гоголь «Сорочинський ярмарок»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_23_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_23_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_23_3,
    },
    {
      id: 4,
      question: "1.	«Римачем» називали товариші юного М. Гоголя за вміння",
      options: [
        { id: "A", text: "говорити віршами" },
        { id: "B", text: "складати жартівливі вірші" },
        { id: "C", text: "цитувати поетичні твори" },
        { id: "D", text: "добирати влучні рими" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 5,
      question: "2.	Одним із джерел, використаних автором для написання повісті, були",
      options: [
        { id: "A", text: "народні перекази" },
        { id: "B", text: "літописи" },
        { id: "C", text: "фольклорні дослідження" },
        { id: "D", text: "комедії батька – Василя Гоголя-Яновського" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Визначте, із чим порівнює автор людей, які гомонять одночасно,  на ярмарку.",
      options: [
        { id: "A", text: "піснею" },
        { id: "B", text: "потопом" },
        { id: "C", text: "вітром" },
        { id: "D", text: "пташиним співом" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	«Віз зі знайомими нам пасажирами виїхав у цей час на міст…» — подія на мосту в сюжеті повісті є",
      options: [
        { id: "A", text: "зав'язкою" },
        { id: "B", text: "розвитком дії" },
        { id: "C", text: "кульмінацією" },
        { id: "D", text: "розв'язкою" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Визначте, завдяки якій рисі характеру Параска сподівалася перебороти мачуху.",
      options: [
        { id: "A", text: "сміливість" },
        { id: "B", text: "рішучість" },
        { id: "C", text: "впертість" },
        { id: "D", text: "чемність" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	Наскрізний художній засіб, використаний автором у повісті, —",
      options: [
        { id: "A", text: "метафора" },
        { id: "B", text: "епітет" },
        { id: "C", text: "символ" },
        { id: "D", text: "іронія" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
  ],
};
