import { text_mod_16, text_mod_17, text_mod_18 } from "../text/Text";

export const module06 = {
  title: "Тема 6. Образ Русі в сучасній українській літературі. Раїса Іванченко «Ярославни»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_16,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_17,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_18,
    },
    {
      id: 4,
      question: "1. Із дітьми Ярослава Мудрого прагнули укласти шлюб монархи різних країн, тому що",
      options: [
        { id: "А", text: "князь примушував їх до цього" },
        { id: "Б", text: "таким було передбачення волхвів" },
        { id: "В", text: "князь давав за своїх дітей великий посаг" },
        { id: "Г", text: "Русь була могутньою та багатою державою" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Донька Ярослава Мудрого Єлизавета стала королевою",
      options: [
        { id: "А", text: "Норвегії та Угорщини" },
        { id: "Б", text: "Норвегії та Данії" },
        { id: "В", text: "Данії та Франції" },
        { id: "Г", text: "Франції та Польщі" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "3. Анастасія Ярославна стала королевою",
      options: [
        { id: "А", text: "Польщі" },
        { id: "Б", text: "Угорщини" },
        { id: "В", text: "Німеччини" },
        { id: "Г", text: "Болгарії" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	Про Анну Ярославну НЕ відповідає дійсності твердження",
      options: [
        { id: "А", text: "Після смерті Філіппа І Анна-Агнія стала фактичною правителькою країни." },
        { id: "Б", text: "У Франції, у Ромейському храмі, й досі зберігається Євангеліє, подароване Анною." },
        { id: "В", text: "Папа Микола ІІ дозволив Анні вийти заміж за графа Рауля III Крепі-і-Валуа." },
        { id: "Г", text: "Могила Анни знаходиться у французькому місті Вільєрі." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Визначте, які архітектурні споруди Києва викликають захоплення у варязького посланця Інгвара.",
      options: [
        { id: "А", text: "Золоті ворота та Софійський собор" },
        { id: "Б", text: "Софійський собор та Золоті ворота" },
        { id: "В", text: "Десятинна церква та Софійський собор" },
        { id: "Г", text: "Києво-Печерська лавра та Десятинна церква" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	В оповіданні «Ярославни» згадується видатний твір доби Київської Русі — перша пам’ятка давньоруського ораторського мистецтва",
      options: [
        { id: "А", text: "“Слово о законі і благодаті” Іларіона" },
        { id: "Б", text: "“Повчання дітям” Володимира Мономаха" },
        { id: "В", text: "Біблія" },
        { id: "Г", text: "«Повість минулих літ» Нестора Літописця" },
      ],
      correctAnswer: "А",
      isTest: true,
    },

  ],
};
