import { text_mod_30_1, text_mod_30_2, text_mod_30_3 } from "../text/Text.js";

export const module30 = {
  title: "Тема 30. Михайло Старицький «За двома зайцями»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_30_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_30_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_30_3,
    },
    {
      id: 4,
      question: "1. Усі твердження про «Театр корифеїв» є правильними, ОКРІМ",
      options: [
        { id: "А", text: "У 1882 р. у Єлисаветграді виставою «Наталка Полтавка» було відкрито перший професійний український театр." },
        { id: "Б", text: "Засновником і керівником театру був Марко Кропивницький." },
        { id: "В", text: "Влада забороняла гастролі театру в Європі." },
        { id: "Г", text: "До кола корифеїв увійшли видатні актори: брати й сестра Тобілевичі, Марія Заньковецька." }
      ],
      correctAnswer: "В",
      isTest: true
    },
    {
      id: 5,
      question: "2. П’єса «За двома зайцями» — це переробка твору",
      options: [
        { id: "А", text: "Івана Нечуя-Левицького" },
        { id: "Б", text: "Івана Котляревського" },
        { id: "В", text: "Григорія Квітки-Основ’яненка" },
        { id: "Г", text: "Марка Кропивницького" }
      ],
      correctAnswer: "В",
      isTest: true
    },
    {
      id: 6,
      question: "3. П’єси «За двома зайцями» стосуються всі слова в рядку",
      options: [
        { id: "А", text: "три дії, соціально-побутова комедія, Стецько" },
        { id: "Б", text: "чотири дії, «національний хіт», Галя" },
        { id: "В", text: "пам’ятник на Андріївському узвозі, водевіль" },
        { id: "Г", text: "дві дії, трагікомедія, Проня Прокопівна" }
      ],
      correctAnswer: "Г",
      isTest: true
    },
    {
      id: 7,
      question: "4. Прихід Секлети на весілля в сюжеті п’єси є",
      options: [
        { id: "А", text: "зав’язкою" },
        { id: "Б", text: "розвитком дії" },
        { id: "В", text: "розв’язкою" },
        { id: "Г", text: "кульмінацією" }
      ],
      correctAnswer: "Б",
      isTest: true
    },
    {
      id: 8,
      question: "5. Засобами створення комічного ефекту в п’єсі є всі перелічені, ОКРІМ",
      options: [
        { id: "А", text: "авторських ремарок" },
        { id: "Б", text: "мови персонажів" },
        { id: "В", text: "манер Голохвостого й Проні Прокопіни" },
        { id: "Г", text: "поведінки головних героїв" }
      ],
      correctAnswer: "А",
      isTest: true
    },
    {
      id: 9,
      question: "6. Серед родинних обрядових традицій, зображених у творі, НЕМАЄ",
      options: [
        { id: "А", text: "заручин" },
        { id: "Б", text: "сватання" },
        { id: "В", text: "вінчання" },
        { id: "Г", text: "весілля" }
      ],
      correctAnswer: "В",
      isTest: true
    }    
  ],
};
