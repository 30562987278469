import {
    module01,
    module2,
    module02,
    module3,
    module03,
    module4,
    module5,
    module6,
    module7,
    module8,
    module9,
    module18_19,
    module20_21,
    module23,
    module41,
    module42_43,
    module45,
    module47,
    module48,
    module50,
    module52_53,
    module54_55,
    module57,
    module59_60,
    module62_63,
    module65_66,
    module69,
    module70_71,
    module73,
    module74_75,
    module77,
    module78_79,
    module80,
    module81,
    module82
} from "./modules.js";

export const routes = [
    { path: "/class/8/ukrmova/avr/modules/module01", module: module01 },
    { path: "/class/8/ukrmova/avr/modules/module2", module: module2 },
    { path: "/class/8/ukrmova/avr/modules/module02", module: module02 },
    { path: "/class/8/ukrmova/avr/modules/module3", module: module3 },
    { path: "/class/8/ukrmova/avr/modules/module03", module: module03 },
    { path: "/class/8/ukrmova/avr/modules/module4", module: module4 },
    { path: "/class/8/ukrmova/avr/modules/module5", module: module5 },
    { path: "/class/8/ukrmova/avr/modules/module6", module: module6 },
    { path: "/class/8/ukrmova/avr/modules/module7", module: module7 },
    { path: "/class/8/ukrmova/avr/modules/module8", module: module8 },
    { path: "/class/8/ukrmova/avr/modules/module9", module: module9 },
    { path: "/class/8/ukrmova/avr/modules/module18_19", module: module18_19 },
    { path: "/class/8/ukrmova/avr/modules/module20_21", module: module20_21 },
    { path: "/class/8/ukrmova/avr/modules/module23", module: module23 },
    { path: "/class/8/ukrmova/avr/modules/module41", module: module41 },
    { path: "/class/8/ukrmova/avr/modules/module42_43", module: module42_43 },
    { path: "/class/8/ukrmova/avr/modules/module45", module: module45 },
    { path: "/class/8/ukrmova/avr/modules/module47", module: module47 },
    { path: "/class/8/ukrmova/avr/modules/module48", module: module48 },
    { path: "/class/8/ukrmova/avr/modules/module50", module: module50 },
    { path: "/class/8/ukrmova/avr/modules/module52_53", module: module52_53 },
    { path: "/class/8/ukrmova/avr/modules/module54_55", module: module54_55 },
    { path: "/class/8/ukrmova/avr/modules/module57", module: module57 },
    { path: "/class/8/ukrmova/avr/modules/module59_60", module: module59_60 },
    { path: "/class/8/ukrmova/avr/modules/module62_63", module: module62_63 },
    { path: "/class/8/ukrmova/avr/modules/module65_66", module: module65_66 },
    { path: "/class/8/ukrmova/avr/modules/module69", module: module69 },
    { path: "/class/8/ukrmova/avr/modules/module70_71", module: module70_71 },
    { path: "/class/8/ukrmova/avr/modules/module73", module: module73 },
    { path: "/class/8/ukrmova/avr/modules/module74_75", module: module74_75 },
    { path: "/class/8/ukrmova/avr/modules/module77", module: module77 },
    { path: "/class/8/ukrmova/avr/modules/module78_79", module: module78_79 },
    { path: "/class/8/ukrmova/avr/modules/module80", module: module80 },
    { path: "/class/8/ukrmova/avr/modules/module81", module: module81 },
    { path: "/class/8/ukrmova/avr/modules/module82", module: module82 },
];