import {text_mod_2, } from "../text/Text.js";
export const module02 = {
  title:
    "Уроки 5-7. Задачі на знаходження невідомого доданка. Компоненти під час виконання віднімання. Знаходження невідомого від’ємника. Одиниці вимірювання величин​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_2, },
    ]
  }
