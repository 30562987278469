import React from "react";
import { jsPDF } from "jspdf";
import "./DejaVuSans-normal.js";

import { modulesData } from '../author_page/modulesData';


function ExportPdfButton({ module }) {
    function findTitleFromURL(modulesData) {
        const currentURL = window.location.pathname;
        const parts = currentURL.split('/');
        return parts[6];
    }

    function stripHtmlTags(text) {
        const withoutTags = text.replace(/<\/?[^>]+(>|$)/g, "");
        return withoutTags.replace(/\s{2,}/g, " ").trim();
    }



    const generatePdf = () => {
        const doc = new jsPDF();
        doc.setFont("DejaVuSans", "normal");

        const pageWidth = 180;
        const marginY = 20;
        let currentY = marginY;

        doc.setFontSize(16);
        const cleanTitle = stripHtmlTags(module.title); // Удаляем HTML-теги из заголовка
        const wrappedTitle = doc.splitTextToSize(cleanTitle, pageWidth);
        const titleHeight = wrappedTitle.length * 10;
        doc.text(wrappedTitle, 10, currentY);
        currentY += titleHeight;

        const addNewPageIfNeeded = (additionalHeight) => {
            if (currentY + additionalHeight > doc.internal.pageSize.height - marginY) {
                doc.addPage();
                currentY = marginY;
            }
        };

        module.questions
            .filter((question) => question.type === "test")
            .forEach((question, index) => {
                const tableStartY = currentY;

                doc.setFontSize(14);
                const questionText = `${question.question}`;
                const cleanQuestionText = stripHtmlTags(questionText); // Удаляем HTML-теги из текста вопроса
                const wrappedQuestion = doc.splitTextToSize(cleanQuestionText, pageWidth - 20);
                const questionHeight = wrappedQuestion.length * 5;

                addNewPageIfNeeded(questionHeight + 20);

                doc.setFillColor(0, 102, 204);
                doc.rect(10, currentY, pageWidth, questionHeight + 9.5, "F");
                doc.setTextColor(255, 255, 255);
                doc.text(wrappedQuestion, 15, currentY + 10);
                currentY += questionHeight + 10;

                const tableHeaderHeight = 10;
                const rowHeight = 10;

                // Table Rows with Styling
                question.options.forEach((option, optionIndex) => {
                    const optionText = `${option.text}`;
                    const cleanOptionText = stripHtmlTags(optionText); // Удаляем HTML-теги из текста опций
                    const wrappedOption = doc.splitTextToSize(cleanOptionText, pageWidth - 30);
                    const optionHeight = wrappedOption.length * rowHeight;
                    addNewPageIfNeeded(optionHeight);

                    if (optionIndex % 2 === 0) {
                        doc.setFillColor(220, 240, 255);
                    } else {
                        doc.setFillColor(255, 255, 255);
                    }

                    doc.rect(10, currentY, 20, optionHeight, "F"); // Column for option ID
                    doc.rect(30, currentY, pageWidth - 30, optionHeight, "F"); // Column for option text

                    doc.setTextColor(0, 0, 0);
                    doc.text(option.id, 15, currentY + 7);
                    doc.text(wrappedOption, 35, currentY + 7);

                    currentY += optionHeight;
                });

                currentY += 10;
            });

        savePDF(modulesData, doc);
    };

    function savePDF(modulesData, doc) {
        const title = findTitleFromURL(modulesData);
        const sanitizedTitle = title ? title.replace(/\s+/g, '_') : 'default';
        doc.save(sanitizedTitle + ".pdf");
    }

    return (
        <button
            onClick={generatePdf}
            style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
            }}
        >
            Експортувати в PDF
        </button>
    );
}

export default ExportPdfButton;
