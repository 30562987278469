export const module03 = {
  title:
    "§ 4-5. Русь, її культура та письменство. Старина про Іллю Муромця та Солов’я.",
  questions: [
    {
      id: 1,
      question: "1. Русь як держава сформувалася в",
      options: [
        { id: "А", text: "V ст." },
        { id: "Б", text: "VII ст." },
        { id: "В", text: "ІХ ст." },
        { id: "Г", text: "ХІІІ ст." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. НЕПРАВИЛЬНИМ є твердження",
      options: [
        {
          id: "А",
          text: "давньоруська культура орієнтувалася у своєму розвитку на середньовічну Візантію",
        },
        {
          id: "Б",
          text: "у Русі розвинулася писемність, запроваджена Кирилом та Мефодієм",
        },
        {
          id: "В",
          text: "у Києво-Печерській лаврі створено літопис «Повість минулих літ»",
        },
        {
          id: "Г",
          text: "літопис «Повість минулих літ» упорядкував князь Володимир",
        },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Билину як жанр також називають",
      options: [
        { id: "А", text: "стариною" },
        { id: "Б", text: "баладою" },
        { id: "В", text: "піснею" },
        { id: "Г", text: "думою" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question:
        "4. У билині Ілля Муромець повідомляє батькам, що збирається до міста",
      options: [
        { id: "А", text: "Муромля" },
        { id: "Б", text: "Чернігова" },
        { id: "В", text: "Новгорода" },
        { id: "Г", text: "Києва" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. НЕПРАВИЛЬНИМ є твердження",
      options: [
        { id: "А", text: "головний герой билин – богатир" },
        { id: "Б", text: "Ілля Муромець жив у ХІІ – ХІІІ ст." },
        { id: "В", text: "співці-музиканти виконують билину речитативом" },
        { id: "Г", text: "в описі вчинків Іллі Муромця використано літоту" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Розподіліть учинки між героями билини. </br><b><i style='text-align: center; display: block;'>Учинки:</i></b>",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "«крикнув на повний крик»", type: "rightBucket" },
        { id: 2, text: "«меча в руки брав»", type: "leftBucket" },
        { id: 3, text: "«посвиснув на повний свист»", type: "rightBucket" },
        {
          id: 4,
          text: "«не стільки сам бив, скільки конем топтав»",
          type: "leftBucket",
        },
        { id: 5, text: "«трьох царевичів живими впіймав»", type: "leftBucket" },
        { id: 6, text: "«зашипів на повний шип»", type: "rightBucket" },
        { id: 7, text: "«натягнув тятиву шовкову»", type: "leftBucket" },
        { id: 8, text: "«відрубав буйну голову»", type: "leftBucket" },
        { id: 9, text: "«випив чару зелен-вина»", type: "rightBucket" },
      ],
      leftBucketTitle: "Ілля Муромець",
      rightBucketTitle: "Соловей-Розбійник",
    },
    {
      id: 7,
      question:
        "7. Перегляньте відеоматеріал телеканалу «СТБ» «Ілля Муромець. Українська історія міфічного богатиря» (27 хв 55 с). Прорекламуйте це відео в соцмережах, зробивши відповідний допис і використавши в ньому найцікавіші факти з побаченого / почутого. ",
      videoPath:
        "https://www.youtube.com/embed/JFoiPPqMGVc?si=MzciMT-rQ21dxyIS",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
