import {text_mod_26, } from "../text/Text.js";
export const module26 = {
  title:
    "§26. Слово. Лексичне значення слова",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_26, },
    ]
  }
