import {text_mod_3, } from "../text/Text.js";
export const module03 = {
  title:
    "§3. Голосні звуки. ​Букви, що їх позначають",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_3, },
    ]
  }
