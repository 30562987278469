import {text_mod_20, } from "../text/Text.js";
export const module20 = {
  title:
    "§20. Мої навчальні досягнення",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_20, },
    ]
  }
