import { text_mod_34, text_mod_35, text_mod_36 } from "../text/Text";

export const module12 = {
  title: "Тема 12. Українські народні історичні пісні. Пісні про боротьбу українського народу за свободу та державність. «Ой як крикнув же козак Сірко»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_34,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_35,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_36,
    },
    {
      id: 4,
      question: "1. Спорідненість історичних пісень з думами прослідковується у",
      options: [
        { id: "А", text: "манері виконання" },
        { id: "Б", text: "композиції творів" },
        { id: "В", text: "тематиці й персонажах" },
        { id: "Г", text: "формі" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Українські народні історичні пісні  споріднені  з такою тематичною групою народних пісень",
      options: [
        { id: "А", text: "календарно-обрядовими" },
        { id: "Б", text: "старинами" },
        { id: "В", text: "соціально-побутовими" },
        { id: "Г", text: "піснями літературного походження" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "3. Серед тематичних груп історичних пісень НЕ БУЛО творів",
      options: [
        { id: "А", text: "про боротьбу з татаро-турецькими нападниками" },
        { id: "Б", text: "про міжусобні чвари князів і бояр" },
        { id: "В", text: "про боротьбу з польськими загарбниками в ХVІІ ст." },
        { id: "Г", text: "про народний опір російському самодержавству в ХVІІІ-ХІХ ст." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	У поданих рядках «Гей, та ми думали, та ми ж думали,  / Що то орли та із Січі вилітали, — / Аж то військо та славне запорозьке  / Та на Кримський шлях з Січі виїжджало»  використано художні засоби",
      options: [
        { id: "А", text: "постійні епітети, метафори, повтори" },
        { id: "Б", text: "повтори, пестливі слова, епітети" },
        { id: "В", text: "паралелізм, постійні епітети, повтори" },
        { id: "Г", text: "метафори, повтори, паралелізм" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	НЕ відповідає дійсності   твердження про Івана Сірка",
      options: [
        { id: "А", text: "Дванадцять разів запорожці обирали Сірка гетьманом." },
        { id: "Б", text: "Сучасники вважали Сірка характерником." },
        { id: "В", text: "З-понад шістдесяти проведених ним битв Іван Сірко не програв жодної." },
        { id: "Г", text: "Іван Сірко брав участь у Визвольній війні під проводом Богдана Хмельницького." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	У пісні «Та, ой, як крикнув же та козак Сірко» ідеться про",
      options: [
        { id: "А", text: "перемогу запорожців над татарами" },
        { id: "Б", text: "отаманські здібності Івана Сірка" },
        { id: "В", text: "початок одного з переможних походів запорозького війська під керівництвом Івана Сірка" },
        { id: "Г", text: "спротив соціальному гніту" },
      ],
      correctAnswer: "В",
      isTest: true,
    },

  ],
};
