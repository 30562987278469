import {text_mod_18, } from "../text/Text.js";
export const module18 = {
  title:
    "Урок 18, 19. Ділення однакових чисел. Розв'язування задач. ​Одиниці довжини та співвідношення між ними.​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_18, },
    ]
  }
