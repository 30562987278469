import {text_mod_33, } from "../text/Text.js";
export const module33 = {
  title:
    "Урок 12. Літера Ж велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_33, },
    ]
  };