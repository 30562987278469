
export const module29_31 = {
  title: "§ 29-31. Романтична й реалістична проза в українській літературі ХІХ ст. Риси романтичного стилю в повісті Миколи Гоголя «Сорочинський ярмарок»",
  questions: [
    {
      id: 1,
      question: "1. Окрім Миколи Гоголя, у романтичному ключі писав твори й",
      options: [
        { id: "А", text: "Пантелеймон Куліш" },
        { id: "Б", text: "Іван Нечуй-Левицький" },
        { id: "В", text: "Панас Мирний" },
        { id: "Г", text: "Борис Грінченко" }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Позасюжетним елементом повісті «Сорочинський ярмарок» є уривок",
      options: [
        { id: "А", text: "Які млосногарячі ті години, коли полудень сяє серед тиші й спеки, і блакитний, незмірний океан, жагучим куполом схилившись над землею, здається, заснув, весь потонувши в млості, пригортаючи й стискаючи прекрасну в ніжних обіймах своїх!" },
        { id: "Б", text: "Віз саме почав з'їжджати з мосту, і останніх слів уже не можна було почути; та парубок не хотів, здається, закінчити на цьому: не думавши довго, схопив він жменю грязюки й жбурнув услід їй." },
        { id: "В", text: "Озирнулась — аж парубок у білій свитці, з ясними очима, стояв перед нею. Жилки її здригнулись і серце забилося так, як ще ніколи, ні з якої радості, ні з якого горя: і дивно, і любо їй здалося, і сама не могла розібрати, що робилося з нею." },
        { id: "Г", text: "Так грізна жінка Черевикова ласкаво підбадьорювала поповича, що боязко тулився коло тину; той виліз хутко на тин та довго стояв, вагаючись, на ньому, ніби висока страшна примара, націлюючись оком, куди б краще стрибнути, і, нарешті, з шумом кинувся в бур'ян." }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. У реченні «Сірі скирти сіна й золоті снопи хліба табором розташовуються в полі й кочують по його безкрайності» НЕМАЄ",
      options: [
        { id: "А", text: "епітета" },
        { id: "Б", text: "гіперболи" },
        { id: "В", text: "паралелізму" },
        { id: "Г", text: "персоніфікації" }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Твір «Сорочинський ярмарок» за родовою належністю",
      options: [
        { id: "А", text: "епічний" },
        { id: "Б", text: "ліричний" },
        { id: "В", text: "драматичний" },
        { id: "Г", text: "ліро-епічний" }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. У повісті «Сорочинський ярмарок» є всі літературні герої (героїні), ОКРІМ",
      options: [
        { id: "А", text: "Солопія Черевика" },
        { id: "Б", text: "Тараса Бульби" },
        { id: "В", text: "Параски" },
        { id: "Г", text: "Хіврі" }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Упізнайте літературного героя (героїню) за словами, які йому (їй) належать.",
      dropdownQuestionParts: [
        "<i> <b>Слова літературного героя (героїні)</b> </br> 1) Я віддав би все своє господарство, щоб поцілувати її. А он спереду й диявол сидить! ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) Ну, Афанасію Івановичу, ми попалися з вами: народу стукається купа, і мені вчувся кумів голос… ",
        { id: "dropdown2", text: " - " },
        "<br /> <i> 3) А продаси воли за двадцять, якщо ми заставимо Черевика віддати нам Параску? ",
        { id: "dropdown3", text: " - " },
        "<br /> <i> 4) Як це можна, щоб чорта впустив хто-небудь у шинок: адже в нього ж є, хвалити бога, і кігті на лапах, і ріжки на голові ",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>"
      ],
      options: [
        { id: "А", text: "Солопій" },
        { id: "Б", text: "Параска" },
        { id: "В", text: "Грицько" },
        { id: "Г", text: "Хівря" },
        { id: "Д", text: "циган" }
      ],
      correctAnswers: {
        dropdown1: "В",
        dropdown2: "Г",
        dropdown3: "Д",
        dropdown4: "А"
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        "7. Уважно перегляньте відео про Миколу Гоголя (2 хв 04 с), для перевірки здобутих знань візьміть участь у грі «Так – ні» (у наступному завданні).",
      videoPath:
        "https://www.youtube.com/embed/1302Xi1q_ms?si=sENiDB9UXfbo1scm",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      shouldGoNext: false,
      type: 'video',
    },
    {
      id: 8,
      question: '8. Гра «Так – ні»: <i><b>Розподіліть твердження за відповідями «Так» та «Ні».</b></i> <br>(Перетягніть квадратик з номером твердження у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "У дитинстві Микола Гоголь спілкувався лише українською мовою.", type: "leftBucket" },
        { id: 2, text: "Письменник друкував книжки російською мовою на прохання царя Петра Першого.", type: "rightBucket" },
        { id: 3, text: "Гоголь видав дві книжки оповідань і повістей, події яких відбуваються в Україні.", type: "rightBucket" },
        { id: 4, text: "Микола Гоголь відмовився від посади доцента Київського університету, бо він мріяв лише про посаду професора.", type: "rightBucket" },
        { id: 5, text: "Письменник переробив повість «Тарас Бульба», оскільки царська цензура вважала її занадто українською.", type: "leftBucket" },
        { id: 6, text: "Другий том «Мертвих душ» Микола Гоголь тричі спалював, оскільки його душа не сприймала неправдивих персонажів.", type: "leftBucket" },
        { id: 7, text: "Узяти до свого серця дух і силу землі, на якій зріс, автор зміг лише у «Вечорах на хуторі біля Диканьки» та в «Тарасові Бульбі».", type: "leftBucket" },
        { id: 8, text: "Словами «Він наш, він не їхній, правда ж?» Микола Гоголь характеризував рідний край – Полтавщину.", type: "rightBucket" }
      ],
      leftBucketTitle: "ТАК",
      rightBucketTitle: "НІ"
    },
  ],
};
