import {text_mod_54, } from "../text/Text.js";
export const module54 = {
  title:
    "Урок 33. Апостроф",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_54, },
    ]
  };