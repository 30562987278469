

import { text_mod_1, text_mod_2, text_mod_3 } from "../text/Text.js";

export const module01 = {
  title: "Тема 1. Художній твір в історико-культурному контексті доби",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_1,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_2,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_3,
    },

    {
      id: 4,
      question: "1. Найзагальніші традиції, погляди, уподобання певної доби, якими вона відрізняється від інших епох, становлять",
      options: [
        { id: "А", text: "літературу певного періоду" },
        { id: "Б", text: "стиль доби" },
        { id: "В", text: "мистецтво" },
        { id: "Г", text: "фольклор" },

      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "2. Стиль певної доби найвиразніше виявляється в",
      options: [
        { id: "А", text: "філософії" },
        { id: "Б", text: "мистецтві" },
        { id: "В", text: "науці" },
        { id: "Г", text: "побуті" },
      ],

      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "3. Об’єднання митців, які мають споріднені ознаки стилів і творять у межах спільної доби та нації, утворює",
      options: [
        { id: "А", text: "мистецький напрям" },
        { id: "Б", text: "авторський стиль" },
        { id: "В", text: "мистецьку течію" },
        { id: "Г", text: "мистецькі паралелі" },

      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 7,
      question: "4. Об’єднання митців за спорідненими творчими принципами утворює",
      options: [
        { id: "А", text: "мистецький напрям" },
        { id: "Б", text: "авторський стиль" },
        { id: "В", text: "мистецьку течію" },
        { id: "Г", text: "мистецькі паралелі" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 8,
      question: "5. Авторський стиль відіграє головну роль у визначенні",
      options: [
        { id: "А", text: "популярності твору" },
        { id: "Б", text: "художньої своєрідності твору" },
        { id: "В", text: "теми твору" },
        { id: "Г", text: "проблематики твору" },

      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 9,
      question: "6. Мистецький напрям, що нині утверджується, — це",
      options: [
        { id: "А", text: "модернізм" },
        { id: "Б", text: "постмодернізм" },
        { id: "В", text: "метамодернізм" },
        { id: "Г", text: "реалізм" },

      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },


  ],
};