import {text_mod_62, } from "../text/Text.js";
export const module62 = {
  title:
    "§62. Створюю вітальну листівку для своєї мами",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_62, },
    ]
  }
