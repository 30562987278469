import {text_mod_25, } from "../text/Text.js";
export const module25 = {
  title:
    "§25. Мої навчальні досягненн. Для кмітливих і допитливих",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_25, },
    ]
  }
