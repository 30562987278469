export const module10 = {
  title: "Інформація",
  questions: [
    {
      id: 1,
      question: "1. Визначте, які складники є важливими для розуміння прихованого змісту інформації.",
      options: [
        { id: "А", text: "багатий словник" },
        { id: "Б", text: "уміння малювати" },
        { id: "В", text: "уміння писати есе" },
        { id: "Г", text: "знання іноземних мов" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Прикладом явного змісту інформації може / можуть бути",
      options: [
        { id: "А", text: "сигнали, що повідомляють про приховану інформацію" },
        { id: "Б", text: "текст, зміст якого очевидний" },
        { id: "В", text: "символи, що мають багато значень" },
        { id: "Г", text: "метафори в художньому творі" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Прикладом прихованої інформації є",
      options: [
        { id: "А", text: "чітке пояснення правил" },
        { id: "Б", text: "зображення, що викликає емоції" },
        { id: "В", text: "пряме запитання" },
        { id: "Г", text: "оголошення про подію" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Допомагає в аналізі прихованої інформації",
      options: [
        { id: "А", text: "творча активність" },
        { id: "Б", text: "вміння малювати" },
        { id: "В", text: "знання історії" },
        { id: "Г", text: "вміння співати" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Сигналом для розпізнавання прихованої інформації є",
      options: [
        { id: "А", text: "структура тексту" },
        { id: "Б", text: "вставні слова" },
        { id: "В", text: "художні засоби" },
        { id: "Г", text: "граматичні правила" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
  ],
};
