export const module7 = {
  title:
    '§ 7. Написання <span class="text-wow">НЕ</span> з різними частинами мови',
  questions: [
    {
      id: 1,
      question:
        '1. <span class="text-wow">&nbsp; НE &nbsp;</span> потрібно писати разом з обома іменниками у варіанті',
      options: [
        { id: "А", text: "не/года, не/помічник" },
        { id: "Б", text: "не/воля (полон), не/родич" },
        { id: "В", text: "не/здара, не/телефон" },
        { id: "Г", text: "не/дбальство, не/мовля" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question:
        '2. <span class="tekst-wow">&nbsp; НE &nbsp;</span> потрібно писати разом з прикметником у варіанті',
      options: [
        { id: "А", text: "турнір не/складний" },
        { id: "Б", text: "турнір зовсім не/складний" },
        { id: "В", text: "не/складний турнір" },
        { id: "Г", text: "турнір не/складний, а простий" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question:
        '3. <span class="text-wow">&nbsp; НE &nbsp;</span> потрібно писати окремо з дієсловом у варіанті',
      options: [
        { id: "А", text: "не/запізнюватися" },
        { id: "Б", text: "не/терпеливитися" },
        { id: "В", text: "не/славити (ганьбити)" },
        { id: "Г", text: "не/доїдати (голодувати)" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question:
        '4. НЕПРАВИЛЬНО написано <span class="text-wow">&nbsp; НE &nbsp;</span> зі словом у реченні',
      options: [
        {
          id: "А",
          text: 'Перси <span class="text-wow">&nbsp;не чекали&nbsp;</span> наведення мосту й долали річку',
        },
        {
          id: "Б",
          text: 'Тут уже навіть Брякус став <span class="text-wow">&nbsp;не покоїтися</span>.',
        },
        {
          id: "В",
          text: 'У дно <span class="text-wow">&nbsp;неглибокої &nbsp;</span> річки було вгороджено соснові стовбури.',
        },
        {
          id: "Г",
          text: 'Селами <span class="text-wow">&nbsp;не вгавала&nbsp;</span> чарівна українська пісня.',
        },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question:
        '5. Частку <span class="text-wow">НЕ&nbsp;</span> треба писати разом зі словами, позначеними цифрами',
      dropdownQuestionParts: [
        { id: "dropdown1", text: "Не/могла " },
        " натішитися пані Оксана, що Андрійко <br />вже ",
        { id: "dropdown2", text: "не/абихто " },
        " , а молодший науковий співробітник їхнього закладу, адже ніколи  ",
        { id: "dropdown3", text: "не/зволікав " },
        " із запланованими дослідженнями, завжди ",
        { id: "dropdown4", text: "не/втомно " },
        "їх проводив,<br /> ",
        { id: "dropdown5", text: "не/хтуючи " },
        " труднощами, що постійно виникали.",
      ],
      options: [
        { id: "Разом", text: "Разом" },
        { id: "Окремо", text: "Окремо" },
      ],
      correctAnswers: {
        dropdown1: "Окремо",
        dropdown2: "Разом",
        dropdown3: "Окремо",
        dropdown4: "Разом",
        dropdown5: "Разом",
      },
      dropdownAmount: 5,
      isTest: false,
      showAnswerOptions: false,
      type: 'interactive',
    },
    {
      id: 6,
      question:
        "6. До кожного правила написання слів доберіть відповідний приклад.",
      dropdownQuestionParts: [
        '1) При <span class="text-wow">НЕ&nbsp;</span> є пояснювальне слово ',
        { id: "dropdown1", text: "" },
        '<br /> 2) Слово з <span class="text-wow">НЕ&nbsp;</span> є присудком ',
        { id: "dropdown2", text: "" },
        '<br />3) Слово з <span class="text-wow">НЕ&nbsp;</span> позначає єдине поняття ',
        { id: "dropdown3", text: "" },
        '<br />4) Без <span class="text-wow">НЕ&nbsp;</span> слово не вживаємо ',
        { id: "dropdown4", text: "" },
      ],
      options: [
        { id: "недбалий, невтомно", text: "недбалий, невтомно" },
        { id: "не доїв, не долетів", text: "не доїв, не долетів" },
        { id: "неволя, невисокий", text: "неволя, невисокий" },
        { id: "ніким не зібране листя", text: "ніким не зібране листя" },
        { id: "вірш не вивчений", text: "вірш не вивчений" },
      ],
      correctAnswers: {
        dropdown1: "ніким не зібране листя",
        dropdown2: "вірш не вивчений",
        dropdown3: "неволя, невисокий",
        dropdown4: "недбалий, невтомно",
      },
      dropdownAmount: 4,
      isTest: false,
      showAnswerOptions: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        "Розподіліть слова за написанням з НЕ у дві чарунки: 1) Разом; 2) Окремо.",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Не/п’ять", type: "rightBucket" },
        { id: 2, text: "не/ук", type: "leftBucket" },
        { id: 3, text: "не/робство", type: "leftBucket" },
        { id: 4, text: "не/сонячний, а похмурий", type: "rightBucket" },
        { id: 5, text: "не/довіра", type: "leftBucket" },
        { id: 6, text: "не/здужати (не змогти)", type: "rightBucket" },
        { id: 7, text: "не/гайний", type: "leftBucket" },
        { id: 8, text: "не/добачати (не помічати)", type: "rightBucket" },
        { id: 9, text: "ще не/дописаний підручник", type: "rightBucket" },
        { id: 10, text: "не/щадно", type: "leftBucket" },
        { id: 11, text: "не/абиякий (значний)", type: "leftBucket" },
        { id: 12, text: "не/сьогодні", type: "rightBucket" },
      ],
      leftBucketTitle: "Разом",
      rightBucketTitle: "Окремо",
    },
    {
      id: 8,
      question:
        "Розподіліть слова за написанням з НЕ у дві чарунки: 1) Разом; 2) Окремо.",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 13, text: "не/зчутися", type: "leftBucket" },
        { id: 14, text: "не/широкий (вузький)", type: "leftBucket" },
        { id: 15, text: "не/школа", type: "rightBucket" },
        { id: 16, text: "не/треба", type: "rightBucket" },
        { id: 17, text: "не/здужати (хворіти)", type: "leftBucket" },
        { id: 18, text: "не/можна", type: "rightBucket" },
        { id: 19, text: "досі не/переписана вправа", type: "rightBucket" },
        { id: 20, text: "не/добачати (постійно)", type: "leftBucket" },
        { id: 21, text: "не/притомніти", type: "leftBucket" },
        { id: 22, text: "не/тільки", type: "rightBucket" },
        {
          id: 23,
          text: "не/покоїтися (не бути похованим)",
          type: "rightBucket",
        },
        { id: 23, text: "не/покоїтися (не бути похованим)", type: "rightBucket" },
        { id: 24, text: "не/покоїтися (хвилюватися)", type: "leftBucket" },
      ],
      leftBucketTitle: "Разом",
      rightBucketTitle: "Окремо",
    },
    {
      id: 9,
      question:
        "9. <b>Ви добре виглядаєте – чи правильно так сказати?</b>. Перегляньте експрес-урок за покликанням і виконайте завдання.</br> <b>Завдання:<b/> </br> <i> 1. Складіть зі словом та словосполученням, про значення яких ішлося у відео, речення (письмово).</br> 2. Запишіть згадані у відео фразеологізми й прочитайте їх вдома родичам.</i>",
      videoPath:
        "https://www.youtube.com/embed/W58EqHqlRs0?si=fYZEvtLCDR0aCdFB",
      addClass: "horizontal",
      type: "video",
      isTest: true,
      isCreativeTask: true,
    },
  ],
};
