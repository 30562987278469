import {text_mod_30, } from "../text/Text.js";
export const module30 = {
  title:
    "Урок 9. Літера х маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_30, },
    ]
  };