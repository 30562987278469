import {text_mod_50, } from "../text/Text.js";
export const module50 = {
  title:
    "§50. Створюю та записую речення, використовуючи малюнки й початок казки І.Савки «Обшивайко»",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_50, },
    ]
  }
