export const module09 = {
  title: "Поняття про відокремлення. Відокремлення узгоджених означень. Цілюща сила та привабливість природи",
  questions: [
    {
      id: 1,
      question: "1. Відокремлене означення, що має додатковий обставинний відтінок причини / умови, перед означуваним словом подано в рядку",
      options: [
        { id: "А", text: "Зелені, пишні дерева шелестіли на вітрі." },
        { id: "Б", text: "Стомлені довгою подорожжю, туристи зупинилися біля гірського озера." },
        { id: "В", text: "Квіти, политі ранковою росою, яскраво сяяли на сонці." },
        { id: "Г", text: "Високі гори, укриті снігом, величаво здіймалися над долиною." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Правильно розставлено розділові знаки при однорідних обставинах у реченні",
      options: [
        { id: "А", text: "Мандрівники йшли, через джунглі обережно й повільно." },
        { id: "Б", text: "Учені спостерігали за вулканом, як удень так і вночі." },
        { id: "В", text: "Дослідники вивчали флору та фауну Галапагоських островів швидко, але ретельно." },
        { id: "Г", text: "Альпіністи піднімалися на Еверест, важко дихаючи й долаючи сильний вітер." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Речення Заходить ніч … буде ускладненим відокремленою обставиною, якщо його продовжити фрагментом",
      options: [
        { id: "А", text: "ясними мерехтливими зорями гаптуючи небо." },
        { id: "Б", text: "і відразу стає темно." },
        { id: "В", text: "і всі ідуть відпочивати." },
        { id: "Г", text: "освітлена зірками та сповнена п’янкими ароматами." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Однорідні обставини з’єднані парним сполучником у реченні",
      options: [
        { id: "А", text: "Науковці досліджували океанічне дно як за допомогою роботів, так і за допомогою підводних човнів." },
        { id: "Б", text: "Мандрівники пересувалися то пішки, то на джипах по африканській савані." },
        { id: "В", text: "Експедиція вивчала клімат Антарктиди влітку та взимку." },
        { id: "Г", text: "Географи досліджували ландшафт не лише візуально, але й за допомогою спеціального обладнання." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Пунктуаційну помилку при відокремленні означення допущено в реченні",
      options: [
        { id: "А", text: "Оповиті легким туманом, гори здавалися казковими." },
        { id: "Б", text: "Утомлені, але щасливі ми поверталися з походу." },
        { id: "В", text: "Густа трава, посріблена росою блищала під сонцем." },
        { id: "Г", text: "Зачудовані красою природи, діти притихли." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Граматичну помилку в уживанні дієприслівникового звороту допущено в реченні",
      options: [
        { id: "А", text: "Виконуючи домашнє завдання, я повторюю вивчене на уроці." },
        { id: "Б", text: "Виконуючи домашнє завдання, мені необхідно повторити вивчене на уроці." },
        { id: "В", text: "Уважно гортаючи сторінки підручника, повторюю матеріал." },
        { id: "Г", text: "Виконавши домашнє завдання, можу відпочити." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
  ],
};
