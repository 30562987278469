import {text_mod_38, } from "../text/Text.js";
export const module38 = {
  title:
    "§38. Велика буква в назвах  вулиць, міст, сіл, річок, гір​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_38, },
    ]
  }
