export const module11 = {
  title: "§ 19-20. Доба козацької України в сучасній літературі",
  questions: [
    {
      id: 1,
      question: "1. НЕПРАВИЛЬНИМ є твердження",
      options: [
        {
          id: "А",
          text: "Марія Морозенко – авторка твору «Іван Сірко – великий характерник»",
        },
        { id: "Б", text: "за жанровими ознаками цей твір – історичне фентезі" },
        { id: "В", text: "текст твору поділено на глави, що мають заголовки" },
        { id: "Г", text: "за родовою ознакою цей твір ліро-епічний" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Події твору відбуваються у",
      options: [
        { id: "А", text: "ІХ ст." },
        { id: "Б", text: "ХІІ ст." },
        { id: "В", text: "ХІV ст." },
        { id: "Г", text: "ХVІІ ст." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Іван народився у Мерефі, що",
      options: [
        { id: "А", text: "в Олешківському краю" },
        { id: "Б", text: "на Харківщині" },
        { id: "В", text: "на Запоріжжі" },
        { id: "Г", text: "у Криму" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Кульмінаційним у повісті є епізод, у якому Іван",
      options: [
        { id: "А", text: "прибуває із Сірим на Січ" },
        { id: "Б", text: "навчається в школі характерництва" },
        { id: "В", text: "шукає в Олешківській пустелі секрет безсмертя" },
        { id: "Г", text: "одружується із Софією" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Позасюжетним елементом повісті є фрагмент",
      options: [
        {
          id: "А",
          text: "<b><i>«Люди мовчали, спантеличені силою мовленої правди. Мовчав і дяк, забувши геть начисто «проповідь», яку спробував укласти по дорозі сюди».</b></i>",
        },
        {
          id: "Б",
          text: "<b><i>«Хлопець схопив руками вовка за щелепу, переборюючи страх і різкий біль, із силою її розтягнув, силкуючись роздерти. Звір подався назад, залишивши на його руках кров’яні пасмуги на шкірі»</b></i>.",
        },
        {
          id: "В",
          text: "<b><i>«Дерев’яні стіни хати з грубих, аж почорнілих від часу тесаних колод, щільно порослих мохом, нависали над ним. Поміж павутинням, від долівки й аж до сволоку, звисали яскраві грона щільно заплетених кісників різнотрав’я».</b></i>",
        },
        {
          id: "Г",
          text: "<b><i>«Козаки перезирнулися між собою. У їхніх очах миготіли веселі іскринки сміху. Козацтво потішав цей хлопчисько, який сміливо відстоював своє. Хай, може, трохи й прибріхував. Але ж дитина, що не кажи, геройська».</b></i>",
        },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Розподіліть відомості, що пов’язані і не пов’язані з твором Марії Морозенко «Іван Сірко – великий характерник».</br><b><i style='text-align: center; display: block;'>Відомості: </i></b>",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Дана", type: "leftBucket" },
        { id: 2, text: "Михайло Діброва", type: "leftBucket" },
        { id: 3, text: "цар Дар’явауш", type: "rightBucket" },
        { id: 4, text: "Івана Купала", type: "leftBucket" },
        { id: 5, text: "цвіт папороті", type: "leftBucket" },
        { id: 6, text: "ключі від темниці", type: "rightBucket" },
        { id: 7, text: "бібліотека Ярослава Мудрого", type: "rightBucket" },
        { id: 8, text: "Золотий Брід", type: "rightBucket" },
        { id: 9, text: "Січ", type: "leftBucket" },
        { id: 10, text: "Муромль", type: "rightBucket" },
        { id: 11, text: "псалом", type: "rightBucket" },
        { id: 12, text: "народна дума", type: "rightBucket" },
        { id: 13, text: "прозова форма", type: "leftBucket" },
        { id: 14, text: "Перевозькі хутори", type: "leftBucket" },
      ],
      leftBucketTitle: "Відомості пов’язані з твором про Івана Сірка",
      rightBucketTitle: "Відомості не пов’язані з твором про Івана Сірка",
    },
    {
      id: 7,
      question:
        "7. Переглянувши відеоматеріал телеканалу «СТБ» «Іван Сірко – отаман із магічними здібностями» (26 хв 35 с), підготуйтеся переказати ті відомості із життя кошового отамана, яких немає в повісті Марії Морозенко «Іван Сірко – великий характерник». Для зручності складіть і запишіть план вибіркового переказу.",
      videoPath:
        "https://www.youtube.com/embed/4SLi9UbV_L4?si=XtCKnr8mYv4-NTv-",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      type: 'video',
    },
  ],
};
