import React, { Component } from "react";
import Header from "../header_h/Header";
import Footer from "../footer/Footer";
import BreadcrumbsModulePage from "../breadcrumbs/BreadcrumbsModulePage";
import SelectionContainer from "../selection_container/SelectionContainer.js";
import ModuleContainer from "../module-container/ModuleContainer";
import ExportPdfButton from "./ExportPdfButton.js";

class ModulePage extends Component {
  state = {
    selectedType: null, // Для хранения выбранного типа (test, video, technical, interactive)
    filteredModule: null, // Отфильтрованный модуль
  };

  handleSelect = (type) => {
    const { module } = this.props;

    let filteredQuestions = [];

    if (type === "test") {
      filteredQuestions = module.questions.filter((question) => question.type === "test");
    } else if (type === "interactive") {
      filteredQuestions = module.questions.filter((question) => question.type === "interactive");
    } else if (type === "video") {
      filteredQuestions = module.questions.filter((question) => question.type === "video");
    }

    const filteredModule = {
      ...module,
      questions: filteredQuestions,
    };

    this.setState({ selectedType: type, filteredModule });
  };

  render() {
    const { userName, userRoles, module, path } = this.props;
    const { component: Component } = this.props;
    const { selectedType, filteredModule } = this.state;

    // Проверка наличия "avr" в URL
    const isAvrRoute = window.location.pathname.includes("/avr/");

    if (!isAvrRoute) {
      // Старая логика
      return (
        <>
          <Header userName={userName} userRoles={userRoles} />
          <BreadcrumbsModulePage />
          {Array.isArray(userRoles) && userRoles.includes("ADMIN") && (
            <ExportPdfButton module={module} />
          )}
          <ModuleContainer component={Component} module={module} path={path} />
          <Footer />
        </>
      );
    }

    // Новая логика для маршрутов с "avr"
    return (
      <>
        <Header userName={userName} userRoles={userRoles} />
        <BreadcrumbsModulePage />
        {Array.isArray(userRoles) && userRoles.includes("ADMIN") && (
          <ExportPdfButton module={module} />
        )}

        {!selectedType ? (
          <SelectionContainer onSelect={this.handleSelect} />
        ) : filteredModule && filteredModule.questions.length > 0 ? (
          <ModuleContainer
            component={Component}
            module={filteredModule}
            path={path}
          />
        ) : (
          <div className="placeholder-content">
            <p>Для даної теми завдання не призначено</p>
          </div>
        )}
        <Footer />
      </>
    );
  }
}

export default ModulePage;
