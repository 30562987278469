export const module11 = {
  title: "Інформація",
  questions: [
    {
      id: 1,
      question: "1. Визначте, яка з наведених функцій мови реалізує задоволення естетичних смаків носіїв.",
      options: [
        { id: "А", text: "комунікативна" },
        { id: "Б", text: "емоційна" },
        { id: "В", text: "державотвірна" },
        { id: "Г", text: "естетична" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Визначте, яка з наведених функцій мови допомагає передати емоції.",
      options: [
        { id: "А", text: "пізнавальна" },
        { id: "Б", text: "емоційна" },
        { id: "В", text: "ідентифікаційна" },
        { id: "Г", text: "називальна" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Для комбінованого повідомлення може використати",
      options: [
        { id: "А", text: "лише текст" },
        { id: "Б", text: "лише графіку" },
        { id: "В", text: "текст і малюнки" },
        { id: "Г", text: "тільки усні комунікації" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Надійними є",
      options: [
        { id: "А", text: "анонімні блоги" },
        { id: "Б", text: "офіційні новинні агенції" },
        { id: "В", text: "соціальні мережі" },
        { id: "Г", text: "чутки" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Визначте, яка з наведених характеристик має особливе значення для медійної інформації.",
      options: [
        { id: "А", text: "повільність" },
        { id: "Б", text: "неправдивість" },
        { id: "В", text: "швидкість поширення" },
        { id: "Г", text: "складність" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Найкраще описує факт",
      options: [
        { id: "А", text: "думка, що може бути істинною або хибною" },
        { id: "Б", text: "реальна подія, що не потребує доведення" },
        { id: "В", text: "оцінка особистого досвіду" },
        { id: "Г", text: "загальноприйняте твердження" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 7,
      question: "7. Прикладом судження є фраза",
      options: [
        { id: "А", text: "Швидкість вібрації вимірюють у герцах." },
        { id: "Б", text: "Немає нічого кращого за кілька улюблених мелодій перед сном." },
        { id: "В", text: "Солов’ї мають посереднє коричневе забарвлення." },
        { id: "Г", text: "Дев’ята симфонія Бетховена вдвічі довша, ніж інші." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 8,
      question: "8. Визначте, чому важливо вміти відрізняти факти від суджень.",
      options: [
        { id: "А", text: "Це допомагає уникати емоційних реакцій." },
        { id: "Б", text: "Це дає змогу формувати об’єктивну точку зору." },
        { id: "В", text: "Це покращує навички письма." },
        { id: "Г", text: "Це робить спілкування легшим." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 9,
      question: "9. Прикладом хибного судження є фраза",
      options: [
        { id: "А", text: "Квитки на рок-концерт дуже дорогі." },
        { id: "Б", text: "Усі музичні інструменти реагують на зміну температури." },
        { id: "В", text: "Немає нічого кращого за музику, що лікує ковід." },
        { id: "Г", text: "Борис Лятошинський написав 11 симфонічних поем." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 10,
      question: "10. Визначте, яка з наведених характеристик найкраще описує маніпуляцію.",
      options: [
        { id: "А", text: "чесне поширення інформації" },
        { id: "Б", text: "викривлення інформації для досягнення мети" },
        { id: "В", text: "об’єктивний аналіз фактів" },
        { id: "Г", text: "поширення ідей, що відповідають інтересам суспільства" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 11,
      question: "11. Прикладом маніпуляції зі статистикою є",
      options: [
        { id: "А", text: "завищення цифр щодо кількості учасників прощі" },
        { id: "Б", text: "вибірковий характер новин" },
        { id: "В", text: "створення неправдивої інформації" },
        { id: "Г", text: "замовчування інформації" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 12,
      question: "12. Прикладом покликання на недостовірні джерела є фраза",
      options: [
        { id: "А", text: "Як усім відомо … ." },
        { id: "Б", text: "У словнику зазначено, що … ." },
        { id: "В", text: "Експерти вважають … ." },
        { id: "Г", text: "Експериментально доведено … ." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
  ],
};
