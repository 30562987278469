import { text_mod_8, } from "../text/Text.js";

export const module17_18 = {

  title: "Урок 17-18. Здорове харчування. Безпека харчування.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_8,
    },

  ],
};
