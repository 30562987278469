import React from "react";
import pdfIcon from "./images/pdfIcon.svg"

function DownloadPdfButton({ pdfPath, pdfFileName }) {
    console.log(pdfPath);

    return (
        <a
            href={pdfPath}
            download={pdfFileName}
            title="Завантажити PDF"
            style={{
                display: "inline-block",
                textDecoration: "none",
            }}
        >
            <img
                src={pdfIcon}
                alt="Download PDF"
                style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    cursor: "pointer",
                }}
            />
        </a>
    );
}

export default DownloadPdfButton;
