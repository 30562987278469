import {text_mod_64, } from "../text/Text.js";
export const module64 = {
  title:
    "§64. Текст-опис",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_64, },
    ]
  }
