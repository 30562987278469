import {text_mod_15, } from "../text/Text.js";
export const module15 = {
  title:
    "Урок 15. Літера І",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_15, },
    ]
  };