import {text_mod_8, } from "../text/Text.js";
export const module08 = {
  title:
    "Уроки 31-34. Способи віднімання від 16 одноцифрових чисел.​ Способи віднімання від 17 одноцифрових чисел.​ Способи віднімання від 18 одноцифрових чисел.​ Додавання і віднімання одноцифрових чисел​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_8, },
    ]
  }
