import {text_mod_14, } from "../text/Text.js";
export const module14 = {
  title:
    "Уроки 61-65. Прямий кут. Розпізнавання геометричних фігур.​ Периметр. Букви латинського алфавіту.​ Задачі на знаходження третього доданка.​ Віднімання чисел виду 83 – 46, 70 – 46​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_14, },
    ]
  }
