
  import {text_mod_58, } from "../text/Text.js";
export const module58 = {
  title:
    "Урок 3. Приголосні звуки: дзвінкі та глухі",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_58, },
    ]
  };