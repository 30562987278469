export const module01 = {
  title: "Односкладні речення. Односкладне називне речення. Дрібниці мають значення",
  questions: [
    {
      id: 1,
      question: "1. Односкладними є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Візьму собі твою співочу душу (Леся Українка)." },
        { id: "Б", text: "Посадили над козаком явір та калину (Т. Шевченко)." },
        { id: "В", text: "Реве та стогне Дніпр широкий… (Т. Шевченко)." },
        { id: "Г", text: "Яскраве сонце." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Називним є речення",
      options: [
        { id: "А", text: "з головним членом у формі підмета" },
        { id: "Б", text: "з головним членом у формі присудка" },
        { id: "В", text: "з підметом і присудком" },
        { id: "Г", text: "що не має другорядних членів" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Односкладним є речення",
      options: [
        { id: "А", text: "Сонце світить яскраво." },
        { id: "Б", text: "Діти граються у дворі." },
        { id: "В", text: "Мені не віриться." },
        { id: "Г", text: "Вона любить читати книжки." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Називним є речення",
      options: [
        { id: "А", text: "Українські пісні зачарували весь світ." },
        { id: "Б", text: "Співаю пісню." },
        { id: "В", text: "Пісня красива." },
        { id: "Г", text: "Красива пісня." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Називне речення може бути поширене",
      options: [
        { id: "А", text: "додатком" },
        { id: "Б", text: "обставиною" },
        { id: "В", text: "означенням" },
        { id: "Г", text: "додатком, означенням, обставиною" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Називними є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Сонячний день." },
        { id: "Б", text: "Сонячно." },
        { id: "В", text: "Сонце." },
        { id: "Г", text: "Надворі сонячно." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
  ],
};
