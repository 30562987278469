import {text_mod_71, } from "../text/Text.js";
export const module71 = {
  title:
    "Урок 16. Слова — назви предметів. Слова, які мають кілька значень",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_71, },
    ]
  };