export const module15 = {
  title: "Текст",
  questions: [
    {
      id: 1,
      question: "1. Визначте, які з наведених складників є обов’язковими для цифрового тексту.",
      options: [
        { id: "А", text: "заголовки" },
        { id: "Б", text: "нумерація" },
        { id: "В", text: "гіперпокликання" },
        { id: "Г", text: "усі вищезазначені" },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Гіперпокликання в цифровому тексті",
      options: [
        { id: "А", text: "прикрашають текст" },
        { id: "Б", text: "допомагають переходити до інших частин тексту або ресурсів" },
        { id: "В", text: "є частиною нумерації" },
        { id: "Г", text: "не мають жодної ролі" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Рубрикація в тексті — це",
      options: [
        { id: "А", text: "визначення терміна" },
        { id: "Б", text: "поділ тексту на логічні частини для кращого сприйняття" },
        { id: "В", text: "використання кольору для виділення тексту" },
        { id: "Г", text: "нумерація фрагментів тексту" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test', },
    {
      id: 4,
      question: "4. Заголовки в тексті",
      options: [
        { id: "А", text: "допомагають привернути увагу" },
        { id: "Б", text: "роблять текст більш естетичним" },
        { id: "В", text: "структурують інформацію та полегшують її сприйняття" },
        { id: "Г", text: "не мають значення" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Переказ називаємо вторинним текстом, тому що він",
      options: [
        { id: "А", text: "містить лише факти" },
        { id: "Б", text: "є переробленим варіантом первинного тексту" },
        { id: "В", text: "може містити певні зміни" },
        { id: "Г", text: "є коротшим за первинний текст" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Основна мета стислого переказу —",
      options: [
        { id: "А", text: "включити всі деталі" },
        { id: "Б", text: "передати основний зміст тексту, вилучивши несуттєве" },
        { id: "В", text: "зробити текст більш складним" },
        { id: "Г", text: "додати нові ідеї" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
  ],
};
