
import React from 'react';
import { Navigate } from 'react-router-dom';
import { mockValidateToken } from '../../api/mockAuth';


function RoleBasedRoute({ children, role }) {

    const token = localStorage.getItem('token');
    const userData = mockValidateToken(token);

    if (!userData) {

        return <Navigate to="/login" replace />;
    }

    const userRoles = userData.roles;

    if (typeof role === 'string') {
        if (!userRoles.includes(role)) {

            return <h3>Доступ заборонений.</h3>;
        }
    }

    if (Array.isArray(role)) {
        const hasRole = role.some((r) => userRoles.includes(r));
        if (!hasRole) {
            return <h3>Доступ заборонений.</h3>;
        }
    }
    return children;
}

export default RoleBasedRoute;
