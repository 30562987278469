import {text_mod_17, } from "../text/Text.js";
export const module17 = {
  title:
    "§17. Позначення м’якості приголосних буквами І, Я, Ю, Є",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_17, },
    ]
  }
