export const module14 = {
  title: "Жанри мовлення",
  questions: [
    {
      id: 1,
      question: "1. Що термін «дорікати» означає",
      options: [
        { id: "А", text: "висловлювати своє незадоволення щодо чогось" },
        { id: "Б", text: "висловлювати радість" },
        { id: "В", text: "виявляти байдужість" },
        { id: "Г", text: "підтримувати когось у важкій ситуації" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Визначте, яка з наведених характеристик найбільш точно описує обман.",
      options: [
        { id: "А", text: "випадкове непорозуміння" },
        { id: "Б", text: "навмисне введення в оману" },
        { id: "В", text: "висловлення співчуття" },
        { id: "Г", text: "неправильне тлумачення фактів" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Комунікативна мета мовленнєвого жанру співчуття —",
      options: [
        { id: "А", text: "висловлення радості" },
        { id: "Б", text: "висловлення жалю через нещастя іншої людини" },
        { id: "В", text: "обговорення досягнень" },
        { id: "Г", text: "висловлення критики" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Відгук містить",
      options: [
        { id: "А", text: "тільки особисті враження" },
        { id: "Б", text: "тезу, докази, висновок, рекомендації" },
        { id: "В", text: "лише загальну оцінку" },
        { id: "Г", text: "лише опис сюжету" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Ризиком при написанні відгуку у вільному стилі є",
      options: [
        { id: "А", text: "втрата логіки викладу думок" },
        { id: "Б", text: "занадто формальний стиль" },
        { id: "В", text: "відсутність емоцій" },
        { id: "Г", text: "неправильне використання термінів" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Мета відгуку на прочитану книжку —",
      options: [
        { id: "А", text: "оцінити автора" },
        { id: "Б", text: "висловити свої почуття та думки, рекомендувати книжку іншим" },
        { id: "В", text: "підсумувати сюжет" },
        { id: "Г", text: "висловити незадоволення" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 7,
      question: "7. Обов’язковими складниками в кулінарному рецепті є",
      options: [
        { id: "А", text: "основні інгредієнти" },
        { id: "Б", text: "основні інгредієнти та спосіб приготування" },
        { id: "В", text: "спосіб приготування" },
        { id: "Г", text: "час приготування" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 8,
      question: "8. Традиційною для української кухні є",
      options: [
        { id: "А", text: "піца" },
        { id: "Б", text: "суші" },
        { id: "В", text: "вареники" },
        { id: "Г", text: "бургер" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 9,
      question: "9. Важливо дотримуватися мовних правил при написанні рецепту, щоб",
      options: [
        { id: "А", text: "зробити текст більш цікавим" },
        { id: "Б", text: "уникнути непорозумінь" },
        { id: "В", text: "спростити читання" },
        { id: "Г", text: "додати більше деталей" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 10,
      question: "10. Найкраще описує важливість кулінарного рецепта в культурі фраза",
      options: [
        { id: "А", text: "Рецепти — лише для кухарів." },
        { id: "Б", text: "Рецепти зберігають традиції і передають знання." },
        { id: "В", text: "Рецепти не мають значення." },
        { id: "Г", text: "Рецепти — це просто інструкції." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 11,
      question: "11. Синонімом до слова вітати",
      options: [
        { id: "А", text: "бажати" },
        { id: "Б", text: "нагороджувати" },
        { id: "В", text: "гратулювати" },
        { id: "Г", text: "дякувати" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 12,
      question: "12. Вислів з роси й води в привітанні означає",
      options: [
        { id: "А", text: "бажання здоров’я і щастя" },
        { id: "Б", text: "бажання удачі" },
        { id: "В", text: "бажання довгих років життя" },
        { id: "Г", text: "бажання багатства" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 13,
      question: "13. Якщо ви отримали п’ять–сім однакових листівок, це",
      options: [
        { id: "А", text: "свідчить про щирість" },
        { id: "Б", text: "робить привітання унікальним" },
        { id: "В", text: "може виглядати нещиро" },
        { id: "Г", text: "не має значення" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 14,
      question: "14. Найбільш важлива порада для залучення підписників до блогу —",
      options: [
        { id: "А", text: "викладати контент рідко" },
        { id: "Б", text: "використовувати складні терміни" },
        { id: "В", text: "регулярно публікувати нові пости" },
        { id: "Г", text: "ігнорувати коментарі від підписників" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 15,
      question: "15. Визначте, яка зі стратегій може допомогти в боротьбі з негативними коментарями в блозі.",
      options: [
        { id: "А", text: "ігнорувати всі коментарі" },
        { id: "Б", text: "відповідати з агресією" },
        { id: "В", text: "ввічливо відповідати та пояснювати свою точку зору" },
        { id: "Г", text: "видаляти всі негативні коментарі" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 16,
      question: "16. Смайлики в чатах доцільно використовувати, коли ви",
      options: [
        { id: "А", text: "хочете висловити серйозні думки" },
        { id: "Б", text: "спілкуєтеся з незнайомими людьми" },
        { id: "В", text: "хочете передати емоції або підкреслити настрій" },
        { id: "Г", text: "не знаєте, що написати" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 17,
      question: "17. У чатах НЕ можна писати",
      options: [
        { id: "А", text: "особисті думки" },
        { id: "Б", text: "конфіденційну інформацію" },
        { id: "В", text: "смайлики" },
        { id: "Г", text: "питання про домашнє завдання" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test', },
    {
      id: 18,
      question: "18. Правила використання емодзі подано в рядку",
      options: [
        { id: "А", text: "Потрібно використовувати емодзі в кожному реченні." },
        { id: "Б", text: "Можна використовувати один–два смайлики поряд." },
        { id: "В", text: "Треба заміщати слова емодзі." },
        { id: "Г", text: "Варто використовувати незрозумілі емодзі." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
  ],
};
