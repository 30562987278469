import {text_mod_62, } from "../text/Text.js";
export const module62 = {
  title:
    "Урок 7. Буква ю. Звукове значення букви ю",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_62, },
    ]
  };