export const module18_19 = {
  title: "§ 18–19. Граматична основа двоскладного речення. Підмет",
  questions: [
    {
      id: 1,
      question: "1. НЕМАЄ підмета в реченні",
      options: [
        { id: "А", text: "Кожна жаба своє болото хвалить." },
        { id: "Б", text: "Сміливі завжди мають щастя." },
        { id: "В", text: "Велике дерево поволі росте." },
        { id: "Г", text: "З пісні слова не викинеш." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "Одним словом виражено підмет у реченні",
      options: [
        { id: "А", text: "Мати із сином першими ввійшли до палацу." },
        { id: "Б", text: "Часом золота молодь порушує усталені закони." },
        { id: "В", text: "Гарбуз із гарбузенятами сховався під листям." },
        { id: "Г", text: "Прилетіла зграйка горобців і сіла на підвіконня." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Словосполученням виражено підмет у реченні",
      options: [
        { id: "А", text: "Двоє вершників помчали до основного війська." },
        { id: "Б", text: "Творчість поетів – це частина вічності всіх нас." },
        { id: "В", text: "Дехто намагався спуститися крутим схилом." },
        { id: "Г", text: "Видно було, що верхові повільно їхали назустріч." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Підмет виражено фразеологізмом у реченні.",
      options: [
        {
          id: "А",
          text: "До нових віників пам’ятатимуть.",
        },
        { id: "Б", text: "Тимкове лице світилося щастям." },
        { id: "В", text: "Знову селян обдерли як липку." },
        { id: "Г", text: "Чиясь сильна рука підтримує нас." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Підмет виражено інфінітивом у реченні.",
      options: [
        {
          id: "А",
          text: "Микола з Ларисою допливли до берега.",
        },
        { id: "Б", text: "Співати – уміння, набуте ще в дитинстві." },
        { id: "В", text: "З неба вже почало налягати літнім дощем." },
        { id: "Г", text: "Полюбили навідуватися до вільшаника." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question:
        "6. Визначте спосіб вираження підмета в кожному реченні.",
      dropdownQuestionParts: [
        "1) І день і ніч парнаські сестри не дають спокою молодому поетові. ",
        { id: "dropdown1" },
        " <br />",
        " 2) Кожен із нас шукає в житті власне призначення й знаходить його. ",
        { id: "dropdown2" },
        " <br />",
        "3) У скрині лежало кілька дбайливо складених вишитих рушників. ",
        { id: "dropdown3" },
        " <br />",
        "4) «Український космос» О. Шупляка пройнятий філософією буття. ",
        { id: "dropdown4" },
        " <br />",
      ],
      options: [
        { id: "А", text: "сполучення слів із кількісним значенням " },
        { id: "Б", text: "неозначена форма дієслова" },
        { id: "В", text: "сполучення займенників" },
        { id: "Г", text: "складна власна назва" },
        { id: "Д", text: "фразеологізм" },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "В",
        dropdown3: "А",
        dropdown4: "Г",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        "7. Розподіліть речення за способом вираження підмета у дві чарунки: 1 – підмет, виражений одним словом; 2 – підмет, виражений сполученням слів.",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "За тобою завше будуть мандрувати очі материнські і білява хата. ", type: "leftBucket" },
        { id: 2, text: "На царину вилетіло двійко хлопчаків. ", type: "rightBucket" },
        { id: 3, text: "Санько з Чорториєм переглянулися. ", type: "rightBucket" },
        { id: 4, text: "Приємно бродити по теплих калюжах після дощу. ", type: "leftBucket" },
        { id: 5, text: "Кожен із козаків привітав Швайку з перемогою. ", type: "rightBucket" },
        { id: 6, text: "Чорнявий став під стіною.", type: "leftBucket" },
      ],
      leftBucketTitle: "підмет, виражений одним словом",
      rightBucketTitle: "підмет, виражений сполученням слів",
    },
    {
      id: 8,
      question:
        "8. Розподіліть речення за способом вираження підмета на дві категорії: підмет, виражений сполученням слів.",
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "На їхніх головах творився і сміх і гріх. ", type: "rightBucket" },
        { id: 2, text: "Грицик з півсотнею людей кинувся вподовж берега. ", type: "leftBucket" },
        { id: 3, text: "Кілька вершників проїхало під вербами.  ", type: "rightBucket" },
        { id: 4, text: "Раптом пролунало гучне «тпру» візниці.", type: "leftBucket" },
        { id: 5, text: "Ми ласували солодкими грушами. ", type: "leftBucket" },
        { id: 6, text: "Група волонтерів надала допомогу воїнам.", type: "rightBucket" },
      ],
      leftBucketTitle: "не використовується",
      rightBucketTitle: "підмет, виражений сполученням слів"
    },
    {
      id: 9,
      question:
        "9. <b>Помилки в українській мові, яких припустився Олександр Авраменко</b>. Перегляньте відео і виконайте завдання.</br> <b>Завдання:<b/> </br> <i> 1. З’ясуйте, у якому реченні абзацу 1 вправи 6 можна припуститися помилки, якщо вжити слово <b>знаходиться</b>.</br> 2. Доберіть до слова <b>зручний</b> синонім (усно).</br> 3. Запишіть слово <b>обрання</b> і розберіть його за будовою.</i>",
      videoPath:
        "https://www.youtube.com/embed/8grKgLiWXe4?si=6zVk_D_RUyoV7e7T",
      addClass: "horizontal",
      type: "video",
      isTest: true,
      isCreativeTask: true,
    },
  ],
};
