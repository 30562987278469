import {text_mod_4, } from "../text/Text.js";
export const module04 = {
  title:
    "Уроки 12-16. ​Додавання чисел 4–9 до 7 з переходом через 10.​ Додавання чисел 5–9 до 6 з переходом через 10.​ Вимірювання температури​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_4, },
    ]
  }
