import { text_mod_10, text_mod_11, text_mod_12, } from "../text/Text";

export const module04 = {
  title: "Тема 4. Русь, її культура та письменство. Усний народний епос. Старини (билини). Старина «Про Іллю Муромця та Соловія»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_10,
    },

    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_11,
    },

    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_12,
    },
    {
      id: 4,
      question: "1. Найдавнішим жанром героїчного епосу українців є",
      options: [
        { id: "А", text: "легенди" },
        { id: "Б", text: "старини" },
        { id: "В", text: "міфи" },
        { id: "Г", text: "історичні пісні" },
      ],

      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Старини виконували в супроводі",
      options: [
        { id: "А", text: "гуслів" },
        { id: "Б", text: "бандури" },
        { id: "В", text: "кобзи" },
        { id: "Г", text: "арфи" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "3. Богатир Ілля Муромець боронить віру християнську та служить князю",
      options: [
        { id: "А", text: "Ярославу Мудрому" },
        { id: "Б", text: "Святославу Хороброму" },
        { id: "В", text: "Володимиру Великому" },
        { id: "Г", text: "Володимиру Мономаху" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "4. «…усі трави-мурави в’януть, // Усі квіти обсипаються, // А хто близько з людей — // Так всі мертві лежать», внаслідок",
      options: [
        { id: "А", text: "бойових дій війська мурзів-татарів" },
        { id: "Б", text: "вправного поводження Іллі з мечем" },
        { id: "В", text: "свисту Соловія" },
        { id: "Г", text: "стихійного лиха" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Визначте, як учинив Ілля з царевичами мурзів-татарів.",
      options: [
        { id: "А", text: "відпустив на волю" },
        { id: "Б", text: "взяв у полон і зажадав викупу" },
        { id: "В", text: "примусив служити князю" },
        { id: "Г", text: "стратив під час битви" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	Для зображення сили Іллі та свисту Соловія в старині використано художній засіб",
      options: [
        { id: "А", text: "епітет" },
        { id: "Б", text: "метафору" },
        { id: "В", text: "гіперболу" },
        { id: "Г", text: "порівняння" },
      ],
      correctAnswer: "В",
      isTest: true,
    },

  ],
};
