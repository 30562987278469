import {text_mod_15, } from "../text/Text.js";
export const module15 = {
  title:
    "Уроки 66-70. Центнер. Дії з іменованими числами.​ Закріплення вивченого матеріалу. Календар зимових місяців. Розв’язування задач. ​Додавання і віднімання чисел​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_15, },
    ]
  }
