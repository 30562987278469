import {text_mod_19, } from "../text/Text.js";
export const module19 = {
  title:
    "§19. Апостроф",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_19, },
    ]
  }
