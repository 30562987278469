import { text_mod_1, } from "./text/Text";
export const instruction = {
    title:
        "Як користуватись додатком?",
    questions: [
        {
            id: 1,
            onlyText: true,
            text: text_mod_1,
        },
    ]
}
