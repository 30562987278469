export const text_mod_1 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQREj7KGhvGWQbHnob8O9p9SAedRXSqhmum20HtOyplzeM8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_2 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR9fl2HYl4aRJRHtM2rfntyAR05wd6YTguWw2vdL2HK_OQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_3 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRoqkUrSbpQSrAC4jbccq0SAaOuGkL0PPvOZmqy1D4sTLo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_4 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTJHPjdC1chQ4Z-EeEjQlFsAR9UO-N6j31yf1lzjgrKETI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_5 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSkgyB0hHsoQ4B6URBP1ZBtARTAeApCuRl4gEUVG2ynRlo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_6 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQyV-SBWHjqQLvFrVAC2AUJAV7OhJw6fSO-VbHSWy4imdc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_7 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSZ7KJxHXiAQawNhI1aAyzcAaC_XyLjoCP0y_XRYw-uosg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_8 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQFrFhLVa0OTazwcLkYelDFAeYPEq3FvaUcu0CUPQlAoM8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_9 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSyqPSTFZmUT4minHFzyTUtAVBCOxKuGMX8PQ2O1uAKr6k"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_10 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQKYNux38RIR4qujzjmQ5sUAfpmDnA8uuvo283txXH1U1U"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_11 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQROP5MkattxTblYXkg-JKUoAd3n6_mMyWrel9KSZqjiFGU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_12 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSsGWQ0R9CPRr4l6URWjYWXAXTAQCvUAdyYPFhOwagGA1c"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_13 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTKQO-sHOM8Qo8UCgGqVUMaARNbJFXtDFXj0jF7TnMGJ30"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_14 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRFVfpbRQOuQpoTXTo6cnqmAfHKesPduYlx1K5nv6zCpks"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_15 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR1caU0r4ucSIVBpb7hhspcAUvyWgy_nPD8z3hYw4jhuY0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_16 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT0nmYOmidTQZDLpsepndhMAY0Zek67ONluWfqXpp94WGw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_17 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSmOfR8LnBsRby5m-92ZX2VAdmbfqXAZ8gJw3lF9XOzsho"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_18 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRlbLgtzTKVQKdikMRf3g5MAYIzgyVPM456UqRbh8Z79_U"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_19 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQy4wkKv3GPSKYMP6NtFFRzAfHlptG3gc4eTjrpi5kj7P8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_20 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTQzx4VtMEbSZlJrcphgXxdAQ94wnL1LD2rr7vLf-L268M"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_21 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS32idekjkXRJD3-lKYId0JAdz-AJZ9f-55Zu_JDoPDE2w"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_22 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT9TBtD-x9aSo0EA8z2a6oVAe5YBB2ceZbcWK8P9HKDY3s"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_23 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSxvjNabW7hRJSnFt8qMUC8AZ5jRWrU0hbMk9SbRpjyiAE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_24 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQnKv4Y464hS6CWx5DWBmnFAXR-_1xlcJpnpXblaLoL6xA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_25 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQAEDOYS2whRoxNiVtpMf5BARXHRSoO0uM7QV2G5SRYhk8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_26 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSwTdCXB7xwRp-J7jVoR5hnASEJ2A2fHdEfNly25u46O8w"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_27 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTRqZDkJBqaQosYriD0buDbAfqijXOYrgKTR0TB7fgLuOY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_28 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQaL10RHfBEQ5vs-Zt6_2OJAfO0lgE8sBqoalnXVSdyt40"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_29 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQ41cM3tiXwT61Me_atnz-GAZHrMwJzhlGm56O33hCDRYw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_30 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQZBnmokdLAQr7qnqXPV3zvASgGrqDf84nuHQWAP1bOZFg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_31 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRMNjzYM7reS6ltjIhrsobzASOwiEhBEg3AvTo5vvzJhrc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_32 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTDXIt1tpwGSJipakVP_STHASb9g3c_X60bS3ekYa8Y1QY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_33 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQS0D59EbbfDRpFz-KLbaWsdAbnDsVPJdT5WV2R8N1kfjN0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_34 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQtDFbemnbBTZl4KVunYDnyAfJsjo8l7WwfP29jNNWzN-4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_35 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRspVcDiMmMQbB5h24m7H4AAfwsPQsbYNu55fwMvcGJE9E"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_36 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSxr0fr2YMBS4imU5QF2QyuAVX_DmnHrG3GtirasUUwCaY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_37 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTlU8AorACXQ6HlaElg_6qLAT3BrPcdAgOck7jEVqeNykM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_38 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSdb7IUwm5VR7cFJJ8KdVkgAXxiJjBEHz_3SESIvxLhdIE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_39 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSki2e589GDSa1WQF7LqVxUAYPqp0hEb7sERyW0AdoZi3s"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_40 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSLQY9VsKLOQaBvohmMT2MuAbCLLekOPAyb0q_T_vlCdOM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_41 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSa2pwCvfWQTZh8nTBdSi_JAQoyX-f5WqDyAjKZvKzBE94"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_42 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSmomrZT9C1SKhybEXgMoaNAVz42I9egc36YVs7nUAUGM8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_43 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSZOK0B4dEsTJ8wLJkEfr4AARmj611MW6f8l4BMLeCKn6Y"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_44 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRj32D0RxFaQ6hkjGVMNkc2AS0I0TbkFE8rm_REBI_XKhw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_45 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQKoHCUfET7RYv4AO1soF8jAQkD3ihHPia1TZSczw1En-M"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_46 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRooVbiKL_IRolMNGyhDThIAYbQnRbRzTpxzj_-BqHMZlc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_47 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTCfB0Ug7agT65oU2Rn4zCgATgOzDarw1rD-yGOdnU__EA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_48 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQJzetHm7MxSIFFOjYgNJxpAaadDdAY2t7F-r-Txw1_hcw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_49 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQm3F5sEbyASKQCfsept6oLAcFaNWXVqvjjFOyBO3blmbU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_50 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRwJjEYJdnqQbvsi9Vk2VVDAWl6_kvg6AD-SDPQdxNprBk"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_51 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSuZ6az1WplTrh2RFlqQhdTAas4z5NCSziFEVdlgtZGrD8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_52 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQHCwwhOZKPSr8pdbkqs1aZAfckmvRwtJpDcIwyQCBcysE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_53 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTnLDK2PtN9RZFoV-7D0pK9AaV6RmhY-XZtCkO3JO7L1F0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_54 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRZdCIGP5rcTrDi8M5PPMl5AZsPhn5g1f34RjoDcDBvEQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_55 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT52aCu5Mj-Qr3WDpcagajnAfZxQILFKreEtK0qDhf74Oc"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_56 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR-dDICW-sLRI7tdIm-tk0xAfZSWWEnnuf6ovW13zIg-6k"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_57 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT0Yh6ucx2_SqBYcZTL0zS4ATWE6QuuQW2-eTY5-r511h8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_58 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQR4rZBvf6pVSatZq0fWqVdlAaaNBhJI3LMtlokyAKCf00w"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_59 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTGoaWHFS4eQJVfZ3_vXMyKAdF8ig28yii8c9E8z6XU0sM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_60 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSc-Y839H4mRLLP_ITibsEkAa_hBowsIhpXY60QURvnx2A"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_61 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQRYjD-n3dXQLtuvdDRIVPjAZN-kdlMCHS6zMJITFH40OU"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_62 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQT6y_9c5o0SJDef4M03MJiAWfQoJW4-icx2jKRavVRVTA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_63 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQSeZ3L4bZE8TrAb4tfqqjAdAXDsF6oDkLZsg0U8KWK-jlM"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_64 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQX059j4PbNS5DO3T4B-cmpAcc-9dO4H9FvK8RE6TqpK6w"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_65 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQIuP0_uY0zRom9QduegrR9AeRSAg9VkwNoShAialOtH8A"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_66 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQT_AwBExPRZTrjm9u5JYJDKAUKwnBDNX1mtpnpEHIXkA_c"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_67 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQRnMfkA7YFBRKHp2Sb6v49bAemQSY1ZT-b-fe4FSptmucs"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;