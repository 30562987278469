import {text_mod_50, } from "../text/Text.js";
export const module50 = {
  title:
    "Урок 29. Літера ф маленька",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_50, },
    ]
  };