import {text_mod_68, } from "../text/Text.js";
export const module68 = {
  title:
    "Урок 13. Поділ слова на склади. Наголос",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_68, },
    ]
  };