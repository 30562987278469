import {text_mod_35, } from "../text/Text.js";
export const module35 = {
  title:
    "Урок 14. Літера Ш велика",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_35, },
    ]
  };