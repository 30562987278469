import instr1 from "./instr1.jpg";
import instr2 from "./instr2.jpg";
import instr3 from "./instr3.jpg";
import instr4 from "./instr4.jpg";
import instr5 from "./instr5.jpg";

export const text_mod_1 = `
    <ol>
        <li><b>Увійдіть у свій акаунт або зареєструйтесь.</b><br/>
        Якщо ви ще не створили акаунт, заповніть просту форму реєстрації, вказавши необхідні дані. Після цього ви отримаєте доступ до всіх функцій платформи.
        </li>
        <li><b>Оберіть потрібний клас.</b>
        <img src="${instr1}" alt="" style="width:100%;">
        </li></br>
          
        <li>
        <b>Оберіть предмет для навчання.</b><br/>
        На платформі доступні додатки з різних навчальних дисциплін. Оберіть той, який вам потрібен.
          <img src="${instr2}" alt="" style="width:100%;">
        </li></br>

        <li>
        <b>Оберіть потрібну тему.</b><br/>
        Перегляньте доступні теми та оберіть ту, яка вас цікавить, щоб почати навчання.
          <img src="${instr3}" alt="" style="width:100%;">
        </li></br>

        <li>
        <b>Вивчайте теорію та виконуйте завдання.</b><br/>
        Пройдіть усі розділи теоретичного матеріалу, перегляньте презентації та використовуйте додаткові ресурси. Виконайте інтерактивні завдання, щоб закріпити знання та перевірити свої успіхи.
          <img src="${instr4}" alt="" style="width:100%;">
        </li></br>

        <li>
        <b>Отримуйте результат</b><br/>
       Після проходження тестів чи інших інтерактивних завдань ви побачите свій результат. 
          <img src="${instr5}" alt="" style="width:100%;">
       </li></br>

        <li>
        <b>У разі виникнення будь-яких запитань, ви можете зв’язатись із нами:</b><br/>
        📧 Email: info@gramota.kiev.ua <br/>
        📞 Телефон: +380686604958
        </li>
    </ol>
    `