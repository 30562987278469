import { text_mod_36 } from "../text/Text.js";

export const module36 = {
  title: "Урок 36. ​Ціна. Кількість. Вартість. Складання і розв'язання задач.​ Розв'язання задач на вивчені випадки множення і ділення. ​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_36,
    },
  ],
};
