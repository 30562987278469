import { text_mod_38 } from "../text/Text.js";

export const module38 = {
  title: "Урок 38. ​Числовий відрізок. Повторення вивчених випадків множення і ділення.​ Обчислення виразів.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_38,
    },
  ],
};
