import {text_mod_53, } from "../text/Text.js";
export const module53 = {
  title:
    "Урок 32. Звук дз",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_53, },
    ]
  };