export const module52_53 = {
  title: "§ 52–53. Однорідні члени речення",
  questions: [
    {
      id: 1,
      question: "1. Однорідними членами ускладнено речення",
      options: [
        { id: "А", text: "Цвіла липа, і густий медовий запах заливав кімнату." },
        { id: "Б", text: "Ледве розтулюю повіки – і яскраве сонце ріже очі." },
        { id: "В", text: "Сонце весняне синє море ніжно зігріває." },
        { id: "Г", text: "Хлопці сиділи на лаві, жадібно вминали пиріжки." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Одним рядом однорідних членів ускладнено речення",
      options: [
        { id: "А", text: "Високий, чистий дзвін коси передвіщав радість і втіху – косовицю." },
        { id: "Б", text: "Тепер човни не пливли, а ніби ковзали по водяній гладіні." },
        { id: "В", text: "Бджоли поралися коло цвіту й літали до соняшника, до маку та додому." },
        { id: "Г", text: "Нові, непізнавані шерехи змушували завмирати й оглядатися." }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Кількома рядами однорідних членів ускладнено речення",
      options: [
        { id: "А", text: "З її тонких, рухливих пальців сипалися червоні пелюстки півоній." },
        { id: "Б", text: "Збирають світлі, золоті меди веселокрилі та прозорі бджоли." },
        { id: "В", text: "Усе тут кудись поспішало, бігло, летіло, метушилося." },
        { id: "Г", text: "Бровкові дістався шмат ковбаси і коржик з маком." }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Протиставним сполучником поєднано однорідні члени в реченні",
      options: [
        { id: "А", text: "Учи дітей не страшкою, а ласкою." },
        { id: "Б", text: "Уже майже середина квітня, а сніг і далі мете." },
        { id: "В", text: "То один, то інший нападник вихоплювався з-за дерева." },
        { id: "Г", text: "Ні комарі, ні люті вепри, ні навіть стріли не страшні козакам." }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Змішаним зв’язком поєднано однорідні члени в реченні",
      options: [
        { id: "А", text: "Скільки сніг не лежатиме, а розтавати буде." },
        { id: "Б", text: "Дівчата до весілля вишивали найкращі, наймиліші рушники." },
        { id: "В", text: "Трави, дерева, квіти і люди до сонця тягнуться ввись." },
        { id: "Г", text: "Мрії стеляться ніжним цвітом, ластівками злітають увись." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. З’ясуйте, якими однорідними членами ускладнено речення.",
      dropdownQuestionParts: [
        "<i> <b>Однорідні члени речення</b> </br> 1) присудками ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) означеннями ",
        { id: "dropdown2", text: " - " },
        "<i><br />3) додатками ",
        { id: "dropdown3", text: " - " },
        "<i><br />4) обставинами ",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>",
      ],
      options: [
        { id: "А", text: "Сном блакитним заснули поля, і долини, і гори, й діброви." },
        { id: "Б", text: "Кілька свічок додали кімнаті не лише світла, а й затишку." },
        { id: "В", text: "Бровко вискочив із будки й привітно заметляв хвостом." },
        { id: "Г", text: "Дідусеву й бабусину хату називають ластівчиним гніздом." },
        { id: "Д", text: "Крізь життєві хащі, тумани випливає наша ріка любові." },
      ],
      correctAnswers: {
        dropdown1: "В",
        dropdown2: "Г",
        dropdown3: "Б",
        dropdown4: "Д",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за типами: <i><b>речення, ускладнені однорідними членами; речення, не ускладнені однорідними членами.</b> </br>(Перетягни номер речення у відповідний кошик)</i>.',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Плакав дощ, плакав…", type: "rightBucket" },
        { id: 2, text: "Зима була хоч і сніжна, але тепла.", type: "leftBucket" },
        { id: 3, text: "Земляки, однокласники, друзі збіглися до фейсбуку душевності.", type: "leftBucket" },
        { id: 4, text: "Жінки кліпали на лейтенанта ні в тих ні в сих.", type: "rightBucket" },
        { id: 5, text: "Увійшовши до лісу, усі відразу пішли шукати горіхи.", type: "rightBucket" },
        { id: 6, text: "Ліс годує та лікує.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені однорідними членами",
      rightBucketTitle: "Речення, не ускладнені однорідними членами",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за типами: <i><b>речення, ускладнені однорідними членами; речення, не ускладнені однорідними членами.</b> </br>(Перетягни номер речення у відповідний кошик)</i>.',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "Незабаром кишені були повні горіхів, і хлопці присіли на траву.", type: "rightBucket" },
        { id: 2, text: "І сміх і гріх було спостерігати за суперечкою моїх дідусів.", type: "rightBucket" },
        { id: 3, text: "Ще холодно, але вже березень.", type: "rightBucket" },
        { id: 4, text: "Безсонна й темна нічка зірок накидала в пічку, додала розмарин, чаберець і спекла золотий млинець.", type: "leftBucket" },
        { id: 5, text: "Поснідавши, Анна мовчки вийшла, не удостоївши нікого ані словом, ані поглядом.", type: "leftBucket" },
        { id: 6, text: "Сонце визирнуло з-за гори Мурави, глянуло на Гарбузяни й усміхнулося.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені однорідними членами",
      rightBucketTitle: "Речення, не ускладнені однорідними членами",
    },
  ],
};
