export const module41 = {
  title: "§ 41. Типи односкладних речень",
  questions: [
    {
      id: 1,
      question: "1. Односкладним є речення",
      options: [
        { id: "А", text: "Лихий доброго псує." },
        { id: "Б", text: "Поруч росла густа ліщина." },
        { id: "В", text: "Справжні книжки перечитують." },
        { id: "Г", text: "Небо затягнулося темними хмарами." }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Означено-особовим є речення",
      options: [
        { id: "А", text: "На палубі вже не метушилися." },
        { id: "Б", text: "Плекає педагог майбутність долі." },
        { id: "В", text: "У місті ще не погасла козацька іскра." },
        { id: "Г", text: "Назбираю в пам’яті щастя по краплинці." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. Неозначено-особовим є речення",
      options: [
        { id: "А", text: "Виховуючи дитину, виховуємо себе." },
        { id: "Б", text: "Наблизилися до темного ліска." },
        { id: "В", text: "Тепла вода забирала втому." },
        { id: "Г", text: "У садибі було темно й прохолодно." }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Узагальнено-особовим є речення",
      options: [
        { id: "А", text: "У закритий рот муха не влізе." },
        { id: "Б", text: "Гостре словечко коле сердечко." },
        { id: "В", text: "Гречана каша сама себе хвалить." },
        { id: "Г", text: "У ліс дрова не возять." }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Називним є речення",
      options: [
        { id: "А", text: "Безсмертні келихи людського повнозвуччя." },
        { id: "Б", text: "Попереду оповитий туманом прадавній ліс." },
        { id: "В", text: "Зненацька Сирітка насторожила вуха." },
        { id: "Г", text: "З діадемою місяця промчав олень." }
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: "6. Доберіть до кожного типу односкладного речення приклад.",
      dropdownQuestionParts: [
        "<i> <b>Тип односкладного речення</b> </br> 1)   ",
        { id: "dropdown1", text: "означено-особове " },
        "<br /> <i> 2) ",
        { id: "dropdown2", text: "неозначено-особове " },
        "<i><br />3) ",
        { id: "dropdown3", text: "узагальнено-особове " },
        "<i><br />4) ",
        { id: "dropdown4", text: "безособове" },
        "<i> <br/></i>",
      ],
      options: [
        { id: "А", text: "Хотілось чогось святочного." },
        { id: "Б", text: "З балки до пагорба скрадалися без звуку." },
        { id: "В", text: "Червону руту не шукай вечорами." },
        { id: "Г", text: "Кожна жаба своє болото хвалить." },
        { id: "Д", text: "У чужому домі не порядкуй." },
      ],
      correctAnswers: {
        dropdown1: "В",
        dropdown2: "Б",
        dropdown3: "Д",
        dropdown4: "А",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за типами: </br><i>1 – односкладні речення; 2 – двоскладні речення</i>>.',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "1. У козацькому таборі панувало пожвавлення.", type: "rightBucket" },
        { id: 2, text: "2. Безшумне обертання сузір’їв. ", type: "leftBucket" },
        { id: 3, text: "3. Вухами лопухів слухаю мушлю тиші. ", type: "leftBucket" },
        { id: 4, text: "4. Вершник зупинив коня, спішився. ", type: "rightBucket" },
        { id: 5, text: "5. Поїхали вздовж річки шукати броду, оминаючи міські ворота. ", type: "leftBucket" },
        { id: 6, text: "6. Після дощу капелюх не потрібний. ", type: "rightBucket" },
        { id: 7, text: "7. Наука в ліс не веде, а з лісу виводить. ", type: "rightBucket" },
        { id: 8, text: "8. Клин клином вибивають. ", type: "leftBucket" },
        { id: 9, text: "9. Підскочили вгору брови Хмельницького. ", type: "rightBucket" },
        { id: 10, text: "10. Надворі почало сутеніти. ", type: "leftBucket" },
        { id: 11, text: "11. Надворі білосніжна зима. ", type: "rightBucket" },
        { id: 12, text: "12. Від своєї тіні не втечеш.", type: "leftBucket" },

      ],
      leftBucketTitle: "Односкладні речення",
      rightBucketTitle: "Двоскладні речення",
    },

  ],
};
