import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/main-page/Main.js";
import ModulePage from "./components/modules/ModulePage.js";
import Module from "./components/modules/module/Module.js";
import TheoryPage from "./components/theory/TheoryPage.js";

import { allRoutes } from "./routes/allRoutes.js";
import { getUserName } from "./controller/UserController.js";

import "./App.css";
import "./css/responsive.css";
import ClassPage from "./components/class_page/class_page.js";
import SubjectPage from "./components/subject-page/subject_page.js";
import AuthorPage from "./components/author_page/AuhtorPage.js";
import TeacherPage from "./components/teacher_page/TeacherPage.js";
import TeacherClassPage from "./components/teacher_class_page/TeacherClassPage.js";
import MathModulePage from "./components/modules/MathModulePage.js";
import MockLoginAs from "./components/mockLogin/MockLoginAs.js";
import MockLoginAsNew from "./components/mockLogin/MockLoginAsNew.js";


import RoleBasedRoute from './components/routes/RoleBasedRoute.js';
import { mockValidateToken } from './api/mockAuth';

import { instruction } from "./components/instruction_page/Instruction.js";
import { about } from "./components/about/About.js";
import UserProfile from "./components/user_profile/UserProfile.js";
import { author_info } from "./components/author_info/Author_Info.js";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userRoles: [],
      loading: true,
    };
  }

  handleSetUserName = (name) => {
    this.setState({ userName: name });
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    const roles = localStorage.getItem('roles');
    let parsedRoles = [];

    // Валидация и парсинг токена
    const userData = mockValidateToken(token);

    // Парсинг ролей, если они есть
    if (roles) {
      try {
        parsedRoles = JSON.parse(roles);
      } catch (error) {
        console.error('Ошибка парсинга ролей из localStorage:', error);
      }
    }

    // Если данные пользователя существуют, обновляем состояние
    if (userData && userData.username) {
      this.setState({
        userName: userData.username,
        userRoles: parsedRoles.length > 0 ? parsedRoles : userData.roles,
      });
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const token = localStorage.getItem('token');
    const roles = localStorage.getItem('roles');
    let parsedRoles = [];

    // Парсинг токена и ролей
    const userData = mockValidateToken(token);
    if (roles) {
      try {
        parsedRoles = JSON.parse(roles);
      } catch (error) {
        console.error('Ошибка парсинга ролей из localStorage:', error);
      }
    }

    // Проверяем, изменились ли данные
    if (
      userData &&
      userData.username &&
      (userData.username !== prevState.userName ||
        JSON.stringify(parsedRoles) !== JSON.stringify(prevState.userRoles))
    ) {
      this.setState({
        userName: userData.username,
        userRoles: parsedRoles.length > 0 ? parsedRoles : userData.roles,
      });
    }
  }


  loadUserName = async () => {
    try {
      const userNameResponse = await getUserName();
      if (userNameResponse && userNameResponse.name) {
        this.setState({ userName: userNameResponse.name, loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { userName, userRoles, loading } = this.state;

    if (loading) {
      // return <Loader />;
    }

    //TODO
    if (!userName) {
      // window.location.href = "http://localhost/api/login";
      // window.location.href = "http://hramota.fun/api/login";
    }

    return (
      <BrowserRouter>
        <Routes>
          {allRoutes.map(({ path, module }, index) => (
            <Route
              key={index}
              path={path}
              element={<ModulePage component={Module} module={module} userName={userName} userRoles={userRoles} />}
            />
          ))}

          <Route
            path="/login"
            element={<MockLoginAsNew onLogin={this.handleSetUserName} />}
          />

          <Route path="/home" element={
            <RoleBasedRoute role={['STUDENT', 'TEACHER', 'ADMIN']}>
              <Main userName={userName} userRoles={userRoles} />
            </RoleBasedRoute>
          } />

          <Route
            path="/"
            element={
              <RoleBasedRoute role={['STUDENT', 'TEACHER', 'ADMIN']}>
                <Main userName={userName} userRoles={userRoles} />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/progress"
            element={
              <RoleBasedRoute role={['STUDENT', 'TEACHER', 'ADMIN']}>
                <UserProfile userName={userName} userRoles={userRoles} />
              </RoleBasedRoute>
            }
          />


          <Route path="/class/:id" element={<ClassPage userName={userName} userRoles={userRoles} />} />

          <Route path="/class/:id/:subject" element={<SubjectPage userName={userName} userRoles={userRoles} />} />

          <Route
            path="/class/:id/:subject/:author/modules"
            element={<AuthorPage userName={userName} userRoles={userRoles} />}
          />

          <Route path="/instruction" element={<ModulePage component={Module} module={instruction} userName={userName} userRoles={userRoles} />} />
          <Route path="/author_info" element={<ModulePage component={Module} module={author_info} userName={userName} userRoles={userRoles} />} />

          <Route path="/about" element={<ModulePage component={Module} module={about} userName={userName} userRoles={userRoles} />} />


          <Route path="/teacher" element={
            <RoleBasedRoute role={['TEACHER', 'ADMIN']}>
              <TeacherPage userName={userName} userRoles={userRoles} />
            </RoleBasedRoute>}
          />

          <Route path="/teacher/class/1b" element={
            <RoleBasedRoute role={['TEACHER', 'ADMIN']}>
              <TeacherClassPage userName={userName} userRoles={userRoles} />
            </RoleBasedRoute>}
          />

          {/* Вбудовані */}
          <Route
            path="/class/1/math/prosh/modules/task01"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/1/index.html"
                userName={userName} userRoles={userRoles}
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task02"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/2/index.html"
                userName={userName} userRoles={userRoles}
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task03"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/3/index.html"
                userName={userName} userRoles={userRoles}
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task04"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/4/index.html"
                userName={userName} userRoles={userRoles}
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task05"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/5/index.html"
                userName={userName} userRoles={userRoles}
              />
            }
          />

          <Route
            path="/class/3/math/prosh/modules/module01"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/class/3/module01/index.html"
                userName={userName} userRoles={userRoles}
              />
            } />

          <Route
            path="/class/3/math/prosh/modules/module02"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/class/3/module02/task_02_1.html"
                userName={userName} userRoles={userRoles}
              />
            } />


        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
