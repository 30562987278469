export const module13 = {
  title: "Одиничний і множинний тексти",
  questions: [
    {
      id: 1,
      question: "1. Визначте, яка з наведених характеристик найкраще описує одиничний текст.",
      options: [
        { id: "А", text: "Охоплює кілька аспектів однієї теми." },
        { id: "Б", text: "Є частиною серії публікацій." },
        { id: "В", text: "Самостійно розкриває певну наукову проблему або подію." },
        { id: "Г", text: "Уключає різні точки зору на одну подію." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Прикладом множинного тексту є",
      options: [
        { id: "А", text: "новинна стаття про конкретну подію" },
        { id: "Б", text: "серія статей про екологічні проблеми одного регіону" },
        { id: "В", text: "оглядова стаття про досягнення науки" },
        { id: "Г", text: "інтерв’ю з відомим ученим" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Перевагою множинних текстів є",
      options: [
        { id: "А", text: "завжди коротші за одиничні тексти" },
        { id: "Б", text: "дають змогу розглянути тему з різних аспектів" },
        { id: "В", text: "не потребують додаткових публікацій для розуміння" },
        { id: "Г", text: "містять лише фактичну інформацію" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Найбільше підходить для використання одиничного тексту",
      options: [
        { id: "А", text: "аналіз різних точок зору на одну подію" },
        { id: "Б", text: "поглиблене дослідження теми через кілька статей" },
        { id: "В", text: "опис конкретної події з усіма необхідними деталями" },
        { id: "Г", text: "порівняння творів різних авторів на одну тему" },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. Визначте, яка з наступних характеристик є типовою для одиничного тексту.",
      options: [
        { id: "А", text: "Містить кілька авторів." },
        { id: "Б", text: "Розглядає тему в межах однієї публікації." },
        { id: "В", text: "Уключає багато прикладів з різних джерел." },
        { id: "Г", text: "Охоплює широкий контекст події." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Основною причиною для використання множинних текстів у дослідженнях є те, що вони",
      options: [
        { id: "А", text: "завжди легкі для читання" },
        { id: "Б", text: "дають можливість скласти чітке уявлення про складну тему" },
        { id: "В", text: "завжди містять нову інформацію" },
        { id: "Г", text: "не потребують аналізу" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test', },
  ],
};
