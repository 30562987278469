export const text_mod_1 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLREEAAAAAAG-0_GuE3znLNqw"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_2 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLRUEAAAAAABCzt20lfCpGJhA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_3 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLSEEAAAAAADfgoxSXb33kTtY"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_4 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLRkEAAAAAAOSB-mFmL6PdnL4"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_5 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLR0EAAAAAANHpsssu0aHXDQQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_6 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLSUEAAAAAAK-Amza6Rs4voP0"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_7 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLSkEAAAAAANsCEO3i0NUdESI"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_8 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLS0EAAAAAAEqWuGwmFv96FMA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_9 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLS0EAAAAAAEqWuGwmFv96FMA"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;



export const text_mod_10 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLPkEAAAAAAIZcfkvfWzRamlE"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_11 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLPEEAAAAAANyYPOqYW9y5oUg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_12 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLP0EAAAAAAPMFDkuobbaOuf8"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_13 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLQEEAAAAAAOyZk17dRdYzEoQ"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_14 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLQUEAAAAAAM7nA7rBS62fBSo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_15 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLQkEAAAAAAGvNH8cTENWdN8s"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_16 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/c/8b131763810f9c95/UQSVnA-BYxcTIICLQ0EAAAAAAOV8V5LhNxqKcTo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;
