import imglit01 from "./images/ukrlit/imglit_01.jpg";
import imglit02 from "./images/ukrlit/imglit_02.jpg";

export const module26_27 = {
  title: "§ 26-27. Жанр балади в літературі романтизму",
  questions: [
    {
      id: 1,
      question: "1. Балада «Причинна» починається словами",
      options: [
        { id: "А", text: "Ідуть дівчата в поле жати та, знай, співають ідучи…" },
        { id: "Б", text: "Чи винна ж голубка, що голуба любить?.." },
        { id: "В", text: "Посадили над козаком явір та ялину…" },
        { id: "Г", text: "Реве та стогне Дніпр широкий…" }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Смерть і пітьму в баладі «Причинна» символізує образ",
      options: [
        { id: "А", text: "голуба" },
        { id: "Б", text: "сичів" },
        { id: "В", text: "коня" },
        { id: "Г", text: "зозулі" }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. НЕПРАВИЛЬНИМ є твердження, що балада «Причинна»",
      options: [
        { id: "А", text: "ліро-епічний твір" },
        { id: "Б", text: "має трагічну кінцівку" },
        { id: "В", text: "має фольклорну основу" },
        { id: "Г", text: "належить до пізньої творчості Шевченка" }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. Ілюстрацію до балади «Причинна» позначено буквою:",
      options: [
        { id: "А", text: "Зображення А" },
        { id: "Б", text: "Зображення Б" },
        { id: "В", text: "Зображення В" },
        { id: "Г", text: "Зображення Г" },

      ],
      imgPath: imglit01,
      addClass: "horizontal",
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Визначте художні засоби в наведених уривках.",
      dropdownQuestionParts: [
        "<i> <b>Уривок</b> </br> 1) Прийшли попи з корогвами, задзвонили дзвони… ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) Посадили над козаком явір та ялину… ",
        { id: "dropdown2", text: " - " },
        "<i><br /> 3) Реве та стогне Дніпр широкий… ",
        { id: "dropdown3", text: " - " },
        "<i> <br/></i>"
      ],
      options: [
        { id: "А", text: "гіпербола" },
        { id: "Б", text: "тавтологія" },
        { id: "В", text: "символ" },
        { id: "Г", text: "епітет" }
      ],
      correctAnswers: {
        dropdown1: "Б",
        dropdown2: "В",
        dropdown3: "Г"
      },
      showAnswerOptions: true,
      dropdownAmount: 3,
      isTest: false,
      type: 'interactive',
    },
    {
      id: 6,
      question: '6. Розподіліть ознаки за жанрами: <i><b>Балада; Дума.</b></i> <br>(Перетягніть ознаки у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "трагічність у розв’язанні конфлікту", type: "leftBucket" },
        { id: 2, text: "складається із «заплачки», основної частини та «славословія»", type: "rightBucket" },
        { id: 3, text: "наявність метаморфози", type: "leftBucket" },
        { id: 4, text: "виконуються речитативом", type: "rightBucket" },
        { id: 5, text: "відсутність фантастичних елементів", type: "rightBucket" },
        { id: 6, text: "незвичайність і загадковість подій", type: "leftBucket" }
      ],
      leftBucketTitle: "Балада",
      rightBucketTitle: "Дума"
    },
    {
      id: 7,
      question:
        "7. Переглянувши трейлер до анімаційного фільму за мотивами балади «Тополя» (1 хв 18 с), заповніть таблицю художніх образів, використаних у відео.",
      videoPath:
        "https://www.youtube.com/embed/nwgNk2hiW10?si=qKhr5qYM1eq_IOZ1",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      imgPath: imglit02,
      addClass: "horizontal",
      type: 'video',
    },
  ],
};
