import {text_mod_19, } from "../text/Text.js";
export const module19 = {
  title:
    "Уроки 91-96. Складання таблиці ділення на 3. Закріплення вивчених випадків множення і ділення.​ Задачі на 2 дії. Ділення чисел на 1. Розв’язання і порівняння задач​​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_19, },
    ]
  }
