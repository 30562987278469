import {text_mod_6, } from "../text/Text.js";
export const module06 = {
  title:
    "Уроки 24-27. Способи віднімання від 11 одноцифрових чисел.​ Способи віднімання від 12 одноцифрових чисел. Способи віднімання від 13 одноцифрових чисел​​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_6, },
    ]
  }
