import React from "react";
import { useParams, Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { modulesData } from "./modulesData";
import Header from "../header_h/Header";
import Footer from "../footer/Footer";
import BreadcrumbsModulePage from "../breadcrumbs/BreadcrumbsModulePage";
import DownloadPdfButton from "../download_pdf_button/DownloadPdfButton";



const AuthorPage = ({ userName, userRoles }) => {
  const { id, subject, author } = useParams();

  // Извлекаем данные модулей на основе параметров
  const modules = modulesData[id]?.[subject]?.[author] || [];

  return (
    <>
      <Header userName={userName} userRoles={userRoles} />
      <BreadcrumbsModulePage />
      <div className="classContainer">
        <ListGroup>
          {modules.length > 0 ? (
            modules.map((module) => (
              <div
                key={module.path}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <ListGroup.Item
                  action
                  className="list-item"
                  variant="warning"
                  href={`/class/${id}/${subject}/${author}/modules/${module.path}`}
                  style={{
                    flexGrow: 1, // Контейнер с текстом занимает всё доступное пространство
                    paddingRight: "10px", // Отступ справа
                    margin: 0,
                  }}
                >
                  {module.title}
                </ListGroup.Item>
                {module.pdfPath && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50px", // Фиксированная ширина для иконки
                      height: "50px", // Фиксированная высота для иконки
                    }}
                  >
                    <DownloadPdfButton
                      pdfPath={module.pdfPath}
                      pdfFileName={module.pdfFileName}
                    />
                  </div>
                )}
              </div>
            ))
          ) : (
            <ListGroup.Item className="list-item">
              Модулі не знайдені
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
      <Footer />
    </>
  );
};

export default AuthorPage;
