import imglit03 from "./images/ukrlit/imglit_03.jpg";

export const module24_25 = {
  title: "§ 24-25. Романтизм і реалізм як основні мистецькі напрями ХІХ ст.",
  questions: [
    {
      id: 1,
      question: "1. У вірші Михайла Петренка «Небо» немає образу",
      options: [
        { id: "А", text: "сокола" },
        { id: "Б", text: "хмар" },
        { id: "В", text: "зірок" },
        { id: "Г", text: "вітру" }
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',
    },
    {
      id: 2,
      question: "2. Вірш «Небо» має ознаки",
      options: [
        { id: "А", text: "орнаменталізму" },
        { id: "Б", text: "монументалізму" },
        { id: "В", text: "романтизму" },
        { id: "Г", text: "реалізму" }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 3,
      question: "3. У вірші «Небо» НЕМАЄ мотиву",
      options: [
        { id: "А", text: "незадоволення земним буттям" },
        { id: "Б", text: "нерозділеного кохання" },
        { id: "В", text: "утечі від реальності" },
        { id: "Г", text: "пошуку щастя" }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 4,
      question: "4. НЕПРАВИЛЬНИМ є твердження, що вірш «Небо»",
      options: [
        { id: "А", text: "написано амфібрахієм" },
        { id: "Б", text: "належить до патріотичної лірики" },
        { id: "В", text: "перший твір, що прозвучав у космосі" },
        { id: "Г", text: "став народною піснею літературного походження" }
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',
    },
    {
      id: 5,
      question: "5. Прочитайте рядки:</br> <i> Коли  б мені крилля, орлячі ті крилля,</br> Я б землю покинув і на новосілля</br Орлом бистрокрилим у небо польнув</br>  І в хмарах навіки од світу втонув!</br><b>В уривку НЕМАЄ</b></i>",
      options: [
        { id: "А", text: "епітета" },
        { id: "Б", text: "інверсії" },
        { id: "В", text: "порівняння" },
        { id: "Г", text: "персоніфікації" }
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',
    },
    {
      id: 6,
      question: '6. Розподіліть ознаки романтизму і реалізму як мистецьких напрямів: <i><b>Романтизм; Реалізм.</b></i> <br>(Перетягніть ознаки у відповідний кошик)',
      isGame: true,
      type: 'interactive',
      initialWords: [
        { id: 1, text: "винятковий герой у незвичайних обставинах", type: "leftBucket" },
        { id: 2, text: "особливо тісний зв’язок із фольклором", type: "leftBucket" },
        { id: 3, text: "правдиве, всебічне зображення типових подій і характерів у типових обставинах", type: "rightBucket" },
        { id: 4, text: "характер і вчинки героя пояснюються його соціальним походженням, умовами повсякденного життя", type: "rightBucket" },
        { id: 5, text: "незадоволення реальним життям", type: "leftBucket" },
        { id: 6, text: "прагнення до точності в зображенні", type: "rightBucket" }
      ],
      leftBucketTitle: "Романтизм",
      rightBucketTitle: "Реалізм"
    },
    {
      id: 7,
      question:
        "7. Переглянувши відеоурок Оксани Садовенко «Стиль романтизму в образотворчому мистецтві» (12 хв 24 с), заповніть таблицю:",
      videoPath:
        "https://www.youtube.com/embed/VvEruqPGcjE?si=NswsC3fYjqMXa2GE",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      imgPath: imglit03,
      addClass: "horizontal",
      type: 'video',
    },
  ],
};
