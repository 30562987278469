import Offcanvas from "react-bootstrap/Offcanvas";

import logoG from "../../../images/logoG.jpg";

import "./LeftMobileMenu.css";
import "./responsive.css";

import LessonDropdown from "./LessonDropdown/LessonDropdown";
import SearchComponent from "../../search/SearchComponent";// Імпортуємо компонент пошуку



function LeftMobileMenu({ show, handleClose, backdropClassName = "gegar" }) {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdropClassName={backdropClassName}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-logoH">
            <img alt="logoH" src={logoG} />
          </div>

          <div className="lesson-drop">
            <LessonDropdown />
          </div>
          {/* Додаємо компонент пошуку */}
          <div className="search-section">
            <SearchComponent />
          </div>

          <div className="support-section">
            <span className="support-text">
             Підтримка: 
<p> <a href="tel:+380686604958">+38 068 660 49 58</a></p> 
<p>info@gramota.kiev.ua</p>
            </span>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default LeftMobileMenu;
