import {text_mod_33, } from "../text/Text.js";
export const module33 = {
  title:
    "§33. Іменник.​ Слова, які відповідають на питання хто? і що?",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_33, },
    ]
  }
