import {text_mod_24, } from "../text/Text.js";
export const module24 = {
  title:
    "Урок 127-133. Складання за схемою добутків із множником 9 і частки з дільником 9. Знаходження невідомого діленого. Множення і ділення на 10. Обчислення виразів на вивчені випадки множення і ділення. Розв’язання задач​​​​​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_24, },
    ]
  }
