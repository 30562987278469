export const module06 = {
  title: "Повні й неповні речення. Тире в неповних реченнях. Вибір професії — це важливо",
  questions: [
    {
      id: 1,
      question: "1. Неповним є речення",
      options: [
        { id: "А", text: "Вибір професії — це відповідальний крок." },
        { id: "Б", text: "Кожен повинен обрати свій шлях у житті." },
        { id: "В", text: "Одні мріють стати акторами, інші — науковцями." },
        { id: "Г", text: "Професія має відповідати здібностям людини." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Неповним є речення",
      options: [
        { id: "А", text: "Сестра мріє стати дизайнером, а я — архітектором." },
        { id: "Б", text: "Програмісти створюють майбутнє." },
        { id: "В", text: "Архітектори проєктують будівлі." },
        { id: "Г", text: "Журналісти пишуть статті." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. У реченні Хтось обирає професію за покликанням, а хтось — за порадою батьків пропущено",
      options: [
        { id: "А", text: "підмет" },
        { id: "Б", text: "присудок" },
        { id: "В", text: "додаток" },
        { id: "Г", text: "означення" },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Потребує редагування речення",
      options: [
        { id: "А", text: "Сестра мріє стати дизайнером, а я мрію стати архітектором." },
        { id: "Б", text: "Лікарі рятують життя, а вчителі — душі." },
        { id: "В", text: "Найважливіше у виборі професії — покликання." },
        { id: "Г", text: "Кожна професія важлива для суспільства." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. НЕПРАВИЛЬНО поставлено тире в реченні",
      options: [
        { id: "А", text: "Хтось обирає професію за покликанням, а хтось — за порадою батьків." },
        { id: "Б", text: "Юристи захищають права, а лікарі — здоров’я." },
        { id: "В", text: "Учителі навчають дітей, а батьки їх — виховують." },
        { id: "Г", text: "Інженери машини проєктують, а механіки — ремонтують." },
      ],
      correctAnswer: "В",
      isTest: true,
      type: 'test',},
    {
      id: 6,
      question: "6. Правильно поставлено тире в реченні",
      options: [
        { id: "А", text: "Одні обирають роботу в офісі — інші на свіжому повітрі." },
        { id: "Б", text: "Психологи допомагають людям, а соціальні працівники — суспільству." },
        { id: "В", text: "Вибір професії залежить від — багатьох факторів." },
        { id: "Г", text: "Професія повинна — приносити задоволення." },
      ],
      correctAnswer: "Б",
      isTest: true,
      type: 'test',},
  ],
};