import {text_mod_77, } from "../text/Text.js";
export const module77 = {
  title:
    "Урок 22. Мої навчальні досягнення. Я вмію, можу",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_77, },
    ]
  };