export const module08 = {
  title: "Узагальнювальні слова в реченнях з однорідними членами та розділові знаки при них. Усе, що породжує позитивні емоції",
  questions: [
    {
      id: 1,
      question: "1. Правильно розставлено розділові знаки при узагальнювальному слові в реченні",
      options: [
        { id: "А", text: "Усе навкруги: квіти, дерева, небо — наповнювало серце радістю." },
        { id: "Б", text: "Усе навкруги — квіти, дерева, небо: наповнювало серце радістю." },
        { id: "В", text: "Усе навкруги: квіти, дерева, небо — наповнювало серце радістю." },
        { id: "Г", text: "Усе навкруги — квіти, дерева, небо — наповнювало серце радістю." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',},
    {
      id: 2,
      question: "2. Двокрапку в реченні з узагальнювальним словом Різні види мистецтва живопис музика література танець можуть викликати позитивні емоції потрібно поставити",
      options: [
        { id: "А", text: "після слова мистецтва" },
        { id: "Б", text: "після слова живопис" },
        { id: "В", text: "після слова література" },
        { id: "Г", text: "двокрапка не потрібна" },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 3,
      question: "3. Узагальнювальне слово при однорідних членах ужито в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Сонячне проміння теплий вітерець аромат квітів усе це створювало чудовий настрій." },
        { id: "Б", text: "Чудовий настрій створювали сонячне проміння теплий вітерець аромат квітів." },
        { id: "В", text: "Часом ми забуваємо рідні звуки, пісню улюбленого міста, гомін безкрайого моря." },
        { id: "Г", text: "Людина на березі моря завжди відпочиває оздоровлюється мріє." },
      ],
      correctAnswer: "А",
      isTest: true,
      type: 'test',},
    {
      id: 4,
      question: "4. Перед однорідними членами треба поставити двокрапку в усіх реченнях, ОКРІМ (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Хай буде сад і дерево крислате і кіт-воркіт і ще багато див (Л. Костенко)." },
        { id: "Б", text: "Тягнуться до неба і до сонця квіти трави дерева." },
        { id: "В", text: "У горах панували тиша та спокій." },
        { id: "Г", text: "І все поволі зникає море скелі земля (М. Коцюбинський)." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',},
    {
      id: 5,
      question: "5. НЕПРАВИЛЬНО вжито розділові знаки при узагальнювальному слові в реченні",
      options: [
        { id: "А", text: "Усі ці речі: книжки, музика, подорожі — наповнюють життя сенсом." },
        { id: "Б", text: "Книжки, музика, подорожі — усе це наповнює життя сенсом." },
        { id: "В", text: "Життя наповнюють сенсом різні речі: книжки, музика, подорожі." },
        { id: "Г", text: "Різні речі — книжки, музика, подорожі: наповнюють життя сенсом." },
      ],
      correctAnswer: "Г",
      isTest: true,
      type: 'test',},
  ],
};
