import {text_mod_73, } from "../text/Text.js";
export const module73 = {
  title:
    "Урок 18. Слова — назви дій",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_73, },
    ]
  };