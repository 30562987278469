import {text_mod_66, } from "../text/Text.js";
export const module66 = {
  title:
    "§66. Створюю текст за серією малюнків. Створюю текст про події з власного життя",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_66, },
    ]
  }
